/* eslint-disable no-unused-vars */
import './currencyConverter.scss'
import ReactSelect, { Components } from 'components/ReactSelect'

import { useState, useEffect } from 'react'

// Constants
import { countryFlags } from 'global/constants/dummyData'

import { USDImg, NEPImg } from 'assets'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import LabelInput from 'components/Layout/LabelInput/LabelInput'

const CurrencyConverter = ({ payload }) => {
    // NOTE: Please do note that the state values will be stored in string data type, so to feed it to the api, you have to convert it to float (number) type.

    const countryOptions = []

    const [usdValue, setUsdValue] = useState(
        payload?.filter((item) => item?.currency?.iso3 === 'USD')[0]
    )

    const [flags, setFlags] = useState({
        first: false,
        second: false,
    })

    function roundToTwo(num) {
        return +(Math.round(num + 'e+2') + 'e-2')
    }

    payload.map((item) =>
        countryOptions.push({
            label: item?.currency?.iso3,
            icon: countryFlags[item?.currency?.iso3],
            value: {
                buy: item?.buy,
                sell: item?.sell,
                unit: item?.currency?.unit,
            },
        })
    )

    countryOptions.push({
        label: 'NEP',
        icon: countryFlags['NEP'],
        value: {
            buy: 1,
            sell: 1,
            unit: 1,
        },
    })

    // Put in the USD data here.
    const [currencyInputNumberOne, setCurrencyInputNumberOne] = useState({
        buy: usdValue?.buy,
        sell: usdValue?.sell,
        unit: usdValue?.currency?.unit,
        iso3: usdValue?.currency?.iso3,
        value: 1,
    })

    const [currencyInputNumberTwo, setCurrencyInputNumberTwo] = useState({
        buy: 1,
        sell: 1,
        unit: 1,
        iso3: 'NEP',
        value: usdValue?.sell,
    })

    const handleOnChangeCurrencyNumberOne = (value) => {
        setCurrencyInputNumberOne((prevState) => ({
            ...prevState,
            value: value,
        }))
    }

    useEffect(() => {
        if (flags.first) {
            let currencyOneValue =
                (parseFloat(currencyInputNumberOne.value) *
                    parseFloat(currencyInputNumberOne.sell)) /
                parseFloat(currencyInputNumberOne.unit)
            let currencyTwoValue =
                parseFloat(currencyInputNumberTwo.unit) /
                parseFloat(currencyInputNumberTwo.sell)

            let convertedValue = isNaN(
                roundToTwo(currencyOneValue * currencyTwoValue)
            )
                ? ''
                : roundToTwo(currencyOneValue * currencyTwoValue)

            setCurrencyInputNumberTwo((prevState) => ({
                ...prevState,
                value: convertedValue,
            }))
        }

        // eslint-disable-next-line
    }, [currencyInputNumberOne])

    const handleOnChangeCurrencyNumberTwo = (value) => {
        setCurrencyInputNumberTwo((prevState) => ({
            ...prevState,
            value: value,
        }))
    }

    useEffect(() => {
        if (flags.second) {
            let currencyTwoValue =
                (parseFloat(currencyInputNumberTwo.value) *
                    parseFloat(currencyInputNumberTwo.sell)) /
                parseFloat(currencyInputNumberTwo.unit)
            let currencyOneValue =
                parseFloat(currencyInputNumberOne.unit) /
                parseFloat(currencyInputNumberOne.sell)
            let convertedValue = isNaN(
                roundToTwo(currencyTwoValue * currencyOneValue)
            )
                ? ''
                : roundToTwo(currencyTwoValue * currencyOneValue)

            setCurrencyInputNumberOne((prevState) => ({
                ...prevState,
                value: convertedValue,
            }))
        }

        // eslint-disable-next-line
    }, [currencyInputNumberTwo])

    return (
        <div className="currency-converter-container">
            <div className="currency-converter-selectors-container">
                <div
                    className="selector-one"
                    onClick={() =>
                        setFlags((prevState) => ({
                            ...prevState,
                            first: true,
                            second: false,
                        }))
                    }
                >
                    <ReactSelect
                        height="57px"
                        width="110px"
                        containerHeight="100%"
                        containerWidth="100%"
                        options={countryOptions}
                        selectDefaultValue={{
                            label: (
                                <div className="selector-two-default-option">
                                    <img
                                        src={USDImg}
                                        alt=""
                                        className="selector-two-default-option-icon"
                                    />
                                    <span>USD</span>
                                </div>
                            ),
                            value: {
                                buy: currencyInputNumberOne.buy,
                                sell: currencyInputNumberOne.sell,
                            },
                        }}
                        components={{
                            Option: Components.Option,
                            SingleValue: Components.SingleValue,
                        }}
                        border={1}
                        className="react-select-one"
                        handleOnChange={(value) =>
                            setCurrencyInputNumberOne((prevState) => ({
                                ...prevState,
                                buy: value.value.buy,
                                sell: value.value.sell,
                                iso3: value.label,
                                unit: value.value.unit,
                            }))
                        }
                    />
                    <LabelInput
                        labelDisplay="none"
                        inputType="number"
                        inputClassName="currency-input-number-field"
                        size="medium"
                        noStyles={true}
                        handleOnChange={(e) =>
                            handleOnChangeCurrencyNumberOne(e.target.value)
                        }
                        inputPlaceholder={0}
                        inputValue={currencyInputNumberOne.value}
                        inputNoBorder={true}
                    />
                </div>
                <div
                    className="selector-two"
                    onClick={() =>
                        setFlags((prevState) => ({
                            ...prevState,
                            first: false,
                            second: true,
                        }))
                    }
                >
                    <ReactSelect
                        selectDefaultValue={{
                            label: (
                                <div className="selector-one-default-option">
                                    <img
                                        src={NEPImg}
                                        alt=""
                                        className="selector-one-default-option-icon"
                                    />
                                    <span>NEP</span>
                                </div>
                            ),
                            value: {
                                buy: currencyInputNumberTwo.buy,
                                sell: currencyInputNumberTwo.sell,
                            },
                        }}
                        options={countryOptions}
                        height="57px"
                        width="110px"
                        components={{
                            Option: Components.Option,
                            SingleValue: Components.SingleValue,
                        }}
                        border={1}
                        className="react-select-two"
                        handleOnChange={(value) =>
                            setCurrencyInputNumberTwo((prevState) => ({
                                ...prevState,
                                buy: value.value.buy,
                                sell: value.value.sell,
                                iso3: value.label,
                                unit: value.value.unit,
                            }))
                        }
                    />
                    <LabelInput
                        labelDisplay="none"
                        inputType="number"
                        inputClassName="currency-input-number-field"
                        size="medium"
                        noStyles={true}
                        handleOnChange={(e) =>
                            handleOnChangeCurrencyNumberTwo(e.target.value)
                        }
                        inputValue={currencyInputNumberTwo.value}
                        inputPlaceholder={0}
                        inputNoBorder={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default CurrencyConverter

CurrencyConverter.propTypes = {
    payload: PropTypes.array.isRequired,
    updatedDate: PropTypes.string,
}
