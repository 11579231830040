import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import token from 'utils/token'
import useStore from 'store/store'
import * as storage from 'utils/storage'
import { detailConst } from 'global/constants/appConstants'

const postSocialLogin = (postData) => {
    if (postData) {
        // REMINDER: This is both for social login as well as social sign up.
        // REMINDER: For the google sign in/up send in another thing in the access_token field instead of the actual access_token as doing so may pose a security risk.
        const updatedEndpoints = endpoints.auth.socialLogin
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostSocialLogin() {
    const addUserDetails = useStore((store) => store.addUserDetails)

    return useMutation(postSocialLogin, {
        onSuccess: ({ payload, message }) => {
            token.setUserToken({
                userToken: `Bearer ${payload?.token}`,
                expiryDate: 86400000,
            })
            addUserDetails(payload?.user)
            storage.set(detailConst.USER_DETAILS, payload?.user)
            toast.success(message)
        },
        onError: (err) => {
            Object.keys(err.errors).map((item) =>
                toast.error(err.errors[item][0])
            )
        },
    })
}
