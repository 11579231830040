import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchVideosPaginate = (paginateNumber) => {
    const updatedEndpoints = endpoints.videos.paginate.replace(
        '{paginateNumber}',
        paginateNumber
    )
    return http().get(updatedEndpoints)
}

export function useFetchVideosPaginate(paginateNumber) {
    return useQuery(['fetchVideosPaginate', paginateNumber], () =>
        fetchVideosPaginate(paginateNumber)
    )
}
