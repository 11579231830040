import './dashboardListTemplateItem.scss'
import { PropTypes } from 'prop-types'
import TVLists from 'pages/TVLists/TVLists'
import { useState, useRef } from 'react'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const DashboardListTemplateItem = ({ payload, type }) => {
    // console.log(type === 'tv' && payload)

    const tvRef = useRef()

    const [tvUrl, setTvUrl] = useState()

    const handleItemClick = (e, url) => {
        if (url) {
            tvRef.current.open()
            setTvUrl(url)
        }
    }

    return (
        <>
            <TVLists innerRef={tvRef} streamUrl={tvUrl} />
            {Array.isArray(payload) && payload.length > 0 && type !== 'tv'
                ? payload.map((data, index) => (
                      <a
                          href={data?.link}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                          className="dashboard-list-template-item-section"
                      >
                          <div className="dashboard-list-template-item-container">
                              <figure className="dashboard-list-template-item-figure">
                                  <img
                                      src={replaceHttpWithHttps(data?.imageUrl)}
                                      alt=""
                                      className="dashboard-list-template-item-img"
                                  />
                              </figure>
                              <div className="dashboard-list-template-item-text-container">
                                  <h3 className="dashboard-list-template-item-title">
                                      {data?.title}
                                  </h3>
                              </div>
                          </div>
                      </a>
                  ))
                : payload.map((data, index) => (
                      <div
                          key={index}
                          className="dashboard-list-template-item-section"
                          onClick={(e) =>
                              handleItemClick(e, data.live_stream_link)
                          }
                      >
                          <div className="dashboard-list-template-item-container">
                              <figure className="dashboard-list-template-item-figure">
                                  <img
                                      src={replaceHttpWithHttps(data?.image)}
                                      alt=""
                                      className="dashboard-list-template-item-img"
                                  />
                              </figure>
                              <div className="dashboard-list-template-item-text-container">
                                  <h3 className="dashboard-list-template-item-title">
                                      {data?.name}
                                  </h3>
                              </div>
                          </div>
                      </div>
                  ))}
        </>
    )
}

export default DashboardListTemplateItem

DashboardListTemplateItem.propTypes = {
    payload: PropTypes.array,
    type: PropTypes.string,
}
