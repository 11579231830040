/* eslint-disable no-unused-vars */
import './singleComment.scss'
import ConvertToRelativeTime from 'utils/ConvertToRelativeTime'
import { defaultAvatarImg } from 'assets'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const SingleComment = ({ commentData, isLast }) => {
    return (
        <div className="single-comment-container">
            <div
                className={classNames(
                    'single-comment-user-profile-pic-container',
                    { last: isLast }
                )}
            >
                <figure className="single-comment-user-profile-pic-figure">
                    <img
                        src={
                            commentData?.img
                                ? replaceHttpWithHttps(commentData?.img)
                                : defaultAvatarImg
                        }
                        alt=""
                    />
                </figure>
            </div>
            <div className="single-comment-text-container">
                <div className="single-comment-user-name-date-container">
                    <p className="single-comment-user-name">
                        {commentData?.user?.username
                            ? commentData?.user?.username
                            : `${commentData?.user?.first_name} ${commentData?.user?.last_name}`}
                    </p>
                    <p className="single-comment-date">
                        {ConvertToRelativeTime(
                            new Date(commentData?.created_at)
                        )}
                    </p>
                </div>
                <p className="single-comment-desc">{commentData?.comment}</p>
            </div>
        </div>
    )
}

export default SingleComment

SingleComment.propTypes = {
    commentData: PropTypes.object,
    isLast: PropTypes.bool,
}
