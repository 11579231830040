import { PieChart } from 'react-minimal-pie-chart'
import { PropTypes } from 'prop-types'
import { useState } from 'react'
import './reactPieChart.scss'
import NumberConverter from 'hooks/numberConverter/NumberConverter'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import { NepaliToEnglishConverter } from 'utils/helpers/nepalToEnglishConverter/NepaliToEnglishConverter'

const ReactPieChart = ({
    principalLoanAmount,
    totalInterestPayable,
    loanEmi,
    totalPayment,
}) => {
    // TODO: Change the name of the component to EMI Pie chart. Do not use React Pie Chart as it is plain confusing.
    const [hovered, setHovered] = useState()

    function roundToTwo(num) {
        return +(Math.round(num + 'e+2') + 'e-2')
    }

    const dataEntry = [
        {
            title: 'Principal Loan Amount',
            value: principalLoanAmount ? principalLoanAmount : 1,
            color: '#18479E',
        },
        {
            title: 'Total Interest Payable',
            value: totalInterestPayable ? totalInterestPayable : 1,
            color: '#D1232A',
        },
    ]

    const data = dataEntry.map((entry, i) => {
        if (hovered === i) {
            return {
                ...entry,
                // color: 'grey',
            }
        }
        return entry
    })

    const labelStyle = {
        fontSize: '5px',
        fill: '#fff',
    }

    const { t, i18n } = useTranslation()

    return (
        <div className="react-pie-chart-container">
            <h3 className="react-pie-chart-heading">
                {t('calculator.emiCalculator.totalPaymentBreakdown')}
            </h3>
            <div className="react-pie-chart-content-container">
                <div className="react-pie-chart-left-side-container">
                    <div className="react-pie-chart-left-side-item">
                        <p className="react-pie-chart-left-side-item-label">
                            {t('calculator.emiCalculator.loanEmi')}
                        </p>
                        <p className="react-pie-chart-left-side-item-value">
                            {i18n.language === lang.NEPALI
                                ? NumberConverter(loanEmi)
                                : NepaliToEnglishConverter(loanEmi)}
                        </p>
                    </div>
                    <div className="react-pie-chart-left-side-item">
                        <p className="react-pie-chart-left-side-item-label">
                            {t(
                                'calculator.emiCalculator.totalInterestPayments'
                            )}
                        </p>
                        <p className="react-pie-chart-left-side-item-value">
                            {i18n.language === lang.NEPALI
                                ? NumberConverter(totalInterestPayable)
                                : NepaliToEnglishConverter(
                                      totalInterestPayable
                                  )}
                        </p>
                    </div>
                    <div className="react-pie-chart-left-side-item">
                        <p className="react-pie-chart-left-side-item-label">
                            {t('calculator.emiCalculator.totalPayment')}
                        </p>
                        <p className="react-pie-chart-left-side-item-value">
                            {i18n.language === lang.NEPALI
                                ? NumberConverter(totalPayment)
                                : NepaliToEnglishConverter(totalPayment)}
                        </p>
                    </div>
                </div>
                <div className="react-pie-chart-right-side-container">
                    <PieChart
                        label={({ dataEntry }) =>
                            i18n.language === lang.NEPALI
                                ? NumberConverter(
                                      roundToTwo(dataEntry.percentage)
                                  ) + '%'
                                : NepaliToEnglishConverter(
                                      roundToTwo(dataEntry.percentage)
                                  ) + '%'
                        }
                        labelStyle={labelStyle}
                        data={data}
                        lineWidth={50}
                        labelPosition={75}
                        paddingAngle={1}
                        animate
                        onMouseOver={(_, index) => {
                            setHovered(index)
                        }}
                        onMouseOut={() => setHovered(undefined)}
                        options={{ showPanel: true, panelPosition: 'bottom' }}
                        className="react-pie-chart"
                    />
                </div>
            </div>
            <div className="react-pie-chart-legend-container">
                <div className="react-pie-chart-legend-item-container">
                    <div className="react-pie-chart-legend-item-color-one"></div>
                    <p className="react-pie-chart-legend-item-name">
                        {t('calculator.emiCalculator.loanAmount')}
                    </p>
                </div>
                <div className="react-pie-chart-legend-item-container">
                    <div className="react-pie-chart-legend-item-color-two"></div>
                    <p className="react-pie-chart-legend-item-name">
                        {t('calculator.emiCalculator.totalInterest')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ReactPieChart

ReactPieChart.propTypes = {
    principalLoanAmount: PropTypes.number,
    totalInterestPayable: PropTypes.number,
    loanEmi: PropTypes.number,
    totalPayment: PropTypes.number,
}
