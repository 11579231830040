import { englishNepaliNumerals } from 'global/constants/dummyData'
import { PropTypes } from 'prop-types'

export function NepaliToEnglishConverter(number) {
    const result = []

    number = String(number)

    let extractedChar = ''

    // Please do note that englishNepaliNumerals is an object which contains keys of English numbers and values of their nepali equivalent strings
    if (number.length > 1) {
        for (let i of number) {
            extractedChar = englishNepaliNumerals[i]

            if (typeof extractedChar !== 'undefined') {
                result.push(extractedChar)
            } else {
                result.push(i)
            }
        }

        return result.join('')
    }

    if (englishNepaliNumerals[number]) return englishNepaliNumerals[number]

    return number
}

NepaliToEnglishConverter.propTypes = {
    number: PropTypes.number,
}
