import './widget.scss'

import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    shareWidgetHeading,
    forexWidgetHeading,
} from 'global/constants/dummyData'
import Button from 'components/Layout/Button/Button'
import SwitchCheckBox from './SwitchCheckBox/SwitchCheckBox'
import { PropTypes } from 'prop-types'
import { countryFlags } from 'global/constants/dummyData'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import Spinner from 'components/Spinner/Spinner'
import classNames from 'classnames'

const Widget = ({
    widgetTitle,
    widgetTitleDesc,
    widgetPayload,
    checkBoxSlider,
    checked,
    setChecked,
    urlLink,
    type,
    status,
}) => {
    // Please remember that false corresponds to the gain section in the share market widget and true correspods to the loss section in the share market widget

    const { t, i18n } = useTranslation()

    const countryOptions = []

    if (type === 'forex') {
        widgetPayload?.map((item) =>
            countryOptions.push({
                label: item?.currency?.iso3,
                icon: countryFlags[item?.currency?.iso3],
                value: {
                    buy: item?.buy,
                    sell: item?.sell,
                    unit: item?.currency?.unit,
                },
            })
        )
    }


    const twoDecimals = (num) => {
        return parseFloat(num).toFixed(2)
    }

    return (
        <div className="widget-container">
            <div className="widget-top-container">
                <div className="widget-top-left">
                    <p className="widget-title">{widgetTitle}</p>
                    <span className="widget-desc">{widgetTitleDesc}</span>
                </div>
                <div className="widget-top-right">
                    {checkBoxSlider && (
                        <SwitchCheckBox
                            isOn={checked}
                            handleToggle={() => setChecked(!checked)}
                        />
                    )}
                    <NavLink exact to={urlLink}>
                        <Button
                            description={t('more')}
                            bgColor="blue"
                            size="extra-small"
                            color="black"
                        />
                    </NavLink>
                </div>
            </div>
            <div className="widget-table-container">
                <div className="widget-table-heading">
                    {type === 'forex' &&
                        forexWidgetHeading.map((heading, index) => (
                            <div className="widget-heading-item" key={index}>
                                {t(heading)}
                            </div>
                        ))}
                    {type === 'shareMarket' &&
                        shareWidgetHeading.map((heading, index) => (
                            <div className="widget-heading-item" key={index}>
                                {t(heading)}
                            </div>
                        ))}
                </div>
                <div className="widget-table-content">
                    {status === 'loading' ? (
                        <Spinner />
                    ) : (
                        <>
                            {type === 'shareMarket' &&
                                widgetPayload?.payload &&
                                Array.isArray(widgetPayload.payload) &&
                                widgetPayload.payload.map((item, index) => (
                                    <div
                                        className={classNames(
                                            `widget-item-container ${type}`
                                        )}
                                        key={index}
                                    >
                                        <div className="widget-content-item">
                                            {item?.company?.symbol}
                                        </div>
                                        <div className="widget-content-item">
                                            {i18n.language === 'np'
                                                ? EnglishToNepaliConverter(
                                                      twoDecimals(
                                                          item?.point_change
                                                      )
                                                  )
                                                : twoDecimals(
                                                      item?.point_change
                                                  )}
                                        </div>
                                        <div
                                          className={classNames(
                                            `widget-content-item ${type}`,
                                            {
                                                red: checked,
                                                green: !checked,
                                            }
                                          )}
                                        >
                                            {i18n.language === 'np'
                                                ? EnglishToNepaliConverter(
                                                      twoDecimals(
                                                          item?.percentage_change
                                                      )
                                                  )
                                                : twoDecimals(
                                                      item?.percentage_change
                                                  )}
                                        </div>
                                        <div className="widget-content-item">
                                            {i18n.language === 'np'
                                                ? EnglishToNepaliConverter(
                                                      twoDecimals(
                                                          item?.total_traded_quantity
                                                      )
                                                  )
                                                : twoDecimals(
                                                      item?.total_traded_quantity
                                                  )}
                                        </div>
                                    </div>
                                ))}
                            {type === 'forex' &&
                                Array.isArray(countryOptions) &&
                                countryOptions.map((item, index) => (
                                    <div
                                        className="widget-item-container"
                                        key={index}
                                    >
                                        <div className="widget-content-item currency-name">
                                            <img src={item?.icon} alt="" />
                                            <p>{item?.label}</p>
                                        </div>
                                        <div className="widget-content-item">
                                            {i18n.language === 'np'
                                                ? EnglishToNepaliConverter(
                                                      item?.value?.unit
                                                  )
                                                : item?.value?.unit}
                                        </div>
                                        <div className="widget-content-item">
                                            {i18n.language === 'np'
                                                ? EnglishToNepaliConverter(
                                                      item?.value?.buy
                                                  )
                                                : item?.value?.buy}
                                        </div>
                                        <div className="widget-content-item">
                                            {i18n.language === 'np'
                                                ? EnglishToNepaliConverter(
                                                      item?.value?.sell
                                                  )
                                                : item?.value?.sell}
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Widget

Widget.propTypes = {
    widgetTitle: PropTypes.string.isRequired,
    widgetTitleDesc: PropTypes.string,
    widgetPayload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    checkBoxSlider: PropTypes.bool,
    urlLink: PropTypes.string,
    type: PropTypes.string,
}
