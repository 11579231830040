import './tabsContent.scss'

import SingleTabContent from './SingleTabContent/SingleTabContent'
import { useState, useEffect } from 'react'
import Spinner from 'components/Spinner/Spinner'

const TabsContent = ({ tab, activeTabHeading }) => {
    const [activeTabItem, setActiveTabItem] = useState(activeTabHeading)
    useEffect(() => {
        setActiveTabItem(null)
    }, [activeTabHeading])

    return (
        <div className="tab-content-container">
            {tab?.map((data, index) => (
                <div
                    key={index}
                    onClick={() => setActiveTabItem(index)}
                    className="tab-content-single-item-container"
                >
                    {data ? (
                        <SingleTabContent
                            payload={data}
                            activeTabItem={
                                activeTabItem === index ? true : false
                            }
                            index={index}
                        />
                    ) : (
                        <Spinner />
                    )}
                </div>
            ))}
        </div>
    )
}

export default TabsContent
