import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { lang } from 'global/constants/appConstants'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import './todaySharePriceTable.scss'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import Spinner from 'components/Spinner/Spinner'

const TodaySharePriceTable = ({ payload, status, lastElementRef }) => {
    const { i18n, t } = useTranslation()

    const isMobile = useMediaQuery('(max-width: 576px)')
    const isSmallTablet = useMediaQuery('(max-width: 768px)')
    const isTablet = useMediaQuery('(max-width: 992px)')

    const twoDecimals = (num) => {
        return parseFloat(num).toFixed(2)
    }

    return (
        <div className="today-share-price-table-container">
            <table>
                <thead className="today-share-price-table-header">
                    <tr>
                        {!isMobile && !isTablet && (
                            <th>{t('shareMarket.sn')}</th>
                        )}
                        <th>{t('shareMarket.symbol')}</th>
                        <th>{t('shareMarket.ltp')}</th>
                        <th>{t('shareMarket.priceChange')}</th>
                        {!isMobile && (
                            <>
                                {!isSmallTablet && (
                                    <th>{t('shareMarket.percentChange')}</th>
                                )}
                                <th>{t('shareMarket.high')}</th>
                                <th>{t('shareMarket.low')}</th>
                                <th>{t('shareMarket.open')}</th>
                            </>
                        )}
                        <th>{t('shareMarket.quantity')}</th>
                        {!isMobile && <th>{t('shareMarket.turnover')}</th>}
                    </tr>
                </thead>
                <tbody
                    className="today-share-price-table-body"
                    style={{
                        width: isMobile
                            ? '400%'
                            : isSmallTablet
                            ? '800%'
                            : isTablet
                            ? '900%'
                            : '1000%',
                    }}
                >
                    {status === 'loading' ? (
                        <tr>
                            <td>
                                <Spinner />
                            </td>
                        </tr>
                    ) : Array.isArray(payload) && payload.length > 0 ? (
                        payload.map((item, index) => (
                            <tr
                                key={index}
                                className='today-share-price-table-row'
                                ref={lastElementRef}
                            >
                                {!isMobile && !isTablet && (
                                    <td>
                                        {i18n.language === lang.ENGLISH
                                            ? index + 1
                                            : EnglishToNepaliConverter(
                                                  index + 1
                                              )}
                                    </td>
                                )}
                                <td>{item?.company?.symbol}</td>
                                <td>
                                    {i18n.language === lang.ENGLISH
                                        ? twoDecimals(item?.close_price)
                                        : EnglishToNepaliConverter(
                                              twoDecimals(item?.close_price)
                                          )}
                                </td>
                                <td>
                                    {i18n.language === lang.ENGLISH
                                        ? twoDecimals(item?.point_change)
                                        : EnglishToNepaliConverter(
                                              twoDecimals(item?.point_change)
                                          )}
                                </td>
                                {!isMobile && (
                                    <>
                                        {!isSmallTablet && (
                                            <td className={
                                                item?.percentage_change > 0 ? 'green' :
                                                  item?.percentage_change < 0 ? 'red' : ''
                                            }>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.percentage_change
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.percentage_change
                                                          )
                                                      )}
                                            </td>
                                        )}
                                        <td>
                                            {i18n.language === lang.ENGLISH
                                                ? twoDecimals(item?.week_high)
                                                : EnglishToNepaliConverter(
                                                      twoDecimals(
                                                          item?.week_high
                                                      )
                                                  )}
                                        </td>
                                        <td>
                                            {i18n.language === lang.ENGLISH
                                                ? twoDecimals(item?.week_low)
                                                : EnglishToNepaliConverter(
                                                      twoDecimals(
                                                          item?.week_low
                                                      )
                                                  )}
                                        </td>
                                        <td>
                                            {i18n.language === lang.ENGLISH
                                                ? twoDecimals(item?.open_price)
                                                : EnglishToNepaliConverter(
                                                      twoDecimals(
                                                          item?.open_price
                                                      )
                                                  )}
                                        </td>
                                    </>
                                )}
                                <td>
                                    {i18n.language === lang.ENGLISH
                                        ? twoDecimals(
                                              item?.total_traded_quantity
                                          )
                                        : EnglishToNepaliConverter(
                                              twoDecimals(
                                                  item?.total_traded_quantity
                                              )
                                          )}
                                </td>
                                {!isMobile && (
                                    <td>
                                        {i18n.language === lang.ENGLISH
                                            ? new Intl.NumberFormat(
                                                  'en-US'
                                              ).format(
                                                  twoDecimals(
                                                      item?.average_traded_price
                                                  )
                                              )
                                            : EnglishToNepaliConverter(
                                                  new Intl.NumberFormat(
                                                      'en-US'
                                                  ).format(
                                                      twoDecimals(
                                                          item?.average_traded_price
                                                      )
                                                  )
                                              )}
                                    </td>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr className="today-share-price-table-no-information">
                            <td>{t('errors.noInformation')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TodaySharePriceTable

TodaySharePriceTable.propTypes = {
    payload: PropTypes.array.isRequired,
    status: PropTypes.string,
    lastElementRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
}
