import { Route, Switch, useLocation } from 'react-router'
import { useState, useEffect } from 'react'
// Pages
import News from 'pages/News/News'
import Forex from 'pages/Forex/Forex'
import InterestTopics from 'pages/InterestTopics/InterestTopics'
import Dashboard from 'pages/Dashboard/Dashboard'
import ErrorPage from 'utils/helpers/error/ErrorPage'
import NavBar from 'components/Layout/Navigation/Navbar/NavBar'
import ShareMarket from 'pages/ShareMarket/ShareMarket'
import Calculator from 'pages/Calculator/Calculator'
import NepaliCalendar from 'pages/NepaliCalendar/NepaliCalendar'
import DateConverter from 'pages/DateConverter/DateConverter'
import Gold from 'pages/Gold/Gold'
import Unicode from 'pages/Unicode/Unicode'
import Horoscope from 'pages/Horoscope/Horoscope'
import FMLists from 'pages/FMLists/index'
import NewsArticleTags from 'pages/NewsArticleTags/NewsArticleTags'
import Footer from 'components/Layout/Footer/Footer'
import NewsSinglePage from 'components/Layout/News/NewsSinglePage/NewsSinglePage'
import SingleHoroscope from 'components/Layout/Horoscope/SingleHoroscope/SingleHoroscope'
import PrivacyEknepal from "pages/Privacy/PrivacyEknepal";

// Components
import NewsTicker from 'components/NewsTicker/NewsTicker'

// Constants
import { routePaths } from 'global/constants/routePaths'
import { dummyData, navigationTitles } from 'global/constants/dummyData'
import { useTranslation } from 'react-i18next'
import AboutEknepal from 'pages/About/AboutEknepal'
import AboutMediaAllianceNepal from 'pages/About/AboutMediaAllianceNepal'
import TodaySharePrice from 'pages/TodaySharePrice/TodaySharePrice'

const PublicRoutes = () => {
    const [currentRoute, setCurrentRoute] = useState()
    const { t } = useTranslation()

    const location = useLocation()

    useEffect(() => {
        setCurrentRoute(navigationTitles[location.pathname])
    }, [location.pathname])

    document.title = `Eknepal - ${t(currentRoute)}`

    return (
        <>
            <NavBar dummyData={dummyData} />
            <NewsTicker />
            <Switch>
                <Route
                    exact
                    path={routePaths.dashboard}
                    component={Dashboard}
                />
                <Route
                    path={routePaths.interestTopics}
                    component={InterestTopics}
                />
                <Route
                    exact
                    path={routePaths.news.tagRouting}
                    component={NewsArticleTags}
                />
                <Route
                    path={routePaths.news.detailRouting}
                    component={NewsSinglePage}
                />
                <Route path={routePaths.news.base} component={News} />
                <Route path={routePaths.forex} component={Forex} />
                <Route path={routePaths.shareMarket} component={ShareMarket} />
                <Route
                    path={routePaths.todaySharePrice}
                    component={TodaySharePrice}
                />
                <Route path={routePaths.unicode} component={Unicode} />
                <Route
                    path={routePaths.dateConverter}
                    component={DateConverter}
                />
                <Route path={routePaths.calculator} component={Calculator} />
                <Route path={routePaths.calendar} component={NepaliCalendar} />
                <Route path={routePaths.gold} component={Gold} />
                <Route
                    path={routePaths.horoscope.detailRouting}
                    component={SingleHoroscope}
                />
                <Route path={routePaths.horoscope.base} component={Horoscope} />
                <Route path={routePaths.fm} component={FMLists} />
                <Route
                    path={routePaths.about.eknepal}
                    component={AboutEknepal}
                />
                <Route
                    path={routePaths.about.mediaAllianceNepal}
                    component={AboutMediaAllianceNepal}
                />
                <Route
                  path={routePaths.privacy.base}
                  component={PrivacyEknepal}
                />
                <Route path="*" component={ErrorPage} />
            </Switch>
            <Footer />
        </>
    )
}

export default PublicRoutes
