/* eslint-disable no-lone-blocks */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './news.scss'
import NewsVideos from 'components/Layout/News/NewsVideos/NewsVideos'
import LeftNewsBlocks from 'components/Layout/News/NewsBlocks/LeftNewsBlock/LeftNewsBlocks'
import RightNewsBlocks from 'components/Layout/News/NewsBlocks/RightNewsBlock/RightNewsBlocks'
import Title from 'components/Layout/Titles/Title'
import CategorySelector from 'components/Layout/News/CategorySelector/CategorySelector'
import Advertisement from 'components/Layout/Advertisement/Advertisement'

const News = () => {
    const [currentNewsTopic, setCurrentNewsTopic] = useState()

    // REMINDER: Remove this section once the API is ready
    const advertisementLeaderboardImages = [
        {
            bgImg: `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/11/700x100-2.gif`,
            urlLink: 'https://daraz.com.np',
        },
        {
            bgImg: `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/12/Imagekhabar-1.gif`,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const { t } = useTranslation()

    return (
        <>
            <section className="container">
                <CategorySelector
                    currentCategory={currentNewsTopic}
                    setCurrentCategory={setCurrentNewsTopic}
                />
                {/* <section className="advertisement-section">
                    <div className="advertisement-container">
                        <Advertisement
                            type="placeholder"
                            payload={advertisementLeaderboardImages}
                        />
                    </div>
                </section> */}

                <section className="news-section">
                    <section className="news-left-side-section">
                        <LeftNewsBlocks
                            currentNewsTopic={
                                currentNewsTopic !== 'all' && currentNewsTopic
                            }
                        />
                    </section>
                    <section className="news-right-side-section">
                        <RightNewsBlocks
                            currentNewsTopic={
                                currentNewsTopic !== 'all' && currentNewsTopic
                            }
                        />
                    </section>
                </section>
                {/*<section className="advertisement-section">*/}
                {/*    <div className="advertisement-container">*/}
                {/*        <Advertisement*/}
                {/*            type="placeholder"*/}
                {/*            payload={advertisementLeaderboardImages}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</section>*/}
            </section>
            <Title title={t('dashboard.videoSection')} bgColor="grey" />
            <section className="container">
                <section className="news-video-section">
                    <div className="news-video-container">
                        <NewsVideos />
                    </div>
                </section>
            </section>
        </>
    )
}

export default News
