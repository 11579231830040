export const endpoints = {
    advertisement: {
        base: 'v1/advertisements',
        type: 'v1/advertisements?type={typeId}',
    },
    auth: {
        base: 'v1/auth/tokens',
        forgotPassword: 'v1/auth/forgot-passwords',
        forgotPasswordOtp: 'v1/auth/forgot-passwords/otp',
        logout: 'v1/auth/logout',
        socialLogin: 'v1/auth/social/login',
        resetPassword: 'v1/auth/reset-passwords',
        users: {
            base: 'v1/users',
            registration: 'v1/users/registration',
            resendOtp: 'v1/users/resend-otp',
            verifyEmail: 'v1/users/verify-email',
        },
    },
    category: {
        base: 'v1/categories',
    },
    news: {
        base: 'v1/news?orderBy=created_at',
        details: 'v1/news/{id}',
        paginate: 'v1/news?page_size={pageSize}',
        catPaginate: 'v1/news?category={catName}&page_size={pageSize}',
        featuredNews: 'v1/news/featured-news',
        comment: 'v1/news/{id}/comment',
        like: 'v1/news/{id}/like',
        popularNews: {
            base: 'v1/news/popular-news/list',
            paginate: 'v1/news/popular-news/list?page_size={number}',
        },
        trendingNews: {
            base: 'v1/news/trending/list',
            paginate: 'v1/news/trending/list?page_size={number}',
        },
        breakingNews: {
            base: 'v1/news/breaking/list',
        },
        tag: {
            base: 'v1/news?tag={tagName}',
            paginate: 'v1/news?tag={tagName}&page_size={paginateNumber}',
        },
    },
    shareMarket: {
        index: {
            base: 'v1/index',
            graph: {
                base: 'v1/index/graph-list',
                typeDuration:
                    'v1/index/graph-list?type={typeName}&duration={durationTime}',
            },
        },
        closePrice: {
            base: 'v1/close-price?page_size={paginateNumber}',
            bySymbol:
                'v1/close-price?search={symbol}&page_size={paginateNumber}',
            byDate: 'v1/close-price?date={date}&page_size={paginateNumber}',
            byType: 'v1/close-price?type={type}',
            byDateSymbolPaginate:
                'v1/close-price?date={date}&search={symbol}&page_size={paginateNumber}',
        },
        marketSummary: 'v1/index/market-summery',
    },
    videos: {
        base: 'v1/videos',
        details: 'v1/videos/{id}',
        paginate: 'v1/videos?page_size={paginateNumber}',
    },
    horoscopes: {
        base: 'v1/horoscopes',
        detail: 'v1/horoscopes/detail?sign={horoscopeSign}&type={currentLanguage}',
    },
    weather: {
        base: '/v1/weather',
        latAndLong: 'v1/weather?lat={lattitudeNum}&lon={longitudeNum}',
    },
    forex: {
        base: 'v1/rate?per_page={perPage}&from={fromDate}&to={toDate}',
    },
    fmAndTv: {
        fm: 'v1/fmandtvs?type=fm&lang={language}',
        tv: 'v1/fmandtvs?type=tv&lang={language}',
    },
    ePaper: {
        base: 'v1/magazines?lang={language}',
    },
    goldSilver: {
        base: 'v1/gold-or-silvers/latest-list',
        graph: 'v1/gold-or-silvers/graph/list',
    },
    calendar: {
        dob: {
            base: 'v1/dobs',
            basedOnDate: 'v1/dobs?dob={queryDate}',
        },
        holidays: {
            base: 'v1/holidays',
            basedOnLanguage: 'v1/holidays?type={currentLang}',
        },
        events: {
            base: 'v1/events',
            basedOnNepaliMonthAndLang:
                'v1/events?type={currentLang}&month={monthNumber}',
        },
    },
    calculator: {
        share: {
            base: 'v1/sharecalcultors/calculation-data',
        },
    },
}
