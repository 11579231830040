import './newsArticleTags.scss'
import { useParamsQuery } from 'utils/helpers/URLLocation'
import { useState, useEffect } from 'react'
import { useFetchNewsBasedOnTag } from 'hooks/news/useFetchNewsBasedOnTag'
import { useTranslation } from 'react-i18next'
import SingleNewsLeftBlock from 'components/Layout/News/NewsBlocks/LeftNewsBlock/SingleNewsLeftBlock/index'
import Spinner from 'components/Spinner/Spinner'
import Button from 'components/Layout/Button/Button'
import Title from 'components/Layout/Titles/Title'

const NewsArticleTags = () => {
    const searchQuery = useParamsQuery()
    const [newsArticleTag, setNewsArticleTag] = useState()
    const [paginateNumber, setPaginateNumber] = useState(20)
    const [newsToShow, setNewsToShow] = useState([])

    const { t } = useTranslation()

    useEffect(() => {
        setNewsArticleTag(decodeURIComponent(searchQuery.get('tag-name')))

        // eslint-disable-next-line
    }, [searchQuery.get('tag-name')])

    const NEWSPERPAGE = 20

    const { status: newsBasedOnTagStatus, data: newsBasedOnTagData } =
        useFetchNewsBasedOnTag(newsArticleTag, NEWSPERPAGE)

    function loadMore() {
        if (newsBasedOnTagStatus === 'success') {
            setNewsToShow((prevNews) => {
                return [
                    ...new Set([
                        ...prevNews,
                        ...newsBasedOnTagData.payload.slice(
                            paginateNumber - NEWSPERPAGE,
                            paginateNumber
                        ),
                    ]),
                ]
            })
            setPaginateNumber(paginateNumber + NEWSPERPAGE)
        }
    }

    useEffect(() => {
        if (newsBasedOnTagStatus === 'success') {
            setNewsToShow(newsBasedOnTagData?.payload)
        }
    }, [newsBasedOnTagStatus, newsBasedOnTagData])

    return (
        <div className="container">
            <div className="tag-page-container">
                <Title title={`${t('tag')}: ${newsArticleTag}`} />

                <div className="tag-page-news-container">
                    {Array.isArray(newsToShow) &&
                        newsToShow.map((news, index) => (
                            <SingleNewsLeftBlock payload={news} key={index} />
                        ))}
                </div>
            </div>
            {newsBasedOnTagStatus === 'loading' ? (
                <Spinner />
            ) : (
                <div className="tag-page-button-container">
                    <Button
                        size="large"
                        bgColor="grey"
                        handleButtonClick={loadMore}
                        description={t('more')}
                        center={true}
                    />
                </div>
            )}
        </div>
    )
}

export default NewsArticleTags
