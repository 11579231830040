import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

const putCommentSingleNews = (necessaryData) => {
    const { newsId, comment } = necessaryData
    if (newsId) {
        const updatedEndpoints = endpoints.news.comment.replace('{id}', newsId)
        return http().put(updatedEndpoints, comment)
    }
}

export default function usePutCommentSingleNews() {
    return useMutation(putCommentSingleNews, {
        onSuccess: () => {
            toast.success('You have commented successfully!')
        },
        onError: (err) => {
            Object.keys(err.errors).map((item, index) =>
                toast.error(err.errors[item][0])
            )
        },
    })
}
