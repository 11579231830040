import { useState, useEffect } from 'react'
import 'nepali-datepicker-reactjs/dist/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'components/ReactSelect'
import './BsToAdConverter.scss'

import NepaliDate from 'nepali-date-converter'
import {
    dateConverterDataMonthDay,
    dateConverterDataYear,
    dateConverterMonthEn,
    dateConverterMonthNp,
} from 'global/constants/dummyData'

const BsToAdConverter = () => {
    const { t } = useTranslation()

    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }

    const [userSelectedDate, setUserSelectedDate] = useState({
        day: new NepaliDate().getBS().date,
        month: new NepaliDate().getBS().month,
        year: new NepaliDate().getBS().year,
    }) 

    const [resultDate, setResultDate] = useState(
        new Date().toLocaleDateString('en-GB', options)
    )

    useEffect(() => {
        try {
            const finalDate = new NepaliDate(
                `${userSelectedDate.year}-${userSelectedDate.month}-${userSelectedDate.day}`
            ).getAD()
            setResultDate(
                `${finalDate.date} ${
                    dateConverterMonthEn[finalDate.month + 1].label
                } ${finalDate.year}`
            )
        } catch (err) {
            // do nothing
        }
    }, [userSelectedDate])

    return (
        <div className="date-converter-container">
            <div className="date-container">
                <div className="date-left-container">
                    <div className="date-left-select-container">
                        <ReactSelect
                            color="#1D1D1F"
                            className="date-converter-select"
                            classNamePrefix="date-converter-select-prefix"
                            handleOnChange={(value) =>
                                setUserSelectedDate((prevState) => ({
                                    ...prevState,
                                    day: value.value,
                                }))
                            }
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            selectDefaultValue={{
                                label: userSelectedDate.day,
                                value: userSelectedDate.day,
                            }}
                            options={
                                dateConverterDataMonthDay[
                                    parseInt(userSelectedDate.month) - 1
                                ].day_np
                            }
                            isSearchable={false}
                        />
                        <ReactSelect
                            color="#1D1D1F"
                            className="date-converter-select"
                            handleOnChange={(value) =>
                                setUserSelectedDate((prevState) => ({
                                    ...prevState,
                                    month: value.value,
                                }))
                            }
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            classNamePrefix="date-converter-select-prefix"
                            selectDefaultValue={{
                                label: dateConverterMonthNp[
                                    parseInt(userSelectedDate.month)
                                ].label,
                                value: userSelectedDate.month,
                            }}
                            options={dateConverterMonthNp}
                            isSearchable={false}
                        />
                        <ReactSelect
                            color="#1D1D1F"
                            className="date-converter-select"
                            handleOnChange={(value) =>
                                setUserSelectedDate((prevState) => ({
                                    ...prevState,
                                    year: value.value,
                                }))
                            }
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            classNamePrefix="date-converter-select-prefix"
                            selectDefaultValue={{
                                label: userSelectedDate.year,
                                value: userSelectedDate.year,
                            }}
                            options={dateConverterDataYear.np}
                            isSearchable={false}
                        />
                    </div>
                    <p className="date-left-from-text">
                        {t('dateConverter.from')}
                    </p>
                </div>
                <div className="date-right-container">
                    <div className="date-col-upper">
                        <div htmlFor="date-bs" className="date-label">
                            <p className="bs-paragraph">
                                {t('dateConverter.ad')}
                            </p>
                        </div>
                        <div className="result-container">
                            <p className="converted-date-paragraph">
                                {resultDate}
                            </p>
                        </div>
                    </div>
                    <div className="date-col-lower">
                        <p className="to-paragraph">{t('dateConverter.to')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BsToAdConverter
