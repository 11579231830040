import EllipseMenuItem from '../EllipseMenuItem/EllipseMenuItem'

import token from 'utils/token'
import { useState, useEffect, useRef } from 'react'

import classNames from 'classnames'

import './ellipseMenu.scss'

import { FaEllipsisH } from 'react-icons/fa'
import { ClickOutside } from 'utils/helpers/clickOutside/ClickOutside'

import { likeIconDarkImg, shareIconImg, commentIconImg } from 'assets'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { routePaths } from 'global/constants/routePaths'
import usePutLikeSingleNews from 'hooks/news/usePutLikeSingleNews'

const EllipseMenu = ({ top, className, open = false, newsId }) => {
    const { mutate: likeFn } = usePutLikeSingleNews()

    const [isLoggedIn, setIsLoggedIn] = useState(
        token.getUserToken() !== null ? true : false
    )
    const [active, setActive] = useState(open)

    const userToken = token.getUserToken()

    useEffect(() => {
        setIsLoggedIn(token.getUserToken() !== null ? true : false)
    }, [userToken])

    const menuRef = useRef(null)

    ClickOutside(menuRef, () => setActive(false))

    const { t } = useTranslation()

    const history = useHistory()

    const handleLikeClick = (e) => {
        setActive(false)
        likeFn(newsId, { like: 1 })
    }

    const handleShareClick = () => {
        setActive(false)
        history.push(
            `${routePaths.news.details.replace(
                '{idNumber}',
                newsId
            )}#share-section`
        )
    }

    const handleCommentClick = () => {
        setActive(false)
        history.push(
            `${routePaths.news.details.replace(
                '{idNumber}',
                newsId
            )}#comment-section`
        )
    }

    return (
        isLoggedIn && (
            <>
                <div
                    className={`extra-options-container ${className}`}
                    onClick={() => setActive(!active)}
                >
                    <FaEllipsisH className="extra-options-icon" />
                </div>
                <div
                    className={classNames('ellipse-menu-container', {
                        inactive: !active,
                        top: top,
                    })}
                    ref={menuRef}
                >
                    <EllipseMenuItem
                        icon={likeIconDarkImg}
                        desc={t('ellipseMenu.likeNews')}
                        handleClick={handleLikeClick}
                    />
                    <EllipseMenuItem
                        icon={shareIconImg}
                        desc={t('ellipseMenu.shareNews')}
                        handleClick={handleShareClick}
                    />
                    <EllipseMenuItem
                        icon={commentIconImg}
                        desc={t('ellipseMenu.addCommentNews')}
                        handleClick={handleCommentClick}
                    />
                </div>
            </>
        )
    )
}

export default EllipseMenu
