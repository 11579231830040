/* eslint-disable no-unused-vars */

import './commentSection.scss'

import SingleComment from './SingleComment/SingleComment'

const CommentSection = ({ payload }) => {
    return (
        <section className="comment-page-section">
            <div className="comment-page-container">
                {Array.isArray(payload) && payload.length > 0
                    ? payload.map((comment, index) => (
                          <SingleComment
                              commentData={comment}
                              isLast={index === payload.length - 1}
                              key={index}
                          />
                      ))
                    : null}
            </div>
        </section>
    )
}

export default CommentSection
