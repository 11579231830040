/* eslint-disable react-hooks/exhaustive-deps */

import './leftNewsBlocks.scss'
import { useFetchNewsPaginate } from 'hooks/news/useFetchNewsPaginate'
import SingleNewsLeftBlock from './SingleNewsLeftBlock'
import Spinner from 'components/Spinner/Spinner'
import { useState, useEffect } from 'react'
import Button from 'components/Layout/Button/Button'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'

const LeftNewsBlocks = ({ currentNewsTopic }) => {
    const [paginateNumber, setPaginateNumber] = useState(20)
    const { status: statusNewsPaginate, data: newsDataPaginate } =
        useFetchNewsPaginate(paginateNumber, currentNewsTopic)
    const [newsToShow, setNewsToShow] = useState([])

    const NEWSPERPAGE = 20

    function loadMore() {
        if (statusNewsPaginate === 'success') {
            setNewsToShow((prevNews) => {
                return [
                    ...new Set([
                        ...prevNews,
                        ...newsDataPaginate.payload.slice(
                            paginateNumber - NEWSPERPAGE,
                            paginateNumber
                        ),
                    ]),
                ]
            })
            setPaginateNumber(paginateNumber + NEWSPERPAGE)
        }
    }

    useEffect(() => {
        setPaginateNumber(20)
    }, [currentNewsTopic])

    useEffect(() => {
        if (statusNewsPaginate === 'success') {
            setNewsToShow(newsDataPaginate?.payload)
        }
    }, [statusNewsPaginate, newsDataPaginate])

    const { t } = useTranslation()

    return (
        <>
            <div className="left-news-block-container">
                {Array.isArray(newsToShow) &&
                    newsToShow.map((news, index) => (
                        <SingleNewsLeftBlock payload={news} key={index} />
                    ))}
            </div>
            {statusNewsPaginate === 'loading' ? (
                <Spinner />
            ) : (
                <div className="left-news-block-button-container">
                    <Button
                        size="large"
                        bgColor="grey"
                        handleButtonClick={loadMore}
                        description={t('more')}
                        center={true}
                    />
                </div>
            )}
        </>
    )
}

export default LeftNewsBlocks

LeftNewsBlocks.propTypes = {
    currentNewsTopic: PropTypes.string,
}
