export const routePaths = {
    // First time
    language: '/',
    interestTopics: '/interested-topics',

    // After first time
    dashboard: '/',
    news: {
        base: '/news',
        moduleSubCategory: '/news?module={moduleName}',
        details: '/news/details?id={idNumber}',
        detailRouting: '/news/details',
        tag: '/news/tag?tag-name={tagName}',
        tagRouting: '/news/tag',
    },
    forex: '/forex',
    shareCalculator: '/share-calculator',
    shareMarket: '/share-market',
    todaySharePrice: '/today-share-price',
    calendar: '/calendar',
    dateConverter: '/date-converter',
    gold: '/gold',
    unicode: '/unicode',
    emiCalculator: '/emi-calculator',
    tvLists: '/tv',
    radio: '/radio',
    horoscope: {
        base: '/horoscope',
        details: '/horoscope/details?sign={horoscopeSign}',
        detailRouting: '/horoscope/details',
    },
    fm: '/fm',
    calculator: '/calculator',
    about: {
        base: '/about',
        eknepal: '/about/eknepal',
        mediaAllianceNepal: '/about/media-alliance-nepal',
    },
    privacy: {
        base: '/privacy',
    }
}
