/* eslint-disable no-unused-vars */
import React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LabelInput from 'components/Layout/LabelInput/LabelInput'
import './forgotPassword.scss'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import Button from 'components/Layout/Button/Button'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { authRoutes } from 'global/constants/appConstants'
import usePostForgotPasswordUsername from 'hooks/userAuth/usePostForgotPassword'
import useStore from 'store/store'
import Spinner from 'components/Spinner/Spinner'

const ForgotPassword = ({ show, setCurrentPage }) => {
    const { t } = useTranslation()

    const {
        mutate: forgotPasswordFn,
        isLoading: forgotPasswordLoading,
        isSuccess: forgotPasswordSuccess,
    } = usePostForgotPasswordUsername()

    const schema = object().shape({
        email: string()
            .email(t('errors.email'))
            .required(t('errors.fieldRequired')),
    })

    // NOTE: Global state
    const addForgotEmail = useStore((store) => store.addForgotEmail)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isValid) {
            const postData = {
                email: values.email,
            }
            forgotPasswordFn(postData)
        }
    }

    useEffect(() => {
        if (forgotPasswordSuccess) {
            addForgotEmail(values.email)
            setCurrentPage(authRoutes.OTP)
        }

        // eslint-disable-next-line
    }, [forgotPasswordSuccess])

    const {
        handleBlur,
        values,
        setFieldValue,
        errors,
        isValid,
        touched,
        setErrors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: handleSubmit,
        initialValues: {
            email: '',
        },
        initialErrors: {
            email: true,
        },
        enableReinitialize: true,
    })

    const handleSignInRedirect = (e) => {
        e.preventDefault()
        setCurrentPage(authRoutes.SIGNIN)
    }

    useEffect(() => {
        setErrors({})

        // eslint-disable-next-line
    }, [show])

    return (
        <div
            className={classNames('password-forget-container', {
                show: show,
            })}
        >
            <h4 className="password-forget-heading">
                {t('authentication.welcomeToEknepal')}
            </h4>
            <form onSubmit={handleSubmit} className="password-forget-form">
                <LabelInput
                    labelDisplay="none"
                    inputName="email"
                    value={values.email}
                    touchedError={errors.email}
                    handleOnChange={(e) =>
                        setFieldValue('email', e.target.value)
                    }
                    touched={touched.email}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.email')}
                />
                {forgotPasswordLoading ? (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={<Spinner isWhite={true} />}
                    />
                ) : (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={t('authentication.sendInstructions')}
                        disabled={!isValid}
                    />
                )}
            </form>
            <div className="password-forget-bottom-container">
                <div className="password-forget-or-divider">
                    {t('authentication.signInSection.or')}
                </div>
                <div className="password-forget-bottom-buttons-container">
                    <Button
                        disabled={!show}
                        size="widthHundred"
                        bgColor="grey"
                        description={t('authentication.goBackSignIn')}
                        handleButtonClick={handleSignInRedirect}
                    />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword

ForgotPassword.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
}
