/* eslint-disable no-unused-vars */
import './horoscopes.scss'
import Title from 'components/Layout/Titles/Title'
import { useFetchHoroscopes } from 'hooks/horoscopes/useFetchHoroscopes'
import Spinner from 'components/Spinner/Spinner'
import HoroscopeCard from 'components/Layout/Horoscope/HoroscopeCard'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'

const Horoscope = () => {
    const { t } = useTranslation()

    const { status: horoscopeStatus, data: horoscopeData } =
        useFetchHoroscopes()

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
    }

    const [currentDate, setCurrentDate] = useState(new Date())

    const { day, month, year, weekday } = useGetDateBasedOnCurrentLang(
        currentDate,
        options
    )

    return (
        <>
            <Title title={t('horoscope.horoscopeTitle')} bgColor="grey" />
            <div className="container">
                <div className="horoscope-time-container">
                    <p className="time-zone-container">
                        {t('horoscope.todaysHoroscope').replace(
                            '{date}',
                            `${day} ${month} ${year}, ${weekday}`
                        )}
                    </p>
                </div>
                {horoscopeStatus === 'loading' ? (
                    <Spinner />
                ) : (
                    <div className="horoscope-item-container">
                        {Array.isArray(horoscopeData?.payload) &&
                            horoscopeData?.payload?.length > 0 &&
                            horoscopeData?.payload.map((item, index) => (
                                <HoroscopeCard payload={item} key={index} />
                            ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default Horoscope
