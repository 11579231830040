import Title from 'components/Layout/Titles/Title'
import './about.scss'
import { mediaAllianceLogoImg } from 'assets'

const AboutMediaAllianceNepal = () => {
    // REMINDER: If the client asks for a dual language support in this section, then just go ahead and put the useTranslation hook there and switch it up.
    const contentArr = [
        `Founded in 2020, Media Alliance Nepal (MAN) is an umbrella organization of privately owned national daily newspapers, satellite television stations and high megahertz radio stations of Nepal. MAN is a non-profit organization of Nepal's mainstream media companies. `,
        `Our mission is to protect the rights of journalists, staff and publishers. We share our expertise with our members so they can adapt and prosper in a digital world. We train our members so they can contribute for the greater good of the society through the fourth estate.`,
        `MAN is committed to develop business cooperation among media companies, sharing best practices in both business and technology. MAN promotes press freedom and advocates for the independence of media companies.`,
    ]

    const companyDetailsArr = [
        {
            companyName: 'Kamana News Publications',
            brands: 'Kamana, Sadhana, Mahanagar, Nepal samacharpatra, News of Nepal',
            establishedDateBS: 'Mangsir 15, 2041',
            establishedDateAD: 'November 30, 1984',
        },
        {
            companyName: 'Impression Publication and Media ',
            brands: 'Himalaya Times',
            establishedDateBS: 'Poush 10, 2052',
            establishedDateAD: 'September 25, 1995',
        },
        {
            companyName: 'Image Channel ',
            brands: 'Image FM (97.9 & 103.6), Image Channel, Image Khabar',
            establishedDateBS: 'Baisakh 1, 2053',
            establishedDateAD: 'October 17, 1996',
        },
        {
            companyName: 'Rajdhani News Publication',
            brands: 'Rajdhani, Rajdhani Khabar',
            establishedDateBS: 'Jestha 22, 2058',
            establishedDateAD: 'June 4, 2001',
        },
        {
            companyName: 'Avenues Khabar',
            brands: 'Avenues Khabar, Avenues Network, Nepal FM',
            establishedDateBS: 'Shrawan 13, 2059',
            establishedDateAD: 'July 29, 2002',
        },
        {
            companyName: 'TBI Publications ',
            brands: 'Karobar, Karobar Online TV',
            establishedDateBS: 'Bhadra 16, 2066',
            establishedDateAD: 'September 1, 2009',
        },
        {
            companyName: '(Nepal Broadcasting Channel)',
            brands: 'News 24 ',
            establishedDateBS: 'Baisakh 8, 2067',
            establishedDateAD: 'April 21, 2010',
        },
        {
            companyName: 'Janata Television',
            brands: 'Janata TV, Radio Janta, Janta SamacharJanata Television',
            establishedDateBS: 'Magh 19 2068',
            establishedDateAD: 'February 2, 2012',
        },
        {
            companyName: 'Sagarmatha TV',
            brands: 'Sagarmatha TV',
            establishedDateBS: 'Shrawan 23, 2062',
            establishedDateAD: 'August 7, 2005',
        },
        {
            companyName: 'Prime Times',
            brands: 'Prime Times HD',
            establishedDateBS: 'Magh 11, 2073',
            establishedDateAD: 'January 24, 2017',
        },
        {
            companyName: 'Annapurna',
            brands: 'AP1, Annapurna Post, Annapurna Express, Radio Annapurna',
            establishedDateBS: 'Baisakh 20, 2059',
            establishedDateAD: 'May 3, 2002',
        },
        {
            companyName: 'Nepal Republic Media ',
            brands: 'Nagarik, Republica, Pariwar, Nagarik Network',
            establishedDateBS: 'Baisakh 11, 2065',
            establishedDateAD: 'April 23, 2008',
        },
        {
            companyName: 'Yoho TV',
            brands: 'Yoho TV, Yoho Khabar',
            establishedDateBS: '',
            establishedDateAD: '',
        },{
            companyName: 'Mountain TV',
            brands: 'Business TV, Mountain TV, Bhakti Darshan',
            establishedDateBS: '',
            establishedDateAD: '',
        },


    ]

    return (
        <>
            <Title title="About Media Alliance Nepal" bgColor="grey" />
            <div className="container">
                <div className="about-container">
                    <div className="about-content-container">
                        <figure className="about-content-figure">
                            <img src={mediaAllianceLogoImg} alt="" />
                        </figure>
                        <div className="about-text-container">
                            {contentArr.map((item, index) => (
                                <p className="about-text" key={index}>
                                    {item}
                                </p>
                            ))}
                        </div>
                    </div>

                    <table className="about-table-container">
                        <thead>
                            <tr className="about-table-heading-row">
                                <th>SN</th>
                                <th>Company Name</th>
                                <th>Brands</th>
                                <th>Established Date (BS)</th>
                                <th>Established Date (AD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companyDetailsArr.map((item, index) => (
                                <tr
                                    className="about-table-body-row"
                                    key={index}
                                >
                                    <td>{index + 1}</td>
                                    <td>{item.companyName}</td>
                                    <td>{item.brands}</td>
                                    <td>{item.establishedDateAD}</td>
                                    <td>{item.establishedDateAD}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default AboutMediaAllianceNepal
