import EMICalculator from './EmiCalculator/EmiCalculator'
import ShareCalculator from './ShareCalculator/ShareCalculator'
import LandCalculator from './LandCalculator/LandCalculator'
import WeightCalculator from './WeightCalculator/weightCalculator'

import { PropTypes } from 'prop-types'
import TemperatureConveter from './TemperatureCalculator/TemperatureConverter'

const CalculatorContent = ({ activeCalculator }) => {
    switch (activeCalculator) {
        case 0:
            return <ShareCalculator />
        case 1:
            return <EMICalculator />
        case 2:
            return <LandCalculator />
        case 3:
            return <WeightCalculator />
        case 4:
            return <TemperatureConveter />
        default:
            return <ShareCalculator />
    }
}

export default CalculatorContent

CalculatorContent.propType = {
    activeCalculator: PropTypes.number.isRequired,
}
