import './exchangeRates.scss'

import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import NumberConverter from 'hooks/numberConverter/NumberConverter'

const ExchangeRates = ({ payload }) => {
    const { i18n, t } = useTranslation()
    return (
        <table className="exchange-rate-container">
            {/* Headings section */}
            <thead className="exchange-rate-heading-container">
                <tr className="exchange-rate-headings">
                    <th className="first-heading">
                        {t('forex.foreignExchange')}
                    </th>
                    <th>{t('forex.sellingRate')}</th>
                    <th>{t('forex.buyingRate')}</th>
                </tr>
            </thead>
            <tbody>
                {payload.map((country, index) => (
                    <tr className="exchange-rate-body-row" key={index}>
                        <td className="exchange-rate-body-left">
                            <span
                                className={`flag flag--${country.currency.iso3}`}
                            ></span>
                            <div className="exchange-rate-list-left-text">
                                <div className="exchange-rate-list-left-text-heading">
                                    <h4>{country.currency.iso3}</h4>
                                    <span className="conditional-for-each-amount">
                                        {country.currency.unit > 1
                                            ? t('forex.perUnit').replace(
                                                  '{number}',
                                                  i18n.language === lang.NEPALI
                                                      ? NumberConverter(
                                                            country.currency
                                                                .unit
                                                        )
                                                      : country.currency.unit
                                              )
                                            : ''}
                                    </span>
                                </div>
                                <span className="country-full-name">
                                    {country.currency.name}
                                </span>
                            </div>
                        </td>
                        <td>
                            {i18n.language === lang.NEPALI
                                ? NumberConverter(country.buy)
                                : country.buy}
                        </td>
                        <td>
                            {i18n.language === lang.NEPALI
                                ? NumberConverter(country.sell)
                                : country.sell}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ExchangeRates

ExchangeRates.propTypes = {
    payload: PropTypes.array.isRequired,
}
