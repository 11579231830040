import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchShareIndex = () => {
    const updatedEndpoints = endpoints.shareMarket.index.base
    return http().get(updatedEndpoints)
}

export function useFetchShareIndex() {
    return useQuery(['fetchShareIndex'], () => fetchShareIndex(), {
        retry: 3,
    })
}
