import Spinner from 'components/Spinner/Spinner'

import './videosBundle.scss'
import NewsHighlightVideos from '../News/NewsVideos/NewsHighlightVideos/NewsHighlightVideos'
import { useFetchVideosPaginate } from 'hooks/videos/useFetchVideosPaginate'

const VideosBundle = () => {
    const { status: statusVideo, data: videosData } = useFetchVideosPaginate(8)

    return statusVideo === 'loading' ? (
        <Spinner />
    ) : (
        <div className="video-bundle-container">
            {/* <div className="video-bundle-left-side">
                    <FeaturedVideo payload={videosData?.payload[0]} />
                    <FourPieceVideos
                        payload={videosData?.payload.slice(0, 4)}
                    />
                </div>
                <div className="video-bundle-right-side">
                    <RightSideVideos payload={videosData?.payload} />
                </div> */}
            <NewsHighlightVideos payload={videosData?.payload} />
        </div>
    )
}

export default VideosBundle
