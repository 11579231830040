import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import './reactDatePicker.scss'

const ReactDatePicker = ({
    inputClassName,
    size,
    handleOnChange,
    noStyles,
    icon,
    inputNoBorder,
    startDate,
}) => {
    return (
        <div className="react-date-picker-container">
            <label className="react-date-picker-input-icon-container">
                <DatePicker
                    selected={startDate ? startDate : new Date()}
                    onChange={handleOnChange}
                    className={classNames(
                        `react-date-picker-field ${
                            inputClassName ? inputClassName : ''
                        }`,
                        {
                            small: size === 'small' || size === undefined,
                            medium: size === 'medium',
                            large: size === 'large',
                            'no-styles': noStyles,
                            'no-border': inputNoBorder,
                        }
                    )}
                />
                {icon && (
                    <div className="react-date-picker-icon-container">
                        {icon}
                    </div>
                )}
            </label>
        </div>
    )
}

export default ReactDatePicker

ReactDatePicker.propTypes = {
    labelName: PropTypes.string,
    labelHtmlFor: PropTypes.string,
    inputType: PropTypes.string,
    labelClassName: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    inputClassName: PropTypes.string,
    size: PropTypes.string,
    inputValue: PropTypes.string,
    inputName: PropTypes.string,
    handleOnChange: PropTypes.func,
    labelDisplay: PropTypes.string,
    error: PropTypes.string,
    handleOnBlur: PropTypes.func,
    touched: PropTypes.bool,
    noStyles: PropTypes.bool,
    icon: PropTypes.element,
    startDate: PropTypes.instanceOf(Date),
}
