/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import './singleHoroscope.scss'
import { useParamsQuery } from 'utils/helpers/URLLocation'
import { useFetchHoroscopesDetails } from 'hooks/horoscopes/useFetchHoroscopeDetails'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import Spinner from 'components/Spinner/Spinner'

const SingleHoroscope = () => {
    const { i18n } = useTranslation()

    const [sign, setSign] = useState()

    const searchQuery = useParamsQuery()
    useEffect(() => {
        setSign(searchQuery.get('sign'))

        // eslint-disable-next-line
    }, [])

    const { status: singleHoroscopeStatus, data: singleHoroscopeData } =
        useFetchHoroscopesDetails(sign?.toLowerCase(), i18n.language)

    return singleHoroscopeStatus === 'success' ? (
        <section className="container">
            <div className="single-horoscope-container bg-clr-primary-500">
                <figure className="single-horoscope-image-figure bg-clr-dark-blue">
                    {singleHoroscopeData?.payload?.image?.image && (
                        <img
                            src={singleHoroscopeData?.payload?.image?.image}
                            alt="single horoscope"
                            className="single-horoscope-image"
                        />
                    )}
                </figure>
                <div className="single-horoscope-text-container color-white">
                    <p className="single-horoscope-date ">
                        {i18n.language === lang.NEPALI
                            ? singleHoroscopeData?.payload?.date_np
                            : singleHoroscopeData?.payload?.date}
                    </p>
                    <h3 className="single-horoscope-sign-name">
                        {i18n.language === lang.NEPALI
                            ? singleHoroscopeData?.payload?.sign_np
                            : singleHoroscopeData?.payload?.sign}
                    </h3>
                    <p className="single-horoscope-description">
                        {i18n.language === lang.NEPALI
                            ? singleHoroscopeData?.payload?.description_np
                            : singleHoroscopeData?.payload?.description}
                    </p>
                </div>
            </div>
        </section>
    ) : (
        <Spinner />
    )
}

export default SingleHoroscope
