import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import token from 'utils/token'
import useStore from 'store/store'
import * as storage from 'utils/storage'
import { detailConst } from 'global/constants/appConstants'

const postUserCredentials = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.auth.base
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostUserLogin() {
    const addUserDetails = useStore((store) => store.addUserDetails)
    return useMutation(postUserCredentials, {
        onSuccess: ({ payload }) => {
            token.setUserToken({
                userToken: `Bearer ${payload?.token}`,
                expiryDate: 86400000,
            })
            addUserDetails(payload?.user)
            storage.set(detailConst.USER_DETAILS, payload?.user)
            toast.success('You have logged in successfully!')
        },
        onError: (err) => {
            toast.error(err?.message)
        },
    })
}
