import { useQuery } from 'react-query'
import { closePriceTypes } from 'global/constants/appConstants'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchTopTurnovers = () => {
    const updatedEndpoints = endpoints.shareMarket.closePrice.byType.replace(
        '{type}',
        closePriceTypes.TOP_TURNOVER
    )
    return http().get(updatedEndpoints)
}

export function useFetchTopTurnovers(options) {
    return useQuery(['fetchTopTurnovers'], () => fetchTopTurnovers(), {
        retry: 3,
        ...options,
    })
}
