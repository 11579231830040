import token from './token'
import i18n from 'i18n'

/**
 * Transform header based on headerType
 *
 * @param {THeader} headerType
 * 
const xhr = new XMLHttpRequest();
const url = 'https://bar.other/resources/public-data/';

xhr.open('GET', url);
xhr.onreadystatechange = someHandler;
xhr.send();
 */

const getHeader = (headerType, locale = true) => {
    const userToken = token.getUserToken() || ''
    let header = {}

    switch (headerType) {
        case 'json':
            header = locale
                ? {
                      'Content-Type': 'application/json',
                      Authorization: userToken ? `Bearer ${userToken}` : '',
                      locale: i18n.language,
                  }
                : {
                      'Content-Type': 'application/json',
                      Authorization: userToken ? `Bearer ${userToken}` : '',
                  }
            break

        case 'multipart':
            header = locale
                ? {
                      'Content-Type': 'multipart/form-data',
                      Authorization: userToken ? `Bearer ${userToken}` : '',
                      locale: i18n.language,
                  }
                : {
                      'Content-Type': 'multipart/form-data',
                      Authorization: userToken ? `Bearer ${userToken}` : '',
                  }
            break

        case 'default':
            header = locale
                ? {
                      'Content-Type': 'application/json',
                      locale: i18n.language,
                  }
                : {
                      'Content-Type': 'application/json',
                  }
            break

        default:
            header = locale
                ? {
                      'Content-Type': 'application/json',
                      locale: i18n.language,
                  }
                : {
                      'Content-Type': 'application/json',
                  }
            break
    }

    return header
}

export default getHeader
