/* eslint-disable no-unused-vars */
import './singleTabContent.scss'
import SingleTabContentText from './SingleTabContentText/SingleTabContentText'
// import SingleTabContentAdvertisement from './SingleTabContentAdvertisement/SingleTabContentAdvertisement'
// import SingleTabContentVideo from './SingleTabContentVideo/SingleTabContentVideo'
// import SingleTabContentAudio from './SingleTabContentAudio/SingleTabContentAudio'
// import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import { routePaths } from 'global/constants/routePaths'
import ConvertToRelativeTime from 'utils/ConvertToRelativeTime'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const SingleTabContent = ({ payload, activeTabItem }) => {
    const history = useHistory()

    const handleItemClick = (e) => {
        history.push(routePaths.news.details.replace('{idNumber}', payload?.id))
    }

    // REMINDER: If the API will support the "type" key in the future, include the commented out code and adjust the code accordingly

    return (
        <>
            {/* {payload.type.toLowerCase() === 'text' && ( */}

            <SingleTabContentText
                img={replaceHttpWithHttps(payload?.image)}
                title={payload.title}
                newsAgencyName={payload.channel_name}
                newsAgencyIcon={replaceHttpWithHttps(payload?.channel_icon)}
                activeTabItem={activeTabItem}
                handleClick={handleItemClick}
                date={ConvertToRelativeTime(
                    new Date(payload?.created_at ? payload.created_at : null)
                )}
            />
            {/* )} */}
            {/* {payload.type.toLowerCase() === 'advertisement' && (
                <a
                    href={payload.url}
                    target="_blank"
                    rel="noreferrer"
                    className="single-tab-content-link"
                >
                    <SingleTabContentAdvertisement
                        img={personImg}
                        advertisementAgencyName="Advertisement Agency"
                        title={payload.desc}
                    />
                </a>
            )}
            {payload.type.toLowerCase() === 'video' && (
                <a
                    href={payload.url}
                    target="_blank"
                    rel="noreferrer"
                    className="single-tab-content-link"
                >
                    <SingleTabContentVideo
                        img={personImg}
                        title={payload.desc}
                        newsAgencyName={newsAgencyName}
                        newsAgencyIcon={annapurnaMediaNetworkImg}
                        activeTabItem={activeTabItem}
                    />
                </a>
            )}
            {payload.type.toLowerCase() === 'audio' && (
                <SingleTabContentAudio
                    img={personImg}
                    title={payload.desc}
                    newsAgencyName={newsAgencyName}
                    activeTabItem={activeTabItem}
                    newsAgencyIcon={annapurnaMediaNetworkImg}
                />
            )} */}
        </>
    )
}

export default SingleTabContent
