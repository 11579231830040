import http from 'utils/http'
import { endpoints } from 'global/endpoints'

export const advertisementSlice = (set, get) => ({
    advertisement: {},
    addAdvertisement: () => {
        const middleArray = []
        for (let i = 0; i < 9; i++) {
            const updatedEndpoints = endpoints.advertisement.type.replace(
                '{typeId}',
                i + 1
            )
            const response = http().get(updatedEndpoints)

            response
                .then((data) => middleArray.push(data?.payload))
                .catch((err) => console.log(err))
        }
        set({ advertisement: middleArray })
    },
})
