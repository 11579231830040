import './thisMonthBirthday.scss'

import React from 'react'
import { PropTypes } from 'prop-types'

import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'

const ThisMonthBirthday = ({ payload }) => {
    const options = { month: 'short', day: 'numeric' }

    const { day, month } = useGetDateBasedOnCurrentLang(payload.dob, options)

    return (
        <div className="this-month-birthday-container">
            <p className="this-month-birthday-date">{`${month} ${day}`}</p>
            <p className="this-month-birthday-description">{payload.name}</p>
        </div>
    )
}

export default ThisMonthBirthday

ThisMonthBirthday.propTypes = {
    payload: PropTypes.object,
}
