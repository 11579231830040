import Spinner from 'components/Spinner/Spinner'
import { useFetchCategory } from 'hooks/category/useFetchCategory'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { routePaths } from 'global/constants/routePaths'
import { useParamsQuery } from 'utils/helpers/URLLocation'
import { useEffect, useState } from 'react'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import './categorySelector.scss'
import ReactSelect from 'components/ReactSelect'

const CategorySelector = ({ currentCategory, setCurrentCategory }) => {
    const [selectValues, setSelectValues] = useState()
    const { status: categoryStatus, data: categoryData } = useFetchCategory()
    const searchQuery = useParamsQuery()
    const location = useLocation()
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const isTabSelector = useMediaQuery('(max-width: 768px)')

    useEffect(() => {
        if (location.pathname === routePaths.news.base) {
            history.push(
                routePaths.news.moduleSubCategory
                    .replace('{moduleName}', 'all')
                    .replace('{subCategoryName}', 'all')
            )
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const newArr = []
        categoryData?.payload?.map((item) =>
            newArr.push({
                label:
                    i18n.language === lang.ENGLISH ? item.title : item.title_np,
                value: item.code,
            })
        )

        setSelectValues(newArr)

        // eslint-disable-next-line
    }, [categoryStatus, i18n.language])

    const handleCategoryChange = (value) => {
        setCurrentCategory(value.value)
        history.push(
            routePaths.news.moduleSubCategory.replace(
                '{moduleName}',
                value.value
            )
        )
    }

    return categoryStatus === 'loading' ? (
        <Spinner />
    ) : (
        <section className="category-selector-section">
            <div className="category-selector-container">
                {!isTabSelector &&
                    categoryData?.payload?.map((category, index) => (
                        <NavLink
                            to={routePaths.news.moduleSubCategory.replace(
                                '{moduleName}',
                                category.code
                            )}
                            activeClassName={
                                searchQuery.get('module') === category.code
                                    ? `is-active`
                                    : ''
                            }
                            key={index}
                            className="category-item"
                            onClick={(e) => setCurrentCategory(category.code)}
                        >
                            {i18n.language === lang.ENGLISH
                                ? category.title
                                : category.title_np}
                        </NavLink>
                    ))}
                {isTabSelector && (
                    <ReactSelect
                        selectDefaultValue={{
                            label: t('news.selection.all'),
                            value: 'all',
                        }}
                        options={selectValues}
                        height="100%"
                        width="100%"
                        boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                        color="#1D1D1F"
                        handleOnChange={(value) => handleCategoryChange(value)}
                        textColor="#a90000"
                        fontWeight="700"
                        singleValueFontSize="18px"
                    />
                )}
            </div>
        </section>
    )
}

export default CategorySelector
