import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchMarketSummary = () => {
    const updatedEndpoints = endpoints.shareMarket.marketSummary
    return http().get(updatedEndpoints)
}

export function useFetchMarketSummary() {
    return useQuery(['fetchMarketSummary'], () => fetchMarketSummary(), {
        retry: 3,
    })
}
