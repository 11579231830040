import React from 'react'
import './shareListingTable.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { marketSummaryData } from 'global/constants/dummyData'

const ShareListingTable = ({ date, title, payload }) => {
    // NOTE: Converts the object into a 2 dimensional array with 2 elements in a single child array.
    const objRows =
        typeof payload === 'object' &&
        !Array.isArray(payload) &&
        payload !== null &&
        Object.keys(payload).reduce(function (rows, key, index) {
            return (
                (index % 2 === 0
                    ? rows.push([key])
                    : rows[rows.length - 1].push(key)) && rows
            )
        }, [])

    const { t, i18n } = useTranslation()

    return (
        <div className="share-listing-table-container">
            {title && (
                <div className="share-listing-table-heading-container">
                    <h3>{t(title)}</h3>
                </div>
            )}
            <div className="share-listing-table-body-container">
                {objRows &&
                    objRows?.map((item, index) => (
                        <div
                            className="share-listing-table-data-row"
                            key={index}
                        >
                            {/* NOTE: Looping over the child array as well. */}
                            {item.map((key, index) => (
                                <div
                                    key={index}
                                    className="share-listing-table-data-row-item"
                                >
                                    <p className="share-listing-table-data-title">
                                        {t(marketSummaryData[key])}
                                    </p>
                                    {key === 'trade_date' ? (
                                        <p>{date}</p>
                                    ) : (
                                        <p>
                                            {i18n.language === lang.ENGLISH
                                                ? new Intl.NumberFormat(
                                                      'en-US'
                                                  ).format(payload[key])
                                                : EnglishToNepaliConverter(
                                                      new Intl.NumberFormat(
                                                          'en-US'
                                                      ).format(payload[key])
                                                  )}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default ShareListingTable

ShareListingTable.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    payload: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
