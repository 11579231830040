import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './carousel.scss'

import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import classNames from 'classnames'

const Carousel = (props) => {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <FiArrowLeft
            {...props}
            className={classNames('slick-prev', {
                out: props.out === 1,
                none: props.dots === 1,
            })}
        />
    )
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <FiArrowRight
            {...props}
            className={classNames('slick-next', {
                out: props.out === 1,
                none: props.dots === 1,
            })}
        />
    )
    const settings = {
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: props.itemsNumber,
        slidesToScroll: 1,
        prevArrow: (
            <SlickArrowLeft out={props.out ? 1 : 0} dots={props.dots ? 1 : 0} />
        ),
        nextArrow: (
            <SlickArrowRight
                out={props.out ? 1 : 0}
                dots={props.dots ? 1 : 0}
            />
        ),
        dots: props.dots,
        arrows: props.arrows !== undefined ? props.arrows : true,
    }

    return (
        <Slider
            {...settings}
            className={classNames({
                eighty: props.size === 80,
                arrowDark: props.arrowBgColor === 'dark',
            })}
        >
            {props.children}
        </Slider>
    )
}

export default Carousel
