import { useQuery } from 'react-query'
import { closePriceTypes } from 'global/constants/appConstants'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchTopTransactions = () => {
    const updatedEndpoints = endpoints.shareMarket.closePrice.byType.replace(
        '{type}',
        closePriceTypes.TOP_NUM_TRANSACTION
    )
    return http().get(updatedEndpoints)
}

export function useFetchTopTransactions(options) {
    return useQuery(['fetchTopTransactions'], () => fetchTopTransactions(), {
        retry: 3,
        ...options,
    })
}
