import './shareTable.scss'
import { PropTypes } from 'prop-types'
import ReactSelect from 'components/ReactSelect'
import { useTranslation } from 'react-i18next'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { lang } from 'global/constants/appConstants'
import classNames from 'classnames'
import { closePriceTypes } from 'global/constants/appConstants'

const ShareTable = ({
    title,
    selector,
    currentDate,
    subHeading,
    payload,
    type,
}) => {
    const { t, i18n } = useTranslation()

    const twoDecimals = (num) => {
        return parseFloat(num).toFixed(2)
    }

    return (
        <div className="share-table-container">
            <div className="share-table-heading-container">
                {title && <h3 className="share-table-title">{t(title)}</h3>}
                {selector && (
                    <div className="share-table-selector-container">
                        <ReactSelect
                            defaultValue={{
                                label: 'Top Turnovers',
                            }}
                            options={selector}
                            color="grey"
                            className="share-table-select"
                            height="32px"
                            width="165px"
                        />
                    </div>
                )}
                {currentDate && (
                    <p className="share-table-date">{currentDate}</p>
                )}
            </div>
            <div className="share-table-body-container">
                <table>
                    <thead>
                        <tr>
                            {Array.isArray(subHeading) &&
                                subHeading.length > 0 &&
                                subHeading.map((item, index) => (
                                    <th key={index}>{t(item)}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody
                        style={{
                            width: `${
                                subHeading.length * 100 + subHeading.length + 1
                            }%`,
                        }}
                    >
                        {Array.isArray(payload) &&
                            payload.length > 0 &&
                            payload.map((item, index) => (
                                <tr
                                    key={index}
                                    className='share-table-row'
                                >
                                    <td className="share-table-cell-company-symbol">
                                        {type !== closePriceTypes.TOP_SECTOR
                                            ? item?.company?.symbol
                                            : item?.sector_name}
                                    </td>
                                    {type ===
                                    closePriceTypes.TOP_SHARE_TRADED ? (
                                        <>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.total_traded_value
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.total_traded_value
                                                          )
                                                      )}
                                            </td>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.close_price
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.close_price
                                                          )
                                                      )}
                                            </td>
                                        </>
                                    ) : type ===
                                      closePriceTypes.TOP_NUM_TRANSACTION ? (
                                        <>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.total_trades
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.total_trades
                                                          )
                                                      )}
                                            </td>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.close_price
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.close_price
                                                          )
                                                      )}
                                            </td>
                                        </>
                                    ) : type ===
                                      closePriceTypes.TOP_TURNOVER ? (
                                        <>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.average_traded_price
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.average_traded_price
                                                          )
                                                      )}
                                            </td>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.close_price
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.close_price
                                                          )
                                                      )}
                                            </td>
                                        </>
                                    ) : type === closePriceTypes.TOP_SECTOR ? (
                                        <>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.turnover_value
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.turnover_value
                                                          )
                                                      )}
                                            </td>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.turnover_volume
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.turnover_volume
                                                          )
                                                      )}
                                            </td>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.total_transaction
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.total_transaction
                                                          )
                                                      )}
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.close_price
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.close_price
                                                          )
                                                      )}
                                            </td>
                                            <td
                                              className={classNames({
                                                  green:
                                                    type === closePriceTypes.TOP_GAIN,
                                                  red: type === closePriceTypes.TOP_LOSS,
                                              })}
                                            >
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.percentage_change
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.percentage_change
                                                          )
                                                      )}
                                            </td>
                                            <td>
                                                {i18n.language === lang.ENGLISH
                                                    ? twoDecimals(
                                                          item?.total_traded_quantity
                                                      )
                                                    : EnglishToNepaliConverter(
                                                          twoDecimals(
                                                              item?.total_traded_quantity
                                                          )
                                                      )}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ShareTable

ShareTable.propTypes = {
    title: PropTypes.string,
    heading: PropTypes.string,
    selector: PropTypes.array,
    tabNumber: PropTypes.number,
    currentDate: PropTypes.string,
    subHeading: PropTypes.array,
    payload: PropTypes.array,
}
