import { useFetchCategory } from 'hooks/category/useFetchCategory'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import Button from 'components/Layout/Button/Button'
import GridImage from './GridImage'
import Spinner from 'components/Spinner/Spinner'
import './interestedTopics.scss'

const InterestTopics = () => {
    const { status: interestedTopicsStatus, data: interestedTopicsData } =
        useFetchCategory()

    const history = useHistory()

    const handleButtonClick = (e) => {
        e.preventDefault()
        history.push('/')
    }

    const handleChange = (e, id) => {}

    const { t } = useTranslation()

    return interestedTopicsStatus === 'success' ? (
        <div className="interested-topics-container">
            <div className="topics-header">
                <h1 className="header">{t('interestedTopics.choose')}</h1>
                <span className="header-span">
                    {t('interestedTopics.shownFeed')}
                </span>
            </div>
            <div className="interested-topics-grid-image-container">
                {Array.isArray(interestedTopicsData?.payload) &&
                interestedTopicsData?.payload?.length > 0
                    ? interestedTopicsData?.payload?.map((item, index) => (
                          <GridImage
                              image={item?.image?.imageUrl}
                              title={item?.title}
                              key={index}
                              handleChange={(e) => handleChange(e, item?.id)}
                          />
                      ))
                    : null}
            </div>
            <Button
                description={t('buttons.submitButton')}
                handleButtonClick={handleButtonClick}
                bgColor="grey"
                size="large"
                center={true}
            />
        </div>
    ) : (
        <Spinner />
    )
}

export default InterestTopics
