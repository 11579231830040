import Title from 'components/Layout/Titles/Title'
import './dateConverter.scss'
import 'nepali-datepicker-reactjs/dist/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import TabsConverter from 'components/Layout/Tabs/TabsConverter'

const DateConverter = () => {
    // Docs for the Nepali date converter library: https://subeshb1.github.io/Nepali-Date/

    const { t } = useTranslation()

    return (
        <>
            <Title title={t('dateConverter.dateTitle')} bgColor="grey" />
            <div className="container">
                <TabsConverter />

                <div className="date-data-container">
                    {t('dateConverter.dateData')}
                </div>
            </div>
        </>
    )
}

export default DateConverter
