import Advertisement from 'components/Layout/Advertisement/Advertisement'
import React from 'react'
import './rightNewsSinglePage.scss'
import { globalAdvertisement2Img } from 'assets'
import RightNewsBlocks from '../../NewsBlocks/RightNewsBlock/RightNewsBlocks'
import { ResizedAd576Img } from 'assets'

const RightNewsSinglePage = () => {
    const payload = [
        {
            bgImg: globalAdvertisement2Img,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const payload1 = [
        {
            bgImg: ResizedAd576Img,
            urlLink: 'https://daraz.com.np',
        },
    ]
    return (
        <div className="news-single-page-right-side-container">
            <Advertisement type="large-rectangles" payload={payload} />
            <RightNewsBlocks containerClass="news-single-page-right-side-most-read" />
            <Advertisement type="large-rectangles" payload={payload1} />
        </div>
    )
}

export default RightNewsSinglePage
