import { useState } from 'react'
import LabelInput from '../../../../components/Layout/LabelInput/LabelInput'
import './temperatureConverter.scss'
import { useTranslation } from 'react-i18next'

const TemperatureConverter = () => {
    const { t } = useTranslation()
    const [farenhite, setFarenhite] = useState()
    const [celsius, setCelsius] = useState()
    const [rankine, setRankine] = useState()
    const [kelvin, setKelvin] = useState()

    function roundToThree(num) {
        return +(Math.round(num + 'e+3') + 'e-3')
    }

    const ConvertFarenhite = (e) => {
        setFarenhite(e.target.value)
        try {
            setCelsius(roundToThree(((parseInt(e.target.value) - 32) * 5) / 9))
            setKelvin(
                roundToThree((parseInt(e.target.value) - 32) / 1.8 + 273.15)
            )
            setRankine(roundToThree(parseInt(e.target.value) + 459.67))
        } catch (err) {
            console.log(err)
        }
    }

    const convertCelsius = (e) => {
        setCelsius(e.target.value)
        try {
            setFarenhite(roundToThree(parseInt(e.target.value) * 1.8 + 32))
            setKelvin(roundToThree(parseInt(e.target.value) + 273.15))
            setRankine(roundToThree(parseInt(e.target.value) + 273.15 * 1.8))
        } catch (err) {
            console.log(err)
        }
    }

    const convertKelvin = (e) => {
        setKelvin(e.target.value)
        try {
            setFarenhite(roundToThree(parseInt(e.target.value) * 1.8 - 459.67))
            setCelsius(roundToThree(parseInt(e.target.value) - 273.15))
            setRankine(roundToThree(parseInt(e.target.value) * 1.8))
        } catch (err) {
            console.log(err)
        }
    }

    const convertRankine = (e) => {
        setRankine(e.target.value)
        try {
            setFarenhite(roundToThree(parseInt(e.target.value) - 459.67))
            setCelsius(roundToThree((parseInt(e.target.value) - 491.67) / 1.8))
            setKelvin(roundToThree(parseInt(e.target.value) / 1.8))
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="temperature-converter-container">
            <form className="temperature-converter-form">
                <LabelInput
                    labelHtmlFor="farenhite"
                    labelName={t('calculator.fahrenheit')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={farenhite}
                    size="small"
                    handleOnChange={(e) => ConvertFarenhite(e)}
                    orientation="vertical"
                />
                <LabelInput
                    labelHtmlFor="celsius"
                    labelName={t('calculator.celsius')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={celsius}
                    size="small"
                    handleOnChange={(e) => convertCelsius(e)}
                    orientation="vertical"
                />
                <LabelInput
                    labelHtmlFor="rankine"
                    labelName={t('calculator.rankine')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={rankine}
                    size="small"
                    handleOnChange={(e) => convertRankine(e)}
                    orientation="vertical"
                />
                <LabelInput
                    labelHtmlFor="kelvin"
                    labelName={t('calculator.kelvin')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={kelvin}
                    size="small"
                    handleOnChange={(e) => convertKelvin(e)}
                    orientation="vertical"
                />
            </form>
            <div className="temperature-converter-caveat-text">
                {t('calculator.temperatureCanveatText')}
            </div>
        </div>
    )
}
export default TemperatureConverter
