import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchCategory = () => {
    const updatedEndpoints = endpoints.category.base
    return http().get(updatedEndpoints)
}

export function useFetchCategory() {
    return useQuery(['fetchCategory'], () => fetchCategory())
}
