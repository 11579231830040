import React from 'react'
import Button from 'components/Layout/Button/Button'
import './errorBoundary.scss'
import { errorBoundaryImg } from 'assets'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }

        this.refreshPage = this.refreshPage.bind(this)

        this.goBack = this.goBack.bind(this)
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV === 'development') {
            console.log({
                error: error,
                errorInfor: info,
            })
        }
    }

    refreshPage() {
        window.location.reload()
    }

    goBack() {
        window.history.back()
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary-container">
                    <figure className="error-boundary-figure">
                        <img src={errorBoundaryImg} alt="" />
                    </figure>
                    <h1 className="error-boundary-heading">
                        Oops! Something went wrong!
                    </h1>
                    <p className="error-boundary-text">
                        Looks like there is some problem that we are facing.
                        Please check in later!
                    </p>
                    <div className="error-boundary-button-container">
                        <Button
                            description="Refresh Page"
                            handleButtonClick={this.refreshPage}
                            bgColor="grey"
                            size="medium"
                            disabled={false}
                        />
                        <Button
                            description="Go back"
                            handleButtonClick={this.goBack}
                            bgColor="grey"
                            size="medium"
                            disabled={false}
                        />
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
