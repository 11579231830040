/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react'
import { useFetchGoldSilverGraphList } from 'hooks/goldSilver/useFetchGoldSilverGraphList'
import { useEffect } from 'react'
import { Chart } from 'react-charts'
import './goldChart.scss'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/Spinner/Spinner'
import ResizableBox from '../ResizableBox'

const GoldChart = () => {
    const [goldSilverData, setGoldSilverData] = useState({
        hallmark: [
            {
                day: 1,
                price: 1,
            },
            {
                day: 2,
                price: 2,
            },
            {
                day: 3,
                price: 3,
            },
        ],
        tajabi: [
            {
                day: 1,
                price: 1,
            },
            {
                day: 2,
                price: 2,
            },
            {
                day: 3,
                price: 3,
            },
        ],
        silver: [
            {
                day: 1,
                price: 1,
            },
            {
                day: 2,
                price: 2,
            },
            {
                day: 3,
                price: 3,
            },
        ],
    })

    const { status: goldChartStatus, data: goldChartData } =
        useFetchGoldSilverGraphList()

    useEffect(() => {
        if (goldChartStatus === 'success') {
            const newArrHallmark = []
            goldChartData?.payload?.day.map((item, index) =>
                newArrHallmark.push({
                    day: item,
                    price: goldChartData?.payload?.gold[index],
                })
            )

            const newArrSilver = []
            goldChartData?.payload?.day.map((item, index) =>
                newArrSilver.push({
                    day: item,
                    price: goldChartData?.payload?.silver[index],
                })
            )

            const newArrTajabi = []
            goldChartData?.payload?.day.map((item, index) =>
                newArrTajabi.push({
                    day: item,
                    price: goldChartData?.payload?.tajabi[index],
                })
            )

            setGoldSilverData({
                silver: newArrSilver,
                hallmark: newArrHallmark,
                tajabi: newArrTajabi,
            })
        }

        // eslint-disable-next-line
    }, [goldChartStatus])

    const data = useMemo(
        () => [
            {
                label: 'Hallmark',
                data: goldSilverData?.hallmark,
            },
            {
                label: 'Tajabi',
                data: goldSilverData?.tajabi,
            },
            {
                label: 'Silver',
                data: goldSilverData?.silver,
            },
        ],

        [goldSilverData]
    )

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => datum.day,
        }),
        []
    )

    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.price,
                // elementType: 'line',
            },
        ],
        []
    )

    const { t } = useTranslation()

    return (
        <div className="chart-data-container">
            {goldChartStatus === 'success' ? (
                <div className="chart-figure">
                    <ResizableBox>
                        <Chart
                            options={{
                                data,
                                primaryAxis,
                                secondaryAxes,
                            }}
                        />
                    </ResizableBox>
                    <div className="chart-data-details">
                        <div className="hallmark-gold-circle"></div>
                        <div className="hallmark-gold">
                            {t('goldSilver.hallmark')}
                        </div>
                        <div className="Tajabi-gold-circle"></div>
                        <div className="Tajabi-gold">
                            {t('goldSilver.tajabi')}
                        </div>
                        <div className="silver-circle"></div>
                        <div className="silver">{t('goldSilver.silver')}</div>
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default GoldChart
