import './advertisement.scss'
import LeaderboardAdvertisement from './LeaderboardAdvertisement/LeaderboardAdvertisement'
import LargeRectangleAdvertisement from './LargeRectangleAdvertisement/LargeRectangleAdvertisement'
import FullPageAdvertisement from './FullPageAdvertisement/FullPageAdvertisement'
import PlaceholderAdvertisement from './PlaceholderAdvertisement/PlaceholderAdvertisement'

const Advertisement = ({ type, payload }) => {
    // A note to future developers: please send the payload, whatever the hell it is: images, gifs, videos, etc. in an array format. Thanks.

    // REMINDER: Add in a URL link so that when the user clicks on it, the user is redirected to the advetisement's site.

    type = type.toLowerCase()

    switch (type) {
        case 'leaderboard':
            return <LeaderboardAdvertisement payload={payload} />
        case 'large-rectangles':
            return <LargeRectangleAdvertisement payload={payload} />
        case 'full-page':
            return <FullPageAdvertisement payload={payload} />
        case 'placeholder':
            return <PlaceholderAdvertisement payload={payload} />
        default:
            return
    }
}

export default Advertisement
