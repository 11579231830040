/* eslint-disable no-unused-vars */
import { useState } from 'react'

import './landCalculator.scss'

import LabelInput from 'components/Layout/LabelInput/LabelInput'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const LandCalculator = () => {
    const { t, i18n } = useTranslation()
    // NOTE: This could probably be further optizimied by setting the squre feet and square meter values in a state and updating the state rather than having 6 extra variables.
    function roundToThree(num) {
        return +(Math.round(num + 'e+3') + 'e-3')
    }

    const caveatArr = [
        'calculator.landCalculator.caveat.bigha',
        'calculator.landCalculator.caveat.katha',
        'calculator.landCalculator.caveat.dhur',
        'calculator.landCalculator.caveat.ropani',
        'calculator.landCalculator.caveat.aana',
        'calculator.landCalculator.caveat.paisa',
        'calculator.landCalculator.caveat.daam',
    ]

    const newObj = {}

    useEffect(() => {
        Object.keys(currentUnitValues).map(
            (key) =>
                (newObj[key] = currentUnitValues[key].map((item) => {
                    const updatedItem = {
                        ...item,
                        labelName: t(`calculator.landCalculator.${item.id}`),
                    }
                    return updatedItem
                }))
        )

        setCurrentUnitValues(newObj)

        // eslint-disable-next-line
    }, [i18n.language])

    const [currentUnitValues, setCurrentUnitValues] = useState({
        hillyMountainousMeasure: [
            {
                value: 0,
                labelName: t('calculator.landCalculator.ropani'),
                id: 'ropani',
            },
            {
                value: 0,
                labelName: t('calculator.landCalculator.anna'),
                id: 'anna',
            },
            {
                value: 0,
                labelName: t('calculator.landCalculator.paisa'),
                id: 'paisa',
            },
            {
                value: 0,
                labelName: t('calculator.landCalculator.daam'),
                id: 'daam',
            },
        ],
        teraiMeasure: [
            {
                value: 0,
                labelName: t('calculator.landCalculator.bigha'),
                id: 'bigha',
            },
            {
                value: 0,
                labelName: t('calculator.landCalculator.kattha'),
                id: 'kattha',
            },
            {
                value: 0,
                labelName: t('calculator.landCalculator.dhur'),
                id: 'dhur',
            },
        ],

        otherMeasures: [
            {
                value: 0,
                labelName: t('calculator.landCalculator.sqFeet'),
                id: 'sqFeet',
            },
            {
                value: 0,
                labelName: t('calculator.landCalculator.sqMeter'),
                id: 'sqMeter',
            },
        ],
    })

    const sqFeetToTeraiValues = (sqFeet) => {
        let bighaValue = 0
        let kathaValue = 0
        let dhurValue = 0

        bighaValue = Math.trunc(sqFeet / 72900)
        kathaValue = Math.trunc((sqFeet - bighaValue * 72900) / 3645)
        dhurValue = (sqFeet - kathaValue * 3645 - bighaValue * 72900) / 182.25

        return [bighaValue, kathaValue, dhurValue]
    }

    const sqFeetToHillyValues = (sqFeet) => {
        let ropaniValue = 0
        let annaValue = 0
        let paisaValue = 0
        let daamValue = 0

        ropaniValue = Math.trunc(sqFeet / 5476)
        annaValue = Math.trunc((sqFeet - ropaniValue * 5476) / 342.25)
        paisaValue = Math.trunc(
            (sqFeet - ropaniValue * 5476 - annaValue * 342.25) / 85.56
        )
        daamValue =
            (sqFeet -
                ropaniValue * 5476 -
                annaValue * 342.25 -
                paisaValue * 85.56) /
            21.39

        return [ropaniValue, annaValue, paisaValue, daamValue]
    }

    const storeHilly = (result) => {
        newObj.hillyMountainousMeasure[0].value = roundToThree(result[0])

        newObj.hillyMountainousMeasure[1].value = roundToThree(result[1])

        newObj.hillyMountainousMeasure[2].value = roundToThree(result[2])

        newObj.hillyMountainousMeasure[3].value = roundToThree(result[3])
    }

    const storeTerai = (result) => {
        newObj.teraiMeasure[0].value = roundToThree(result[0])

        newObj.teraiMeasure[1].value = roundToThree(result[1])
        newObj.teraiMeasure[2].value = roundToThree(result[2])
    }

    const storeSqFeet = (result) => {
        newObj.otherMeasures[0].value = roundToThree(result)
    }

    const storeSqMeter = (result) => {
        newObj.otherMeasures[1].value = roundToThree(result)
    }

    const getSqFeetValueHilly = () => {
        return (
            (isNaN(newObj.hillyMountainousMeasure[0]?.value * 5476)
                ? 0
                : newObj.hillyMountainousMeasure[0]?.value * 5476) +
            (isNaN(newObj.hillyMountainousMeasure[1]?.value * 342.25)
                ? 0
                : newObj.hillyMountainousMeasure[1]?.value * 342.25) +
            (isNaN(newObj.hillyMountainousMeasure[2]?.value * 85.56)
                ? 0
                : newObj.hillyMountainousMeasure[2]?.value * 85.56) +
            (isNaN(newObj.hillyMountainousMeasure[3]?.value * 21.39)
                ? 0
                : newObj.hillyMountainousMeasure[3]?.value * 21.39)
        )
    }

    const getSqFeetValueTerai = () => {
        return (
            (isNaN(newObj.teraiMeasure[0]?.value * 72900)
                ? 0
                : newObj.teraiMeasure[0]?.value * 72900) +
            (isNaN(newObj.teraiMeasure[1]?.value * 3645)
                ? 0
                : newObj.teraiMeasure[1]?.value * 3645) +
            (isNaN(newObj.teraiMeasure[2]?.value * 182.25)
                ? 0
                : newObj.teraiMeasure[2]?.value * 182.25)
        )
    }

    const handleInputTextChange = (e, id) => {
        try {
            Object.keys(currentUnitValues).map((key, position) => {
                newObj[key] = currentUnitValues[key].map((item, placeValue) => {
                    if (item?.id === id) {
                        const updatedItem = {
                            ...item,
                            value: parseFloat(e.target.value),
                        }
                        return updatedItem
                    } else {
                        return item
                    }
                })
                return null
            })

            switch (id) {
                case 'ropani':
                case 'anna':
                case 'paisa':
                case 'daam':
                    // fall-through. Refer to this docs: https://stackoverflow.com/questions/6513585/test-for-multiple-cases-in-a-switch-like-an-or
                    let totalSqFeetValue = getSqFeetValueHilly()
                    let totalSqMeterValue = totalSqFeetValue / 10.764
                    let resultTerai = sqFeetToTeraiValues(totalSqFeetValue)

                    storeSqFeet(totalSqFeetValue)
                    storeSqMeter(totalSqMeterValue)
                    storeTerai(resultTerai)

                    break

                case 'bigha':
                case 'kattha':
                case 'dhur':
                    let totalSqFeetValueTerai = getSqFeetValueTerai()
                    let totalSqMeterValueTerai = totalSqFeetValueTerai / 10.764
                    let resultHilly = sqFeetToHillyValues(totalSqFeetValueTerai)

                    storeSqMeter(totalSqMeterValueTerai)
                    storeSqFeet(totalSqFeetValueTerai)
                    storeHilly(resultHilly)

                    break

                case 'sqFeet':
                    let totalSqFeet = newObj.otherMeasures[0].value
                    let totalSqMeter = totalSqFeet / 10.764
                    let resultTeraiValue = sqFeetToTeraiValues(totalSqFeet)
                    let resultHillyValue = sqFeetToHillyValues(totalSqFeet)

                    storeSqMeter(totalSqMeter)
                    storeHilly(resultHillyValue)
                    storeTerai(resultTeraiValue)

                    break

                case 'sqMeter':
                    let finalSqMeter = newObj.otherMeasures[1].value
                    let finalSqFeet = finalSqMeter * 10.764
                    let finalTeraiValue = sqFeetToTeraiValues(finalSqFeet)
                    let finalHillyValue = sqFeetToHillyValues(finalSqFeet)

                    storeSqFeet(finalSqFeet)
                    storeHilly(finalHillyValue)
                    storeTerai(finalTeraiValue)

                    break

                default:
                // do nothing
            }
            setCurrentUnitValues(newObj)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="land-calculator-container">
            <form action="" className="land-calculator-form">
                {Object.keys(currentUnitValues).map(function (key, index) {
                    return (
                        <div className="land-calculator-form-block" key={index}>
                            <h2 className="land-calculator-form-block-title">
                                {t(`calculator.landCalculator.${key}`)}
                            </h2>
                            <div className="land-calculator-form-block-content-container">
                                {currentUnitValues[key].map((value, index) => (
                                    <LabelInput
                                        orientation="vertical"
                                        labelName={value?.labelName}
                                        labelHtmlFor={value?.id}
                                        inputType="number"
                                        inputName={value?.id}
                                        inputValue={value?.value}
                                        size="small"
                                        handleOnChange={(e) =>
                                            handleInputTextChange(e, value?.id)
                                        }
                                        inputPlaceholder={0}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </div>
                    )
                })}
            </form>
            <p className="land-calculator-caveat">
                *{t('calculator.landCalculatorDesc')}
            </p>
            <div className="land-calculator-partial-list-container">
                <h3>{t('calculator.landCalculator.partialListHeading')}</h3>
                <ul>
                    {caveatArr.map((item, index) => (
                        <li key={index}>{t(item)}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default LandCalculator
