import './rightNewsBlocks.scss'
import MostRead from 'components/Layout/News/MostRead/MostRead'
import { useFetchNewsPaginate } from 'hooks/news/useFetchNewsPaginate'
import Spinner from 'components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useFetchPopularNews } from 'hooks/news/useFetchPopularNews'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import ReactSelect from 'components/ReactSelect'

const RightNewsBlocks = ({ currentNewsTopic, containerClass = '' }) => {
    // This API call is for the most read newsn
    const { status: statusPopularNews, data: popularNewsData } =
        useFetchPopularNews(10)
    // This API call is for the recently updated news
    const { status: statusNewsPaginate, data: newsDataPaginate } =
        useFetchNewsPaginate(10)

    const { t } = useTranslation()

    const [newsToShow, setNewsToShow] = useState()
    const [activeTab, setActiveTab] = useState('recentlyUpdated')

    const tabHeading = [
        {
            label: t('recentlyUpdated'),
            value: 'recentlyUpdated',
        },
        {
            label: t('mostRead'),
            value: 'mostRead',
        },
    ]

    useEffect(() => {
        activeTab === 'mostRead'
            ? setNewsToShow(popularNewsData?.payload)
            : setNewsToShow(newsDataPaginate?.payload)

        // eslint-disable-next-line
    }, [activeTab, statusPopularNews, statusNewsPaginate])

    const isTabSelector = useMediaQuery('(max-width: 576px)')

    return (
        <section className={`right-news-block-container ${containerClass}`}>
            <div className="right-news-block-most-read-container">
                <div className="right-news-block-tab-heading-container">
                    {!isTabSelector &&
                        tabHeading.map((item, index) => (
                            <div
                                className={classNames('tab-heading', {
                                    activeTab: activeTab === item.value,
                                })}
                                onClick={() => setActiveTab(item.value)}
                                key={index}
                            >
                                <h3
                                    className={classNames(
                                        'right-news-block-most-read-heading',
                                        {
                                            activeTab: activeTab === item.value,
                                        }
                                    )}
                                >
                                    {item.label}
                                </h3>
                            </div>
                        ))}
                    {isTabSelector && (
                        <ReactSelect
                            options={tabHeading}
                            selectDefaultValue={tabHeading[0]}
                            height="100%"
                            width="100%"
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            color="#1D1D1F"
                            handleOnChange={(value) =>
                                setActiveTab(value.value)
                            }
                            textColor="#a90000"
                            fontWeight="700"
                            singleValueFontSize="18px"
                        />
                    )}
                </div>
                <div className="right-news-block-most-read-items-container">
                    {statusNewsPaginate === 'success' ||
                    statusPopularNews === 'success' ? (
                        newsToShow && Array.isArray(newsToShow) ? (
                            newsToShow.map((news, index) => (
                                <MostRead
                                    key={index}
                                    title={news.title}
                                    newsAgency={news.channel_name}
                                    newsAgencyIcon={news.channel_icon}
                                    rank={index + 1}
                                    id={news.id}
                                    newsAgencyLink={news.channel_link}
                                />
                            ))
                        ) : null
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        </section>
    )
}

export default RightNewsBlocks

RightNewsBlocks.propTypes = {
    currentNewsTopic: PropTypes.string,
}
