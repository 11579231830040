import http from 'utils/http'
import { endpoints } from 'global/endpoints'
import { useMutation } from 'react-query'

const postShareCalculator = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.calculator.share.base
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostShareCalculator() {
    return useMutation(postShareCalculator)
}
