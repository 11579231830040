/* eslint-disable no-unused-vars */
import ReactSelect from 'components/ReactSelect'
import { lang } from 'global/constants/appConstants'
import NumberConverter from 'hooks/numberConverter/NumberConverter'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LabelInput from 'components/Layout/LabelInput/LabelInput'
import './weightCalculator.scss'

const WeightCalculator = () => {
    const { t } = useTranslation()
    function roundToThree(num) {
        return +(Math.round(num + 'e+3') + 'e-3')
    }

    const [kilogram, setKilogram] = useState()
    const [pound, setPound] = useState()
    const [gram, setGram] = useState()
    const [ton, setTon] = useState()

    const convertKilogram = (e) => {
        setKilogram(e.target.value)
        try {
            setPound(roundToThree(parseFloat(e.target.value * 2.20462)))
            setGram(roundToThree(parseFloat(e.target.value * 1000)))
            setTon(roundToThree(parseFloat(e.target.value * 0.00110231)))
        } catch (err) {
            console.log(err)
        }
    }
    const convertPound = (e) => {
        setPound(e.target.value)
        try {
            setKilogram(roundToThree(parseFloat(e.target.value * 0.453592)))
            setGram(roundToThree(parseFloat(e.target.value * 453.592)))
            setTon(roundToThree(parseFloat(e.target.value * 0.0005)))
        } catch (err) {
            console.log(err)
        }
    }
    const convertGram = (e) => {
        setGram(e.target.value)
        try {
            setKilogram(roundToThree(parseFloat(e.target.value * 0.001)))
            setPound(roundToThree(parseFloat(e.target.value * 0.00220462)))
            setTon(roundToThree(parseFloat(e.target.value * 0.000001)))
        } catch (err) {
            console.log(err)
        }
    }
    const convertTon = (e) => {
        setTon(e.target.value)
        try {
            setKilogram(roundToThree(parseFloat(e.target.value * 907.185)))
            setPound(roundToThree(parseFloat(e.target.value * 2000)))
            setGram(roundToThree(parseFloat(e.target.value * 907185)))
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="weight-converter-container">
            <div className="weight-converter-form">
                <LabelInput
                    labelHtmlFor="kilogram"
                    labelName={t('calculator.kilogram')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={kilogram}
                    size="small"
                    handleOnChange={(e) => convertKilogram(e)}
                    orientation="vertical"
                />
                <LabelInput
                    labelHtmlFor="pound"
                    labelName={t('calculator.pound')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={pound}
                    size="small"
                    handleOnChange={(e) => convertPound(e)}
                    orientation="vertical"
                />
                <LabelInput
                    labelHtmlFor="gram"
                    labelName={t('calculator.grams')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={gram}
                    size="small"
                    handleOnChange={(e) => convertGram(e)}
                    orientation="vertical"
                />
                <LabelInput
                    labelHtmlFor="ton"
                    labelName={t('calculator.tons')}
                    inputType="number"
                    inputPlaceholder="0"
                    inputValue={ton}
                    size="small"
                    handleOnChange={(e) => convertTon(e)}
                    orientation="vertical"
                />
            </div>
            <div className="weight-converter-caveat-text">
                {t('calculator.weightCanveatText')}
            </div>
        </div>
    )
}
export default WeightCalculator
