import './thisMonthEvents.scss'

import React from 'react'
import { PropTypes } from 'prop-types'

const ThisMonthEvents = ({ payload }) => {
    return (
        <div className="this-month-events-container">
            <p className="this-month-events-date">{payload.display_date}</p>
            <p className="this-month-events-description">{payload.event}</p>
        </div>
    )
}

export default ThisMonthEvents

ThisMonthEvents.propTypes = {
    payload: PropTypes.object,
}
