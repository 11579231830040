import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchForex = (perPage = 20, defaultDate) => {
    return http('json', 'https://www.nrb.org.np/api/forex/', 20000, false).get(
        endpoints.forex.base
            .replace('{perPage}', perPage)
            .replace('{fromDate}', defaultDate)
            .replace('{toDate}', defaultDate)
    )
}

export function useFetchForex(perPage) {
    const offset = new Date().getTimezoneOffset()
    const defaultDate = new Date(new Date().getTime() - offset * 60 * 1000)
        .toISOString()
        .split('T')[0]

    return useQuery(
        ['fetchForex', perPage, defaultDate],
        () => fetchForex(perPage, defaultDate),
        { retry: 3 }
    )
}
