import React, { useRef } from 'react'
import Modal from 'components/Modal/Modal'
import { PropTypes } from 'prop-types'
import { AiOutlineClose } from 'react-icons/ai'
import './fullPageAdvertisement.scss'

const FullPageAdvertisement = ({ payload }) => {
    const innerRef = useRef()

    const handleCloseButtonClick = () => {
        innerRef.current.close()
    }

    return (
        <Modal ref={innerRef} defaultOpened={true}>
            <div className="advertisement-full-page-container">
                <AiOutlineClose
                    onClick={handleCloseButtonClick}
                    className="close-button"
                />
                <section className="advertisement-full-page-section">
                    {payload?.map((item, index) => (
                        <div
                            className="advertisement-full-page-item"
                            key={index}
                        >
                            {item?.urlLink ? (
                                <a
                                    href={item.urlLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <figure className="advertisement-full-page-figure">
                                        <img
                                            src={item?.bgImg}
                                            alt=""
                                            className="advertisement-full-page-img"
                                        />
                                    </figure>
                                </a>
                            ) : (
                                <figure className="advertisement-full-page-figure">
                                    <img
                                        src={item?.bgImg}
                                        alt=""
                                        className="advertisement-full-page-img"
                                    />
                                </figure>
                            )}
                        </div>
                    ))}
                </section>
            </div>
        </Modal>
    )
}

export default FullPageAdvertisement

FullPageAdvertisement.propTypes = {
    payload: PropTypes.array,
}
