import { useTranslation } from 'react-i18next'
import { useFetchGoldSilverList } from 'hooks/goldSilver/useFetchGoldSilverList'
import { useState, useEffect } from 'react'
import { lang } from 'global/constants/appConstants'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { goldImg, silverImg } from 'assets'
import { GoldChart } from 'components/Charts'

import './gold.scss'

import Title from 'components/Layout/Titles/Title'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'

const Gold = () => {
    const { status: goldSilverListStatus, data: goldSilverListData } =
        useFetchGoldSilverList()

    const { t, i18n } = useTranslation()

    const [updatedDate, setUpdatedDate] = useState(new Date())

    const options = {
        day: 'numeric',
        weekday: 'long',
        month: 'long',
        year: 'numeric',
    }

    useEffect(() => {
        setUpdatedDate(goldSilverListData?.payload[0].date)

        // eslint-disable-next-line
    }, [goldSilverListStatus])

    const { day, month, year, time, weekday } = useGetDateBasedOnCurrentLang(
        updatedDate,
        options
    )

    const currentLang = i18n.language

    return (
        <>
            <Title
                title={t('navbar.goldSilver')}
                desc={`*${t(
                    'lastUpdated'
                )}: ${weekday}, ${month} ${day}, ${year} - ${time}`}
                bgColor="grey"
            />
            <div className="container">
                <div className="gold-silver-data-container">
                    <div className="gold-silver-data-todays-rate">
                        <h3 className="gold-silver-data-sub-title">
                            {`${t('todaysRates')} (${t('goldSilver.perTola')})`}
                        </h3>
                        <div className="gold-silver-data-list-container">
                            {Array.isArray(goldSilverListData?.payload) &&
                            goldSilverListData?.payload.length > 0
                                ? goldSilverListData?.payload.map(
                                      (item, index) => (
                                          <div
                                              className="gold-silver-data-list-item"
                                              key={index}
                                          >
                                              <figure className="gold-silver-data-list-item-figure">
                                                  {item?.gold_silver_name
                                                      .split(' ')[0]
                                                      .toLowerCase() === 'gold'
                                                      ? goldImg && (
                                                            <img
                                                                src={goldImg}
                                                                alt=""
                                                            />
                                                        )
                                                      : silverImg && (
                                                            <img
                                                                src={silverImg}
                                                                alt=""
                                                            />
                                                        )}
                                              </figure>
                                              <div className="gold-silver-data-list-item-text-container">
                                                  <p className="gold-silver-data-list-item-name">
                                                      {item?.gold_silver_name.toLowerCase() ===
                                                      'silver'
                                                          ? t(
                                                                'goldSilver.silver'
                                                            )
                                                          : item?.gold_silver_name.toLowerCase() ===
                                                            'gold hallmark'
                                                          ? `${t(
                                                                'goldSilver.hallmark'
                                                            )} ${t(
                                                                'goldSilver.gold'
                                                            )}`
                                                          : `${t(
                                                                'goldSilver.tajabi'
                                                            )} ${t(
                                                                'goldSilver.gold'
                                                            )}`}
                                                  </p>
                                                  <p className="gold-silver-data-list-item-value">
                                                      {currentLang ===
                                                      lang.ENGLISH
                                                          ? `${t(
                                                                'ruppees'
                                                            )}. ${Number(
                                                                item?.rate
                                                            ).toLocaleString(
                                                                'en-US'
                                                            )}`
                                                          : `${t(
                                                                'ruppees'
                                                            )}. ${EnglishToNepaliConverter(
                                                                Number(
                                                                    item?.rate
                                                                ).toLocaleString(
                                                                    'en-US'
                                                                )
                                                            )}`}
                                                  </p>
                                              </div>
                                          </div>
                                      )
                                  )
                                : null}
                        </div>
                    </div>
                    <div className="gold-silver-data-vertical-line"></div>
                    <div className="gold-silver-data-graph-container">
                        <h3 className="gold-silver-data-sub-title">
                            {t('goldSilver.goldSilverTrends')}
                        </h3>
                        <GoldChart />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gold
