import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchEpaper = (currentLang) => {
    const updatedEndpoints = endpoints.ePaper.base.replace(
        '{language}',
        currentLang
    )
    return http().get(updatedEndpoints)
}

export function useFetchEPaper(currentLang) {
    return useQuery(
        ['fetchEpaper', currentLang],
        () => fetchEpaper(currentLang),
        {
            retry: 3,
        }
    )
}
