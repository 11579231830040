import './newsVideosCarousel.scss'
import VideoPlayButton from 'components/VideoPlayButton/VideoPlayButton'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const NewsVideosCarousel = ({ payload }) => {
    return (
        <div className="news-video-carousel" title={payload?.title}>
            <a href={payload?.url} target="_blank" rel="noreferrer">
                <figure className="news-video-carousel-image-container">
                    <img
                        src={replaceHttpWithHttps(payload?.imageUrl)}
                        alt=""
                        className="news-video-carousel-image"
                    />
                    <VideoPlayButton size="large" />
                </figure>
            </a>
            <div className="news-video-carousel-bottom-container">
                <a
                    href={payload?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="news-hightlight-video-link"
                >
                    <h3 className="news-video-carousel-title">
                        {payload?.title}
                    </h3>
                </a>
                <div className="news-video-carousel-news-agency-container">
                    <img
                        src={replaceHttpWithHttps(payload?.channel_icon)}
                        alt=""
                        className="news-video-carousel-news-agency-icon"
                    />
                    <p className="news-video-carousel-news-agency-name">
                        {payload?.channel}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NewsVideosCarousel
