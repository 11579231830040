import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchGoldSilverGraphList = () => {
    const updatedEndpoints = endpoints.goldSilver.graph
    return http().get(updatedEndpoints)
}

export function useFetchGoldSilverGraphList() {
    return useQuery(['fetchGoldSilverGraphList'], () =>
        fetchGoldSilverGraphList()
    )
}
