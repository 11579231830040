/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react'
import { Chart } from 'react-charts'
import { indexType, shareGraphDuration } from 'global/constants/appConstants'
import { useEffect } from 'react'
import { useFetchShareIndexGraph } from 'hooks/shareMarket'

import ResizableBox from '../ResizableBox'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner/Spinner'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'

import './shareMarketChart.scss'

const ShareMarketChart = ({ type = '', duration = '', size = 'sm' }) => {
    const [currentLabel, setCurrentLabel] = useState('NEPSE')
    const [chartDimensions, setChartDimensions] = useState({
        width: 455,
        height: size === 'sm' ? 270 : 450,
    })
    const [graphData, setGraphData] = useState([
        {
            date: '',
            price: 0,
        },
    ])

    const { status: shareIndexGraphDataStatus, data: shareIndexGraphData } =
        useFetchShareIndexGraph(type, duration)

    useEffect(() => {
        switch (type) {
            case indexType.NEPSE:
                setCurrentLabel('NEPSE')
                break
            case indexType.SENSITIVE:
                setCurrentLabel('SENSITIVE')
                break
            case indexType.FLOAT:
                setCurrentLabel('FLOAT')
                break
            case indexType.SENSITIVE_FLOAT:
                setCurrentLabel('SENSITIVE_FLOAT')
                break
            default:
                setCurrentLabel('NEPSE')
        }
    }, [type])

    useEffect(() => {
        if (shareIndexGraphDataStatus === 'success') {
            setGraphData([...shareIndexGraphData?.payload[type]])
        }

        // eslint-disable-next-line
    }, [shareIndexGraphDataStatus, shareIndexGraphData])

    const data = useMemo(
        () => [
            {
                label: currentLabel,
                data:
                    graphData.length > 0
                        ? graphData
                        : [
                              {
                                  date: '',
                                  price: 0,
                              },
                          ],
            },
        ],
        // eslint-disable-next-line
        [graphData]
    )

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => datum?.date,
        }),
        []
    )

    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum?.price,
                elementType: 'area',
            },
        ],
        []
    )

    const isMobile = useMediaQuery('(max-width: 576px)')
    const isSmallLaptop = useMediaQuery('(max-width: 1200px)')

    useEffect(() => {
        if (isSmallLaptop) {
            setChartDimensions((prevState) => ({
                ...prevState,
                width: 350,
            }))
        }
        if (isMobile) {
            setChartDimensions((prevState) => ({
                ...prevState,
                width: 350,
            }))
        }
    }, [isMobile, isSmallLaptop])

    return (
        <div className="chart-data-container">
            {shareIndexGraphDataStatus === 'success' ? (
                <div className="chart-figure">
                    <ResizableBox
                        height={chartDimensions.height}
                        width={chartDimensions.width}
                    >
                        <Chart
                            options={{
                                defaultColors: ['#1E85C5'],
                                data,
                                primaryAxis,
                                secondaryAxes,
                            }}
                        />
                    </ResizableBox>
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default ShareMarketChart

ShareMarketChart.propTypes = {
    type: PropTypes.string,
    duration: PropTypes.string,
    size: PropTypes.string,
}
