/* eslint-disable no-unused-vars */
import Title from 'components/Layout/Titles/Title'
// import Horoscope from 'pages/Horoscope'
import DashboardFeaturedNews from 'components/Layout/Dashboard/DashboardFeaturedNews/DashboardFeaturedNews'
import NewsArticleBundle from 'components/Layout/Dashboard/DashboardNewsArticleBundle'

import './dashboard.scss'
import { useEffect, useState } from 'react'

import HotNewsTab from 'pages/HotNewsTab/HotNewsTab'

import Widget from 'components/Layout/Widgets/Widget'

// REMINDER: Remove this section once the widget api comes in
import { widgetData } from 'global/constants/dummyData'
import VideosBundle from 'components/Layout/VideosBundle/VideosBundle'
import DashboardListTemplate from 'components/Layout/Dashboard/DashboardListTemplate/DashboardListTemplate'
import Spinner from 'components/Spinner/Spinner'
import Advertisement from 'components/Layout/Advertisement/Advertisement'
import { useFetchFm } from 'hooks/fmAndTv/useFetchFm'
import { useFetchTv } from 'hooks/fmAndTv/useFetchTv'
import { useFetchEPaper } from 'hooks/ePaper/useFetchEpaper'
import { useTranslation } from 'react-i18next'
import useStore from 'store/store'
import {
    globalAdvertisement2Img,
    ResizedAd576Img,
    RoadBlockAdvertOne,
} from 'assets'
import { useFetchForex } from 'hooks/forex/useFetchForex'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'
import convertToTwelve from 'utils/convertToTwelve'
import { ShareChart } from 'components/Layout/ShareMarket'
import { useFetchTopDifference } from 'hooks/shareMarket'
import { closePriceTypes } from 'global/constants/appConstants'

const Dashboard = () => {
    const [shareType, setShareType] = useState(false)
    const { t, i18n } = useTranslation()

    const { status: fmListStatus, data: fmListData } = useFetchFm(i18n.language)
    const { status: tvListStatus, data: tvListData } = useFetchTv(i18n.language)
    const { status: ePaperListStatus, data: ePaperListData } = useFetchEPaper(
        i18n.language
    )
    const { status: forexDataStatus, data: forexData } = useFetchForex()
    const { status: todaySharePriceStatus, data: todaySharePriceData } =
        useFetchTopDifference(
            shareType ? closePriceTypes.TOP_LOSS : closePriceTypes.TOP_GAIN
        )
    const [ePaperList, setEPaperList] = useState([])
    const advertisement = useStore((state) => state.advertisement)

    const advertisementLeaderboardImages = [
        {
            bgImg: `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/11/700x100-2.gif`,
            urlLink: 'https://daraz.com.np',
        },
        {
            bgImg: `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/12/Imagekhabar-1.gif`,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const advertisementLeaderboardImagesTwo = [
        {
            bgImg: `https://www.onlinekhabar.com/wp-content/uploads/2022/01/AMBE_OB_1230x100.jpg`,
            urlLink: 'https://daraz.com.np',
        },
        {
            bgImg: `https://www.onlinekhabar.com/wp-content/uploads/2021/12/1230_100-Pixel-Bolero-Udemsil-Nepali.jpg`,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const advertisementLeaderboardImagesThree = [
        {
            bgImg: `https://www.onlinekhabar.com/wp-content/uploads/2021/12/Onlinekhabar-Mobile-900x100px1.jpg`,
            urlLink: 'https://daraz.com.np',
        },
        {
            bgImg: `https://www.onlinekhabar.com/wp-content/uploads/2021/12/1230x1003.gif`,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const payload = [
        {
            bgImg: globalAdvertisement2Img,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const payload1 = [
        {
            bgImg: ResizedAd576Img,
            urlLink: 'https://daraz.com.np',
        },
    ]

    const fullPageAdvert = [
        {
            bgImg: RoadBlockAdvertOne,
        },
    ]

    const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }

    const {
        day: forexDay,
        month: forexMonth,
        year: forexYear,
        time: forexTime,
    } = useGetDateBasedOnCurrentLang(forexData?.data?.payload?.date, options)

    const {
        day: shareMarketDay,
        month: shareMarketMonth,
        year: shareMarketYear,
        time: shareMarketTime,
    } = useGetDateBasedOnCurrentLang(
        todaySharePriceData?.payload[0]?.trade_date,
        options
    )

    useEffect(()=>{
        let updateEPaperListData;
        if(ePaperListData){
            updateEPaperListData= ePaperListData.payload.filter((item)=> item.code !== 'pariwar' && item.code !== 'nagarik' && item.code !== 'shukrabar')
            setEPaperList([...updateEPaperListData])
        }


    },[ePaperListData])

    return (
        <>
            {/* <Advertisement type="full-page" payload={fullPageAdvert} /> */}
            <main className="container">
                {/* <section className="advertisement-section">
                    <div className="advertisement-container">
                        <Advertisement
                            type="placeholder"
                            payload={advertisementLeaderboardImagesTwo}
                        />
                    </div>
                </section> */}
                <section className="dashboard-main-news-section">
                    <div className="dashboard-main-news-container">
                        <Title
                            title={t('dashboard.mainNews')}
                            urlLink="/news"
                        />
                    </div>
                    <section className="dashboard-main-news">
                        <section className="dashboard-main-news-left-side">
                            <article className="dashboard-featured-news-section">
                                <div className="dashboard-featured-news-container">
                                    <DashboardFeaturedNews />
                                </div>
                            </article>
                            <article className="dashboard-single-news-block-section">
                                <NewsArticleBundle />
                            </article>
                        </section>
                        <section className="dashboard-main-news-right-side">
                            <article className="dashboard-fresh-popular-trending-news-section">
                                <div className="dashboard-fresh-popular-trending-news-container">
                                    <HotNewsTab />
                                </div>
                            </article>
                            <article className="dashboard-advertisement-section-two">
                                <Advertisement
                                    type="large-rectangles"
                                    payload={payload}
                                />
                                <Advertisement
                                    type="large-rectangles"
                                    payload={payload1}
                                />
                            </article>
                        </section>
                    </section>
                </section>
            </main>
            <section className="short-widget">
                <div className="short-widget-container">
                    <article className="share-chart-widget">
                        <ShareChart />
                    </article>
                    <article className="share-market-widget">
                        <Widget
                            widgetTitle={t('navbar.shareMarket')}
                            widgetTitleDesc={`${shareMarketMonth} ${shareMarketDay}, ${shareMarketYear}`}
                            widgetPayload={todaySharePriceData}
                            checkBoxSlider={true}
                            checked={shareType}
                            setChecked={setShareType}
                            urlLink="/share-market"
                            type="shareMarket"
                            status={todaySharePriceStatus}
                        />
                    </article>
                    <article className="forex-widget">
                        <Widget
                            widgetTitle={t('navbar.forex')}
                            widgetTitleDesc={`${forexMonth} ${forexDay}, ${forexYear}`}
                            widgetPayload={forexData?.data?.payload?.rates}
                            urlLink="/forex"
                            type="forex"
                        />
                    </article>
                </div>
            </section>

            <main className="container">
                {/* <section className="advertisement-section">
                    <div className="advertisement-container">
                        <Advertisement
                            type="placeholder"
                            payload={advertisementLeaderboardImagesThree}
                        />
                    </div>
                </section> */}
                <section className="dashboard-video-playlist-section">
                    <div className="dashboard-video-playlist-container">
                        <Title title={t('dashboard.videoSection')} />
                        <VideosBundle />
                    </div>
                </section>
                <section className="dashboard-epaper-fm-tv-section">
                    <div className="dashboard-epaper-container">
                        {ePaperListStatus === 'loading' ? (
                            <Spinner />
                        ) : (
                            Array.isArray(ePaperList) &&
                            ePaperList.length > 0 && (
                                <DashboardListTemplate
                                    payload={ePaperList}
                                    title={t('dashboard.ePaper')}
                                    hasCheckbox={false}
                                    type="text"
                                />
                            )
                        )}
                    </div>
                    <div className="dashboard-fm-container">
                        {fmListStatus === 'loading' ? (
                            <Spinner />
                        ) : (
                            Array.isArray(fmListData?.payload) &&
                            fmListData?.payload?.length > 0 && (
                                <DashboardListTemplate
                                    payload={fmListData?.payload}
                                    title={t('dashboard.fm')}
                                    hasCheckbox={true}
                                    type="fm"
                                />
                            )
                        )}
                    </div>
                    <div className="dashboard-tv-container">
                        {tvListStatus === 'loading' ? (
                            <Spinner />
                        ) : (
                            Array.isArray(tvListData?.payload) &&
                            tvListData?.payload?.length > 0 && (
                                <DashboardListTemplate
                                    payload={tvListData?.payload}
                                    title={t('dashboard.tv')}
                                    hasCheckbox={false}
                                    type="tv"
                                />
                            )
                        )}
                    </div>
                </section>
                {/* <section className="advertisement-section">
                    <div className="advertisement-container">
                        <Advertisement
                            type="placeholder"
                            payload={advertisementLeaderboardImages}
                        />
                    </div>
                </section> */}
            </main>
        </>
    )
}

export default Dashboard
