/* eslint-disable no-unused-vars */
import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { FaEllipsisH } from 'react-icons/fa'

import './dashboardNewsArticle.scss'

import EllipseMenu from 'components/Layout/EllipseMenu/EllipseMenuContainer/EllipseMenu'
import { routePaths } from 'global/constants/routePaths'
import ConvertToRelativeTime from 'utils/ConvertToRelativeTime'
import { useTranslation } from 'react-i18next'
import { AiFillTags } from 'react-icons/ai'
import { eknepalSmallImg } from 'assets'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import token from 'utils/token'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const DashboardNewsArticle = ({
    newsAgency,
    newsAgencyIcon,
    title,
    tags,
    date,
    image,
    newsLink,
    id,
    desc,
}) => {
    const [optionMenuClicked, setOptionMenuClicked] = useState(false)

    const { t } = useTranslation()

    return (
        <div className="news-article-container">
            <div className="news-agency-text-container">
                <div className="news-agency-container">
                    <a
                        href={newsLink}
                        className="news-agency-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div
                            className="news-agency"
                            title={`Go to ${newsAgency}'s website`}
                        >
                            <span>
                                {newsAgencyIcon && (
                                    <img
                                        src={replaceHttpWithHttps(newsAgencyIcon)}
                                        alt=""
                                        className="news-agency-icon"
                                    />
                                )}
                            </span>
                            {newsAgency}
                        </div>
                    </a>
                    <EllipseMenu className="extra-options" newsId={id} />
                </div>
                <Link
                    to={routePaths.news.details.replace('{idNumber}', id)}
                    className="news-article-text-link"
                >
                    <div className="news-article-title">{title}</div>
                </Link>
                <p className="news-article-description">{desc}</p>
                <div className="news-article-date-tag-container">
                    <div className="news-article-tags">
                        {Array.isArray(tags) && tags.length > 0 && (
                            <figure className="news-article-tags-figure">
                                <AiFillTags className="news-article-tags-img" />
                            </figure>
                        )}
                        {Array.isArray(tags) && tags.length > 0 && tags
                            ? tags.map((tag, index) => (
                                  <NavLink
                                      to={routePaths.news.tag.replace(
                                          '{tagName}',
                                          encodeURIComponent(String(tag))
                                      )}
                                      className="news-article-tag"
                                      key={index}
                                  >
                                      {tag}
                                  </NavLink>
                              ))
                            : null}
                    </div>
                    <p className="news-date" title={new Date(date)}>
                        {date && ConvertToRelativeTime(new Date(date))}
                    </p>
                </div>
            </div>
            <Link
                to={routePaths.news.details.replace(
                    '{idNumber}',
                    encodeURIComponent(String(id))
                )}
                className="news-article-image-link"
            >
                <figure className="news-image-container">
                    <img
                        src={image ? replaceHttpWithHttps(image) : eknepalSmallImg}
                        alt="particular news"
                        className={classNames('news-article-image', {
                            noImg: !image,
                        })}
                    />
                </figure>
            </Link>
        </div>
    )
}

export default DashboardNewsArticle
