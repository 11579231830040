import React from 'react'
import Title from 'components/Layout/Titles/Title'
import './about.scss'
import { useTranslation } from 'react-i18next'
import { useFetchFm } from 'hooks/fmAndTv/useFetchFm'
import { useFetchTv } from 'hooks/fmAndTv/useFetchTv'
import { useFetchEPaper } from 'hooks/ePaper/useFetchEpaper'
import Spinner from 'components/Spinner/Spinner'
import DashboardListTemplate from 'components/Layout/Dashboard/DashboardListTemplate/DashboardListTemplate'
import {eknepalExtraLargeImg, eknepalLargeImg} from 'assets'

const AboutEknepal = () => {
    const { t, i18n } = useTranslation()
    const { status: fmListStatus, data: fmListData } = useFetchFm(i18n.language)
    const { status: tvListStatus, data: tvListData } = useFetchTv(i18n.language)
    const { status: ePaperListStatus, data: ePaperListData } = useFetchEPaper(
        i18n.language
    )

    // REMINDER: If the client asks for a dual language support in this section, then just go ahead and put the useTranslation hook there and switch it up.
    const contentArr = [
        'Ek Nepal is the news aggregator service developed by Media Alliance Nepal. Ek Nepal presents content of the members of Media Alliance Nepal, the umbrella organization of mainstream media of Nepal.',
        'Fake News is a big problem in our society today. On social networks, misleading and inaccurate information spreads like wildfire, reaching millions of users within minutes. carrying a tremendous threat to confuse and impact our society in a negative way. Media controls the minds of masses so EK Nepal is dedicated to list real news only.',
        'Ek Nepal is available as an app on Android, iOS and the web. ',
    ]

    return (
        <>
            <Title title="About Ek Nepal" bgColor="grey" />
            <div className="container">
                <div className="about-container">
                    <div className="about-content-container">
                        <figure className="about-content-figure">
                            <img src={eknepalExtraLargeImg} alt="" />
                        </figure>
                        <div className="about-text-container">
                            {contentArr.map((item, index) => (
                                <p className="about-text" key={index}>
                                    {item}
                                </p>
                            ))}
                        </div>
                    </div>
                    <section className="dashboard-epaper-fm-tv-section">
                        <div className="dashboard-epaper-container">
                            {ePaperListStatus === 'loading' ? (
                                <Spinner />
                            ) : (
                                Array.isArray(ePaperListData?.payload) &&
                                ePaperListData?.payload?.length > 0 && (
                                    <DashboardListTemplate
                                        payload={ePaperListData.payload}
                                        title={t('dashboard.ePaper')}
                                        hasCheckbox={false}
                                        type="text"
                                    />
                                )
                            )}
                        </div>
                        <div className="dashboard-fm-container">
                            {fmListStatus === 'loading' ? (
                                <Spinner />
                            ) : (
                                Array.isArray(fmListData?.payload) &&
                                fmListData?.payload?.length > 0 && (
                                    <DashboardListTemplate
                                        payload={fmListData?.payload}
                                        title={t('dashboard.fm')}
                                        hasCheckbox={true}
                                        type="fm"
                                    />
                                )
                            )}
                        </div>
                        <div className="dashboard-tv-container">
                            {tvListStatus === 'loading' ? (
                                <Spinner />
                            ) : (
                                Array.isArray(tvListData?.payload) &&
                                tvListData?.payload?.length > 0 && (
                                    <DashboardListTemplate
                                        payload={tvListData?.payload}
                                        title={t('dashboard.tv')}
                                        hasCheckbox={true}
                                        type="tv"
                                    />
                                )
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default AboutEknepal
