import './unicode.scss'
import Title from 'components/Layout/Titles/Title'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import classNames from 'classnames'
import UnicodeIframe from 'components/Layout/Unicode/UnicodeIframe'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import ReactSelect from 'components/ReactSelect'

const Unicode = () => {
    const [activeTab, setActiveTab] = useState(0)
    const { t } = useTranslation()

    const isTabSelector = useMediaQuery('(max-width: 768px)')

    const iframeHeadings = [
        {
            label: t('unicode.preetiToUnicode'),
            value: 0,
        },
        {
            label: t('unicode.romanizedNepaliToUnicode'),
            value: 1,
        },
        {
            label: t('unicode.unicodeToPreeti'),
            value: 2,
        },
    ]

    const iframeSources = [
        'https://www.ashesh.com.np/preeti-unicode/linkapi.php?api=440119m340',
        'https://www.ashesh.com.np/linknepali-unicode3.php?api=440115m448',
        'https://www.ashesh.com.np/unicode-preeti/linkapi.php?api=440115m441',
    ]

    return (
        <>
            <Title title={t('unicode.uniCodeTitle')} bgColor="grey" />
            <div className="container">
                <div className="tabs">
                    <div className="tabs-nav">
                        {!isTabSelector &&
                            iframeHeadings.map((item, index) => (
                                <div
                                    className={classNames('tab-heading', {
                                        activeTab: item.value === activeTab,
                                    })}
                                    onClick={() => setActiveTab(item.value)}
                                    key={index}
                                >
                                    <p
                                        className={classNames(
                                            'tab-heading-text',
                                            {
                                                activeTab:
                                                    item.value === activeTab,
                                            }
                                        )}
                                    >
                                        {item.label}
                                    </p>
                                </div>
                            ))}
                        {isTabSelector && (
                            <ReactSelect
                                options={iframeHeadings}
                                selectDefaultValue={iframeHeadings[0]}
                                height="100%"
                                width="100%"
                                boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                                color="#1D1D1F"
                                handleOnChange={(value) =>
                                    setActiveTab(value.value)
                                }
                                textColor="#a90000"
                                fontWeight="700"
                                singleValueFontSize="18px"
                            />
                        )}
                    </div>
                    <div className="tabs-content unicode-iframe">
                        <UnicodeIframe src={iframeSources[activeTab]} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Unicode
