/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import Advertisement from 'components/Layout/Advertisement/Advertisement'
import LabelInput from 'components/Layout/LabelInput/LabelInput'
import ReactDatePicker from 'components/Layout/ReactDatePicker/ReactDatePicker'
import Title from 'components/Layout/Titles/Title'
import useInfiniteScroll from 'hooks/infiniteScroll'

import { TodaySharePriceTable } from 'components/Layout/TodaySharePrice'

import ShareListingTable from 'components/Layout/ShareMarket/ShareListingTable/index'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'

import { useFetchClosePrice } from 'hooks/shareMarket'
import { useTranslation } from 'react-i18next'

import { BsSearch } from 'react-icons/bs'
import { FiFilter } from 'react-icons/fi'
import { GiCalendar } from 'react-icons/gi'

import { useEffect } from 'react'
import { useFetchMarketSummary } from 'hooks/shareMarket'

import './todaySharePrice.scss'

const TodaySharePrice = () => {
    const [searchSymbol, setSearchSymbol] = useState('')
    const [filterClicked, setFilterClicked] = useState(true)
    const [datePickerValue, setDatePickerValue] = useState()
    const [numberOfItems, setNumberOfItems] = useState(30)
    const [hasMoreData, setHasMoreData] = useState(true)
    const [scrolled, setScrolled] = useState(false)

    const { status: marketSummaryStatus, data: marketSummaryData } =
        useFetchMarketSummary()
    const { status: todaySharePriceStatus, data: todaySharePriceData } =
        useFetchClosePrice(
            searchSymbol ? searchSymbol : '',
            datePickerValue ? datePickerValue.toISOString().split('T')[0] : '',
            numberOfItems,
            {
                enabled: filterClicked || scrolled,
                onSettled: () => {
                    setFilterClicked(false)
                    setScrolled(false)
                },
            }
        )

    const [lastElementRef] = useInfiniteScroll(
        hasMoreData
            ? () => {
                  setScrolled(true)
                  setNumberOfItems((prevnumberOfItems) => prevnumberOfItems + 1)
              }
            : () => {},
        todaySharePriceStatus === 'loading'
    )

    useEffect(() => {
        if (
            todaySharePriceData?.meta_data?.pagination?.total <=
            todaySharePriceData?.payload?.length
        ) {
            setHasMoreData(false)
        }

        // eslint-disable-next-line
    }, [todaySharePriceData, todaySharePriceStatus])

    const handleFormSubmit = (e) => {
        e.preventDefault()
        setFilterClicked(true)
        setNumberOfItems(30)
    }

    const options = {
        day: 'numeric',
        weekday: 'long',
        month: 'long',
        year: 'numeric',
    }

    const { day, month, year, weekday } = useGetDateBasedOnCurrentLang(
        todaySharePriceData?.payload[0]?.trade_date,
        options
    )

    const { t } = useTranslation()

    const placeholderArr = [{}, {}]

    return (
        <>
            <Title
                title={t('shareMarket.todaySharePrice')}
                desc={`*${t(
                    'lastUpdated'
                )}: ${weekday}, ${month} ${day}, ${year}`}
                bgColor="grey"
            />
            <section className="container">
                {/*<section className="advertisement-section">*/}
                {/*    <div className="advertisement-container">*/}
                {/*        <Advertisement*/}
                {/*            type="placeholder"*/}
                {/*            payload={placeholderArr}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</section>*/}
                <form
                    className="search-filter-container"
                    onSubmit={handleFormSubmit}
                >
                    <LabelInput
                        labelDisplay="none"
                        size="small"
                        icon={<BsSearch className="search-icon" />}
                        inputPlaceholder={t('shareMarket.stockSymbol')}
                        inputType="text"
                        inputValue={searchSymbol}
                        handleOnChange={(e) => setSearchSymbol(e.target.value)}
                    />
                    <ReactDatePicker
                        size="small"
                        icon={<GiCalendar />}
                        handleOnChange={(date) => setDatePickerValue(date)}
                        startDate={datePickerValue || (todaySharePriceData?.payload[0]?.trade_date && new Date(todaySharePriceData?.payload[0]?.trade_date))}
                    />
                    <button className="filter-container" type="submit">
                        <FiFilter />
                    </button>
                </form>
                <TodaySharePriceTable
                    payload={
                        (todaySharePriceData?.payload.length > 0 &&
                            todaySharePriceData?.payload) ||
                        []
                    }
                    status={todaySharePriceStatus}
                    lastElementRef={lastElementRef}
                />
                <ShareListingTable
                    title="shareMarket.marketSummary"
                    payload={marketSummaryData?.payload}
                    date={`${weekday}, ${month} ${day}, ${year}`}
                />
            </section>
        </>
    )
}

export default TodaySharePrice

TodaySharePrice.propTypes = {}
