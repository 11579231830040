import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchDobs = (queryDate) => {
    const updatedEndpoints = endpoints.calendar.dob.basedOnDate.replace(
        '{queryDate}',
        queryDate
    )
    return http().get(updatedEndpoints)
}

export function useFetchDobs(queryDate) {
    return useQuery(['fetchDobs', queryDate], () => fetchDobs(queryDate), {
        retry: 3,
    })
}
