import { useQuery } from 'react-query'
import { closePriceTypes } from 'global/constants/appConstants'
import { endpoints } from 'global/endpoints'

import http from 'utils/http'

const fetchTopSectors = () => {
    const updatedEndpoints = endpoints.shareMarket.closePrice.byType.replace(
        '{type}',
        closePriceTypes.TOP_SECTOR
    )
    return http().get(updatedEndpoints)
}

export function useFetchTopSectors(options) {
    return useQuery(['fetchTopSectors'], () => fetchTopSectors(), {
        retry: 3,
        ...options,
    })
}
