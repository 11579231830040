/* eslint-disable no-unused-vars */
import { closePriceTypes } from 'global/constants/appConstants'
import { routePaths } from 'global/constants/routePaths'
import {
    ShareCard,
    ShareListingTable,
    ShareTable,
    ShareChart,
} from 'components/Layout/ShareMarket'
import { useEffect, useState } from 'react'
import {
    useFetchShareIndex,
    useFetchMarketSummary,
    useFetchTopGainers,
    useFetchTopLosers,
    useFetchTopSharesTraded,
    useFetchTopTransactions,
    useFetchTopTurnovers,
    useFetchTopSectors,
} from 'hooks/shareMarket'
import { useHistory } from 'react-router'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'
import { useTranslation } from 'react-i18next'

import './shareMarket.scss'

import Advertisement from 'components/Layout/Advertisement/Advertisement'
import Button from 'components/Layout/Button/Button'
import Carousel from 'utils/carousel/Carousel'
import Title from 'components/Layout/Titles/Title'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'

const ShareMarket = () => {
    const advertisementLeaderboardImages = [
        `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/10/ADBL-1100revised_CRN.gif`,
        `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/10/Prabhu-life-1.gif`,
    ]

    const subHeadingsFull = [
        'shareMarket.symbol',
        'shareMarket.ltp',
        'shareMarket.percentChange',
        'shareMarket.quantity',
    ]

    const topTurnoversSubHeadings = [
        'shareMarket.symbol',
        'shareMarket.turnover',
        'shareMarket.ltp',
    ]

    const topTransactionsSubHeadings = [
        'shareMarket.symbol',
        'shareMarket.transactions',
        'shareMarket.ltp',
    ]

    const topSharesSubHeadings = [
        'shareMarket.symbol',
        'shareMarket.shareTraded',
        'shareMarket.ltp',
    ]

    const topSectorsSubHeadings = [
        'shareMarket.sector',
        'shareMarket.turnoverValues',
        'shareMarket.turnoverVolume',
        'shareMarket.totalTransactionsTitleCase',
    ]

    const { status: marketSummaryStatus, data: marketSummaryData } =
        useFetchMarketSummary()
    const { status: topGainersStatus, data: topGainersData } =
        useFetchTopGainers()
    const { status: topLosersStatus, data: topLosersData } = useFetchTopLosers()
    const { status: topSharesTradedStatus, data: topSharesTradedData } =
        useFetchTopSharesTraded()
    const { status: topTransactionsStatus, data: topTransactionsData } =
        useFetchTopTransactions()
    const { status: topTurnoversStatus, data: topTurnoversData } =
        useFetchTopTurnovers()
    const { status: topSectorsStatus, data: topSectorsData } =
        useFetchTopSectors()
    const { status: shareCardDataStatus, data: shareCardData } =
        useFetchShareIndex()

    const largeSizeCarousel = useMediaQuery('(max-width:1440px)')
    const mediumSizeCarousel = useMediaQuery('(max-width: 1200px)')
    const smallMediumSizeCarousel = useMediaQuery('(max-width: 992px)')
    const smallSizeCarousel = useMediaQuery('(max-width:768px)')
    const extraSmallSizeCarousel = useMediaQuery('(max-width: 576px)')

    const [numberOfItems, setNumberOfItems] = useState(5)

    useEffect(() => {
        if (largeSizeCarousel) {
            setNumberOfItems(4)
        }
        if (mediumSizeCarousel) {
            setNumberOfItems(3)
        }
        if (smallMediumSizeCarousel) {
            setNumberOfItems(2)
        }
        if (smallSizeCarousel) {
            setNumberOfItems(2)
        }
        if (extraSmallSizeCarousel) {
            setNumberOfItems(1)
        }
    }, [
        largeSizeCarousel,
        mediumSizeCarousel,
        smallMediumSizeCarousel,
        smallSizeCarousel,
        extraSmallSizeCarousel,
    ])

    const { t, i18n } = useTranslation()

    const history = useHistory()

    const removeArrows = useMediaQuery('(max-width: 768px)')

    const redirectToTodaysPrice = () => {
        history.push(routePaths.todaySharePrice)
    }

    const options = {
        day: 'numeric',
        weekday: 'long',
        month: 'long',
        year: 'numeric',
    }

    const { day, month, year, weekday } = useGetDateBasedOnCurrentLang(
        marketSummaryData?.payload?.trade_date,
        options
    )

    return (
        <>
            {/* <div className="container no-fixed-height">
                <section className="advertisement-section">
                    <div className="advertisement-container">
                        <Advertisement
                            type="placeholder"
                            payload={advertisementLeaderboardImages}
                        />
                    </div>
                </section>
            </div> */}
            <Title
                title={t('navbar.shareMarket')}
                desc={`*${t(
                    'lastUpdated'
                )}: ${weekday}, ${month} ${day}, ${year}`}
                bgColor="grey"
            />
            <main className="container">
                <div className="share-market-carousel-container">
                    <Carousel
                        itemsNumber={numberOfItems}
                        out={1}
                        size={80}
                        slidesToScroll={1}
                        arrowBgColor="dark"
                        arrows={!removeArrows}
                    >
                        {shareCardDataStatus === 'success' &&
                        Array.isArray(shareCardData?.payload) &&
                        shareCardData?.payload?.length > 0
                            ? shareCardData?.payload?.map((data, index) => (
                                  <ShareCard
                                      key={index}
                                      indexName={data?.title}
                                      amount={data?.value}
                                      changedAmountNum={data?.point_change}
                                      changedAmountPercent={
                                          data?.percent_change
                                      }
                                      result={
                                          data?.point_change < 0
                                              ? 'loss'
                                              : data?.percent_change > 0
                                              ? 'profit'
                                              : 'constant'
                                      }
                                  />
                              ))
                            : null}
                    </Carousel>
                </div>
                <section className="share-market-tables-container">
                    <div className="share-market-chart-summary-container">
                        <div className="share-market-chart-row">
                            <div>
                                <ShareChart size="lg" />
                            </div>
                            <div className="share-market-chart-column">
                                <Button
                                    description={t(
                                        'buttons.todaysPrices'
                                    ).replace(
                                        '{date}',
                                        `${weekday}, ${month} ${day}, ${year}`
                                    )}
                                    bgColor="grey"
                                    size="widthHundred"
                                    disabled={false}
                                    handleButtonClick={redirectToTodaysPrice}
                                />
                                <ShareListingTable
                                    title="shareMarket.marketSummary"
                                    payload={marketSummaryData?.payload}
                                    date={`${weekday}, ${month} ${day}, ${year}`}
                                />
                            </div>
                        </div>
                        <div className="share-market-chart-row">
                            <ShareTable
                                title="shareMarket.topGainers"
                                subHeading={subHeadingsFull}
                                payload={topGainersData?.payload}
                                type={closePriceTypes.TOP_GAIN}
                            />
                            <ShareTable
                                title="shareMarket.topTurnovers"
                                subHeading={topTurnoversSubHeadings}
                                payload={topTurnoversData?.payload}
                                type={closePriceTypes.TOP_TURNOVER}
                            />
                        </div>
                        <div className="share-market-chart-row">
                            <ShareTable
                                title="shareMarket.topLosers"
                                subHeading={subHeadingsFull}
                                payload={topLosersData?.payload}
                                type={closePriceTypes.TOP_LOSS}
                            />
                            <ShareTable
                                title="shareMarket.topTransactions"
                                subHeading={topTransactionsSubHeadings}
                                payload={topTransactionsData?.payload}
                                type={closePriceTypes.TOP_NUM_TRANSACTION}
                            />
                        </div>
                        <div className="share-market-chart-row">
                            <ShareTable
                                title="shareMarket.topSectors"
                                subHeading={topSectorsSubHeadings}
                                payload={topSectorsData?.payload}
                                type={closePriceTypes.TOP_SECTOR}
                            />
                            <ShareTable
                                title="shareMarket.topSharesTraded"
                                subHeading={topSharesSubHeadings}
                                payload={topSharesTradedData?.payload}
                                type={closePriceTypes.TOP_SHARE_TRADED}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default ShareMarket
