/* eslint-disable no-unused-vars */
import './dashboardListTemplateItemCheckbox.scss'
import { useState, useEffect, useRef } from 'react'
import { AiFillPlayCircle } from 'react-icons/ai'
import classNames from 'classnames'
import ReactPlayer from 'react-player/lazy'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const DashboardListTemplateItemCheckbox = ({ payload }) => {
    const [checked, setChecked] = useState('')
    const [tvUrl, setTvUrl] = useState()
    const [audioUrlState, setAudioUrlState] = useState()
    const [playing, setPlaying] = useState(false)

    const tvRef = useRef()

    const handleOnChecked = (e) => {
        setChecked(e.target.value)
    }

    // Put this function as a null otherwise your state will continue to change and will not uncheck the checked state in the fm/tv list.
    const handleRadioButtonChange = (e) => {
        // do nothing
    }

    const handleRadioButtonClicked = (e, url, type) => {
        if (checked === e.target.value) {
            setChecked(null)
        } else {
            setChecked(e.target.value)
        }
        if (type === 'fm') setAudioUrlState(url)
    }

    useEffect(() => {
        if (checked !== null) {
            setPlaying(true)
        } else {
            setPlaying(false)
        }
    }, [checked])

    // REMINDER: payload[0].type differentiates between whether the incoming data is tv or fm format. Handle accordingly.

    return (
        <>
            <ReactPlayer
                url={audioUrlState}
                playing={playing}
                onError={(e) => console.log('OnError', e)}
                className="react-player"
            />
            {Array.isArray(payload) && payload.length > 0
                ? payload.map((data, index) => (
                      <div
                          className="dashboard-list-template-item-has-checkbox-container"
                          key={index}
                      >
                          <form
                              action=""
                              className="dashboard-list-template-item-has-checkbox-form"
                          >
                              <input
                                  type="radio"
                                  checked={checked === data.name}
                                  onChange={handleRadioButtonChange}
                                  onClick={(e) =>
                                      handleRadioButtonClicked(
                                          e,
                                          data.live_stream_link,
                                          data.type
                                      )
                                  }
                                  className="dashboard-list-template-item-has-checkbox-input"
                                  value={data.name}
                                  id={data.name}
                              />
                              <label
                                  className="dashboard-list-template-item-has-checkbox-label"
                                  htmlFor={data.name}
                              >
                                  {' '}
                                  <div className="dashboard-list-template-item-has-checkbox-img-title-container">
                                      <figure className="dashboard-list-template-item-has-checkbox-figure">
                                          {data?.image && (
                                              <img
                                                  src={replaceHttpWithHttps(data?.image)}
                                                  alt=""
                                                  className="dashboard-list-template-item-has-checkbox-img"
                                              />
                                          )}
                                      </figure>
                                      <p className="dashboard-list-template-item-has-checkbox-title">
                                          {data.name}
                                      </p>
                                  </div>
                                  <AiFillPlayCircle
                                      className={classNames(
                                          'dashboard-list-template-item-has-checkbox-play-icon',
                                          { checked: checked === data.name }
                                      )}
                                  />
                              </label>
                          </form>
                      </div>
                      //   </a>
                  ))
                : null}
        </>
    )
}

export default DashboardListTemplateItemCheckbox
