import { useQuery } from 'react-query'
import { closePriceTypes } from 'global/constants/appConstants'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchTopGainers = () => {
    const updatedEndpoints = endpoints.shareMarket.closePrice.byType.replace(
        '{type}',
        closePriceTypes.TOP_GAIN
    )
    return http().get(updatedEndpoints)
}

export function useFetchTopGainers(options) {
    return useQuery(['fetchTopGainers'], () => fetchTopGainers(), {
        retry: 3,
        ...options,
    })
}
