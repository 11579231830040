import React, { useEffect } from 'react'
import './signUp.scss'
import { useTranslation } from 'react-i18next'
import LabelInput from 'components/Layout/LabelInput/LabelInput'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import Button from 'components/Layout/Button/Button'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { authRoutes } from 'global/constants/appConstants'
import useStore from 'store/store'
import FacebookAuthentication from '../FacebookAuthentication/FacebookAuthentication'
import GoogleAuthentication from '../GoogleAuthentication/GoogleAuthentication'

const SignUpUsername = ({ show, setCurrentPage }) => {
    const { t } = useTranslation()

    const addUserCredentials = useStore((store) => store.addUserCredentials)
    const handleSubmit = (e) => {
        e.preventDefault()

        if (isValid) {
            addUserCredentials(values.userName, values.email)
            setCurrentPage(authRoutes.SIGNUP_PASSWORD)
        }
    }

    const schema = object().shape({
        userName: string().required(t('errors.fieldRequired')),
        email: string()
            .email(t('errors.email'))
            .required(t('errors.fieldRequired')),
    })

    const {
        handleBlur,
        values,
        setFieldValue,
        errors,
        isValid,
        touched,
        setErrors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: handleSubmit,
        initialValues: {
            userName: '',
            email: '',
        },
        initialErrors: {
            userName: true,
            email: true,
        },
        enableReinitialize: true,
    })

    const handleGoToSignIn = (e) => {
        setCurrentPage(show && authRoutes.SIGNIN)
    }

    useEffect(() => {
        setErrors({})

        // eslint-disable-next-line
    }, [show])

    return (
        <div
            className={classNames('sign-up-container', {
                show: show,
            })}
        >
            <h4 className="sign-up-form-heading">
                {t('authentication.welcomeToEknepal')}
            </h4>
            <form onSubmit={handleSubmit} className="sign-up-form">
                <LabelInput
                    labelDisplay="none"
                    inputType={'text'}
                    inputName="userName"
                    value={values.userName}
                    touchedError={errors.userName}
                    handleOnChange={(e) =>
                        setFieldValue('userName', e.target.value)
                    }
                    touched={touched.userName}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t(
                        'authentication.signUpSection.username'
                    )}
                />
                <LabelInput
                    labelDisplay="none"
                    inputType={'text'}
                    inputName="email"
                    value={values.email}
                    touchedError={errors.email}
                    handleOnChange={(e) =>
                        setFieldValue('email', e.target.value)
                    }
                    touched={touched.email}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.email')}
                />
                <Button
                    size="widthHundred"
                    bgColor="grey"
                    description={t('buttons.continue')}
                    disabled={!isValid}
                />
            </form>
            <div className="sign-up-bottom-container">
                <div className="sign-up-or-divider">
                    {t('authentication.signInSection.or')}
                </div>
                <div className="sign-up-bottom-buttons-container">
                    <FacebookAuthentication />
                    {/* <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={t(
                            'authentication.signInSection.continueWithGoogle'
                        )}
                        additionalStyles="white-border-grey"
                        img={googleImg}
                        imgAbsolute={true}
                        handleButtonClick={googleButtonClick}
                    /> */}
                    <GoogleAuthentication />
                </div>
                <div className="sign-up-bottom-sign-up-container">
                    <p>{t('authentication.signInSection.noAccountSignUp')}</p>
                    <p
                        className="sign-up-bottom-sign-up"
                        onClick={handleGoToSignIn}
                    >
                        {t('authentication.signIn')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SignUpUsername

SignUpUsername.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
}
