import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
    // Icons
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
} from 'react-share'

const ShareIcons = () => {
    return (
        <div className="news-single-page-share-icons-container">
            <EmailShareButton
                url="https://media.eknepal.com"
                title={document.title}
            >
                <EmailIcon className="share-button" round={true} size={30} />
            </EmailShareButton>
            <FacebookShareButton
                quote={String(document.title)}
                url="https://media.eknepal.com"
            >
                <FacebookIcon className="share-button" round={true} size={30} />
            </FacebookShareButton>
            <TwitterShareButton
                url="https://media.eknepal.com"
                title={document.title}
            >
                <TwitterIcon className="share-button" round={true} size={30} />
            </TwitterShareButton>
            <ViberShareButton
                url="https://media.eknepal.com"
                title={document.title}
            >
                <ViberIcon className="share-button" round={true} size={30} />
            </ViberShareButton>
            <WhatsappShareButton
                url="https://media.eknepal.com"
                title={document.title}
            >
                <WhatsappIcon className="share-button" round={true} size={30} />
            </WhatsappShareButton>
        </div>
    )
}

export default ShareIcons
