/* eslint-disable no-unused-vars */
import './newsSinglePage.scss'

import { useState, useEffect } from 'react'

import Spinner from 'components/Spinner/Spinner'
import { useParamsQuery } from 'utils/helpers/URLLocation'
import { useFetchNewsById } from 'hooks/news/useFetchNewsById'

import Advertisement from 'components/Layout/Advertisement/Advertisement'

import LeftNewsSinglePage from './LeftNewsSinglePage/LeftNewsSinglePage'
import RightNewsSinglePage from './RightNewsSinglePage/RightNewsSinglePage'

const NewsSinglePage = () => {
    const [newsArticleId, setNewsArticleId] = useState('')

    const searchQuery = useParamsQuery()

    const {
        status: newsArticleStatus,
        data: newsArticleData,
        refetch: newsArticleRefetchData,
    } = useFetchNewsById(newsArticleId)

    const advertisementLeaderboardImages = [
        {
            bgImg: `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/11/700x100-2.gif`,
            urlLink: 'https://daraz.com.np',
        },
        {
            bgImg: `https://appharuspace.fra1.digitaloceanspaces.com/imagekhabar/uploads/2021/12/Imagekhabar-1.gif`,
            urlLink: 'https://daraz.com.np',
        },
    ]

    useEffect(() => {
        document.title = newsArticleData?.payload?.title || document.title
    }, [newsArticleData])

    useEffect(() => {
        setNewsArticleId(searchQuery.get('id'))

        //eslint-disable-next-line
    }, [searchQuery.get('id')])

    return newsArticleStatus === 'loading' ? (
        <div className="spinner">
            <Spinner />
        </div>
    ) : (
        <section className="container">
            <section className="advertisement-section">
                {/*<div className="advertisement-container">*/}
                {/*    <Advertisement*/}
                {/*        type="placeholder"*/}
                {/*        payload={advertisementLeaderboardImages}*/}
                {/*    />*/}
                {/*</div>*/}
            </section>
            <div className="news-single-page-container">
                <LeftNewsSinglePage
                    newsArticleData={newsArticleData}
                    newsArticleStatus={newsArticleStatus}
                    newsArticleRefetchData={() => newsArticleRefetchData()}
                    newsArticleId={newsArticleId}
                />
                <RightNewsSinglePage />
            </div>
        </section>
    )
}

export default NewsSinglePage
