/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Layout/Tabs/Tabs'
import { useMemo } from 'react'
import { useFetchPopularNews } from 'hooks/news/useFetchPopularNews'
import { useFetchTrendingNews } from 'hooks/news/useFetchTrendingNews'
import { useEffect, useState } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import Button from 'components/Layout/Button/Button'
import { useTranslation } from 'react-i18next'
import { useFetchNewsPaginate } from 'hooks/news/useFetchNewsPaginate'
import Spinner from 'components/Spinner/Spinner'

const HotNewsTab = () => {
    const [paginateNumber, setPaginateNumber] = useState(8)
    const [newsToShow, setNewsToShow] = useState({
        popular: [],
        recentNews: [],
        trending: [],
    })

    const NEWSPERPAGE = 8

    const { status: popularNewsStatus, data: popularNewsData } =
        useFetchPopularNews(paginateNumber)
    const { status: trendingNewsStatus, data: trendingNewsData } =
        useFetchTrendingNews(paginateNumber)
    const { status: recentNewsStatus, data: recentNewsData } =
        useFetchNewsPaginate(paginateNumber + 4)

    function loadMore() {
        if (
            popularNewsStatus === 'success' &&
            recentNewsStatus === 'success' &&
            trendingNewsStatus === 'success'
        ) {
            setNewsToShow((prevNews) => ({
                ...prevNews,
                popular: [
                    ...new Set([
                        ...prevNews.popular,
                        ...popularNewsData?.payload?.slice(
                            paginateNumber - NEWSPERPAGE,
                            paginateNumber
                        ),
                    ]),
                ],
                recentNews: [
                    ...new Set([
                        ...prevNews.recentNews,
                        ...recentNewsData?.payload?.slice(
                            paginateNumber - NEWSPERPAGE,
                            paginateNumber
                        ),
                    ]),
                ],
                trending: [
                    ...new Set([
                        ...prevNews.trending,
                        ...trendingNewsData?.payload?.slice(
                            paginateNumber - NEWSPERPAGE,
                            paginateNumber
                        ),
                    ]),
                ],
            }))
            setPaginateNumber(paginateNumber + NEWSPERPAGE)
        }
    }

    useEffect(() => {
        loadMore()
    }, [])

    useEffect(() => {
        if (popularNewsStatus === 'success') {
            setNewsToShow((prevNews) => ({
                ...prevNews,
                popular: popularNewsData?.payload,
            }))
        }
        if (recentNewsStatus === 'success') {
            setNewsToShow((prevNews) => ({
                ...prevNews,
                recentNews: recentNewsData?.payload?.slice(
                    4,
                    recentNewsData?.payload?.length
                ),
            }))
        }
        if (trendingNewsStatus === 'success') {
            setNewsToShow((prevNews) => ({
                ...prevNews,
                trending: trendingNewsData?.payload,
            }))
        }
    }, [popularNewsStatus, trendingNewsStatus, recentNewsStatus])

    const { t } = useTranslation()

    return (
        <>
            {useMemo(
                () => (
                    <Tabs newsContent={newsToShow} />
                ),
                [newsToShow]
            )}
            {popularNewsStatus === 'success' ||
            recentNewsStatus === 'success' ||
            trendingNewsStatus === 'success' ? (
                <Button
                    description={t('more')}
                    handleButtonClick={loadMore}
                    bgColor="grey"
                    size="widthHundred"
                    icon={<RiArrowDownSLine />}
                />
            ) : (
                <Button
                    description={<Spinner isWhite={true} />}
                    bgColor="grey"
                    size="widthHundred"
                />
            )}
        </>
    )
}

export default HotNewsTab
