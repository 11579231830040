/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import './signUp.scss'
// import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LabelInput from 'components/Layout/LabelInput/LabelInput'
import { useFormik } from 'formik'
import { object, string, ref } from 'yup'
import Button from 'components/Layout/Button/Button'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { authRoutes } from 'global/constants/appConstants'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import useStore from 'store/store'
import usePostUserRegistration from 'hooks/userAuth/usePostUserRegistration'
import Spinner from 'components/Spinner/Spinner'

const SignUpPassword = ({ show, setCurrentPage }) => {
    const { t } = useTranslation()

    const [showPassword, setShowPassword] = useState({
        pwd: false,
        confirmPwd: false,
    })

    const {
        status,
        mutate: registrationFn,
        isSuccess: registrationSuccess,
        isLoading: registrationLoading,
        error,
    } = usePostUserRegistration()

    const userCredentials = useStore((store) => store.userCredentials)

    const schema = object().shape({
        password: string()
            .required(t('errors.fieldRequired'))
            .test(
                'len',
                t('errors.mustBe620Long'),
                (val) => val?.trim()?.length >= 6 && val?.trim()?.length <= 20
            ),
        confirmPassword: string()
            .required(t('errors.fieldRequired'))
            .oneOf([ref('password'), null], t('errors.mustMatch')),
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const postData = {
            ...userCredentials,
            password: values.password,
            password_confirmation: values.confirmPassword,
        }
        registrationFn(postData)
    }

    const handleGoBackToSignIn = (e) => {
        e.preventDefault()
        setCurrentPage(authRoutes.SIGNUP_USERNAME)
    }

    useEffect(() => {
        if (registrationSuccess) {
            setCurrentPage(authRoutes.OTP)
        }

        // eslint-disable-next-line
    }, [registrationSuccess])

    const {
        handleBlur,
        values,
        setFieldValue,
        errors,
        isValid,
        touched,
        setErrors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: handleSubmit,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        initialErrors: {
            password: true,
            confirmPassword: true,
        },
        enableReinitialize: true,
    })

    useEffect(() => {
        setErrors({})

        // eslint-disable-next-line
    }, [show])

    return (
        <div className={classNames('sign-up-container', { show: show })}>
            <h4 className="sign-up-form-heading">
                {t('authentication.signUpPassword')}
            </h4>
            <form onSubmit={handleSubmit} className="sign-up-form">
                <LabelInput
                    labelDisplay="none"
                    inputType={showPassword.pwd ? 'text' : 'password'}
                    inputName="password"
                    value={values.password}
                    touchedError={errors.password}
                    handleOnChange={(e) =>
                        setFieldValue('password', e.target.value)
                    }
                    touched={touched.password}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.password')}
                    icon={
                        showPassword.pwd ? (
                            <AiFillEye />
                        ) : (
                            <AiFillEyeInvisible />
                        )
                    }
                    iconOnClick={() =>
                        setShowPassword((prevState) => ({
                            ...prevState,
                            pwd: !prevState.pwd,
                        }))
                    }
                />
                <LabelInput
                    labelDisplay="none"
                    inputType={showPassword.confirmPwd ? 'text' : 'password'}
                    inputName="confirmPassword"
                    value={values.confirmPassword}
                    touchedError={errors.confirmPassword}
                    handleOnChange={(e) =>
                        setFieldValue('confirmPassword', e.target.value)
                    }
                    touched={touched.confirmPassword}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.password')}
                    icon={
                        showPassword.confirmPwd ? (
                            <AiFillEye />
                        ) : (
                            <AiFillEyeInvisible />
                        )
                    }
                    iconOnClick={() =>
                        setShowPassword((prevState) => ({
                            ...prevState,
                            confirmPwd: !prevState.confirmPwd,
                        }))
                    }
                />
                <div className="sign-up-buttons-container">
                    {registrationLoading ? (
                        <Button
                            size="widthHundred"
                            bgColor="grey"
                            description={<Spinner isWhite={true} />}
                        />
                    ) : (
                        <Button
                            size="widthHundred"
                            bgColor="grey"
                            description={t('buttons.continue')}
                            disabled={!isValid}
                        />
                    )}
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={t('buttons.goBack')}
                        handleButtonClick={handleGoBackToSignIn}
                    />
                </div>
            </form>
        </div>
    )
}

export default SignUpPassword

SignUpPassword.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
}
