import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchTv = (currentLang) => {
    const updatedEndpoints = endpoints.fmAndTv.tv.replace(
        '{language}',
        currentLang
    )
    return http().get(updatedEndpoints)
}

export function useFetchTv(currentLang) {
    return useQuery(['fetchTv', currentLang], () => fetchTv(currentLang), {
        retry: 3,
    })
}
