import { useState } from 'react'
import TabsContent from './TabsContent/TabsContent'

import './tabs.scss'

import classNames from 'classnames'
import { PropTypes } from 'prop-types'
import Spinner from 'components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import ReactSelect from 'components/ReactSelect'

const Tabs = ({ newsContent }) => {
    const [activeTab, setActiveTab] = useState('recentNews')

    const isTabSelector = useMediaQuery('(max-width: 768px)')

    const { t } = useTranslation()

    const tabHeading = [
        { label: t('recentlyUpdated'), value: 'recentNews' },
        { label: t('news.selection.popular'), value: 'popular' },
        { label: t('news.selection.trending'), value: 'trending' },
    ]

    return (
        <div className="tabs">
            <div className="tabs-nav">
                {!isTabSelector &&
                    tabHeading?.map((heading, index) => (
                        <div
                            className={classNames('tab-heading', {
                                activeTab: activeTab === heading.value,
                            })}
                            onClick={() => setActiveTab(heading.value)}
                            key={index}
                        >
                            <p
                                className={classNames('tab-heading-text', {
                                    activeTab: activeTab === heading.value,
                                })}
                            >
                                {heading.label}
                            </p>
                        </div>
                    ))}
                {isTabSelector && (
                    <ReactSelect
                        options={tabHeading}
                        selectDefaultValue={tabHeading[0]}
                        height="100%"
                        width="100%"
                        boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                        color="#1D1D1F"
                        handleOnChange={(value) => setActiveTab(value.value)}
                        textColor="#a90000"
                        fontWeight="700"
                        singleValueFontSize="18px"
                    />
                )}
            </div>
            <div className="tabs-content">
                {newsContent.recentNews.length > 0 ||
                newsContent.popular.length > 0 ||
                newsContent.trending.length > 0 ? (
                    <TabsContent
                        tab={
                            activeTab === 'popular'
                                ? newsContent.popular
                                : activeTab === 'recentNews'
                                ? newsContent.recentNews
                                : newsContent.trending
                        }
                        activeTabHeading={tabHeading.value}
                    />
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    )
}

export default Tabs

Tabs.propTypes = {
    newsContent: PropTypes.object.isRequired,
}
