import React from 'react'
import useFetchWeatherLocation from 'hooks/weather/useFetchWeatherLocation'
import Spinner from 'components/Spinner/Spinner'
import { locationTagImg, degreeTagImg } from 'assets'
import './weather.scss'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'

const Weather = ({ lat, long }) => {
    const { status: weatherStatus, data: weatherData } =
        useFetchWeatherLocation(lat, long)
    const { i18n } = useTranslation()

    return weatherStatus === 'loading' ? (
        <Spinner isSmall={true} />
    ) : (
        !(weatherStatus === 'error') && (
            <div className="weather-location-container">
                <div className="weather-location-location-container">
                    <figure className="weather-location-location-tag-figure">
                        <img src={locationTagImg} alt="" />
                    </figure>
                    {weatherData?.payload?.name}
                </div>
                <div className="weather-location-temperature-container">
                    <figure className="weather-location-weather-icon-figure">
                        <img
                            src={`http://openweathermap.org/img/w/${weatherData?.payload?.weather[0]?.icon}.png`}
                            alt={weatherData?.payload?.weather[0]?.description}
                        />
                    </figure>
                    {i18n.language === lang.ENGLISH
                        ? parseInt(weatherData?.payload?.main?.temp)
                        : EnglishToNepaliConverter(
                              parseInt(weatherData?.payload?.main?.temp)
                          )}{' '}
                    <figure className="weather-location-degree-figure">
                        <img src={degreeTagImg} alt="" />
                    </figure>
                </div>
            </div>
        )
    )
}

export default Weather
