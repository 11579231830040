import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'

const ReactSelect = ({ ...props }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [touched, setTouched] = useState(false)

    const style = {
        control: (base) => ({
            ...base,
            border: !menuOpen
                ? props.border
                    ? props.border
                    : '1px solid #d6d6d6'
                : 1,
            minHeight: '30px',
            height: props.height ? props.height : '30px',
            width: props.width ? props.width : '30px',
            boxShadow: props.boxShadow && menuOpen ? props.boxShadow : 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: menuOpen && '#f6f6f6',
            color: menuOpen && 'black',
            '&:hover': {
                backgroundColor: '#d6d6d6',
            },
            'z-index': '100',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: props.textColor,
            fontWeight: props.fontWeight,
            fontSize: props.singleValueFontSize,
            overflow: 'visible',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: props.height ? props.height : '30px',
            padding: '0 6px',
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            height: props.height ? props.height : '30px',
            ...provided,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: props.color ? props.color : '#a90000',
        }),
    }

    return props.async === true ? (
        <AsyncSelect
            {...props}
            styles={style}
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={() => setMenuOpen(false)}
            onChange={props?.handleOnChange}
            className={props?.className}
            classNamePrefix={props?.classNamePrefix}
            defaultValue={props?.selectDefaultValue}
            value={props?.selectValue}
            isSearchable={props?.isSearchable}
            loadOptions={props?.options}
        />
    ) : (
        <>
            <Select
                {...props}
                styles={style}
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => setMenuOpen(false)}
                onChange={props?.handleOnChange}
                className={props?.className}
                classNamePrefix={props?.classNamePrefix}
                defaultValue={props?.selectDefaultValue}
                value={props?.selectValue}
                isSearchable={props?.isSearchable}
                options={props?.options}
                onBlur={() => setTouched(true)}
            />
            {touched && props.error && (
                <p className="select-error">{props.error}</p>
            )}
        </>
    )
}

export default ReactSelect
