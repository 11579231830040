/* eslint-disable no-unused-vars */
import Title from 'components/Layout/Titles/Title'
import './nepaliCalendar.scss'
import { useTranslation } from 'react-i18next'
import { useFetchDobs } from 'hooks/calendar/useFetchDobs'
import Spinner from 'components/Spinner/Spinner'
import ThisMonthBirthday from 'components/Layout/Calendar/ThisMonthBirthday/ThisMonthBirthday'
import ThisMonthHoliday from 'components/Layout/Calendar/ThisMonthHoliday/ThisMonthHoliday'
import ThisMonthEvents from 'components/Layout/Calendar/ThisMonthEvents/ThisMonthEvents'
import { FaBirthdayCake } from 'react-icons/fa'
import { BsFillCalendarEventFill } from 'react-icons/bs'
import { GiPartyFlags } from 'react-icons/gi'
import { useState, useEffect } from 'react'
import { useFetchHolidays } from 'hooks/calendar/useFetchHolidays'
import { useFetchEvents } from 'hooks/calendar/useFetchEvents'
import NepaliDate from 'nepali-date-converter'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import { nepaliMonths } from 'global/constants/dummyData'

const NepaliCalendar = () => {
    const { t, i18n } = useTranslation()

    const [currentDate, setCurrentDate] = useState(
        new Date().toISOString().split('T')[0]
    )
    const [nepaliMonth, setNepaliMonth] = useState(
        new NepaliDate(new Date()).getBS().month + 1
    )

    const { status: dobStatus, data: dobData } = useFetchDobs(currentDate)
    const { status: eventsStatus, data: eventsData } = useFetchEvents(
        i18n.language,
        nepaliMonth
    )
    const { status: holidayStatus, data: holidayData } = useFetchHolidays(
        i18n.language
    )

    const [mappedHolidayData, setMappedHolidayData] = useState()

    useEffect(() => {
        holidayStatus === 'success' &&
            setMappedHolidayData((prevState) =>
                Object.keys(holidayData?.payload).map((key) => {
                    return holidayData?.payload[key]
                })
            )

        // eslint-disable-next-line
    }, [holidayStatus])

    const isMedium = useMediaQuery('(max-width: 900px)')
    const isSmall = useMediaQuery('(max-width: 576px)')

    const [iframeSrc, setIframeSrc] = useState(
        'https://www.hamropatro.com/widgets/calender-full.php'
    )

    useEffect(() => {
        if (isMedium) {
            setIframeSrc(
                'https://www.hamropatro.com/widgets/calender-medium.php'
            )
        } else if (isSmall) {
            setIframeSrc(
                'https://www.hamropatro.com/widgets/calender-medium.php'
            )
        } else {
            setIframeSrc('https://www.hamropatro.com/widgets/calender-full.php')
        }
    }, [isMedium, isSmall])

    return (
        <>
            <Title title={t('navbar.calendar')} bgColor="grey" />
            <div id="np_widget_wiz1" widget="month"></div>
            <script
                async
                src="https://nepalipatro.com.np/np-widgets/nepalipatro.js"
                id="wiz1"
            ></script>{' '}
            <div className="container">
                <div className="calendar-container">
                    <div className="calendar-iframe-container">
                        <div className="remover-one"></div>
                        <div className="remover-two"></div>
                        <iframe
                            title="calendar"
                            src={iframeSrc}
                            frameBorder="0"
                            scrolling="no"
                            marginWidth="0"
                            marginHeight="0"
                            id="myiFrame"
                            allowtransparency="true"
                            className="iframe-calendar"
                        ></iframe>
                    </div>
                    <div className="calendar-section-content-container">
                        <div className="calendar-this-month-container">
                            <div className="calendar-title-container">
                                <GiPartyFlags className="calendar-dob-title-icon" />
                                <h4 className="calendar-dob-title">
                                    {t('calendar.thisYearHoliday')}
                                </h4>
                            </div>
                            <div className="calendar-content-container">
                                {holidayStatus === 'success' ? (
                                    Array.isArray(mappedHolidayData) &&
                                    mappedHolidayData.length > 0 ? (
                                        <ThisMonthHoliday
                                            payload={mappedHolidayData}
                                        />
                                    ) : (
                                        <p className="calendar-not-available-text">
                                            {t(
                                                'calendar.isNotAvailable'
                                            ).replace(
                                                '{item}',
                                                t('calendar.holiday')
                                            )}
                                        </p>
                                    )
                                ) : (
                                    <Spinner />
                                )}
                            </div>
                        </div>
                        <div className="calendar-this-month-container">
                            <div className="calendar-title-container">
                                <FaBirthdayCake className="calendar-dob-title-icon" />
                                <h4 className="calendar-dob-title">
                                    {t('calendar.thisMonthBirthday').replace(
                                        '{thisMonth}',
                                        nepaliMonths[nepaliMonth - 1]
                                    )}
                                </h4>
                            </div>
                            <div className="calendar-content-container">
                                {holidayStatus === 'success' ? (
                                    Array.isArray(holidayData?.payload) &&
                                    holidayData?.payload.length > 0 ? (
                                        holidayData?.payload?.map(
                                            (item, index) => (
                                                <ThisMonthBirthday
                                                    payload={item}
                                                    key={index}
                                                />
                                            )
                                        )
                                    ) : (
                                        <p className="calendar-not-available-text">
                                            {t(
                                                'calendar.isNotAvailable'
                                            ).replace(
                                                '{item}',
                                                t('calendar.birthday')
                                            )}
                                        </p>
                                    )
                                ) : (
                                    <Spinner />
                                )}
                            </div>
                        </div>
                        <div className="calendar-this-month-container">
                            <div className="calendar-title-container">
                                <BsFillCalendarEventFill className="calendar-dob-title-icon" />
                                <h4 className="calendar-dob-title">
                                    {t('calendar.thisMonthEvents').replace(
                                        '{thisMonth}',
                                        nepaliMonths[nepaliMonth - 1]
                                    )}
                                </h4>
                            </div>
                            <div className="calendar-content-container">
                                {eventsStatus === 'success' ? (
                                    Array.isArray(eventsData?.payload) &&
                                    eventsData?.payload.length > 0 ? (
                                        eventsData?.payload?.map(
                                            (item, index) => (
                                                <ThisMonthEvents
                                                    payload={item}
                                                    key={index}
                                                />
                                            )
                                        )
                                    ) : (
                                        <p className="calendar-not-available-text">
                                            {t(
                                                'calendar.isNotAvailable'
                                            ).replace(
                                                '{item}',
                                                t('calendar.event')
                                            )}
                                        </p>
                                    )
                                ) : (
                                    <Spinner />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NepaliCalendar
