import { logoImg } from 'assets'
import classNames from 'classnames'

const SingleTabContentText = ({
    img,
    title,
    newsAgencyName,
    newsAgencyIcon,
    handleClick,
    date,
}) => {
    return (
        <div className="single-tab-content-container" onClick={handleClick}>
            <figure className="single-tab-content-figure">
                <img
                    src={img ? img : logoImg}
                    alt=""
                    className={classNames('single-tab-content-img', {
                        noImg: !img,
                    })}
                />
            </figure>

            <div className="single-tab-content-left-side">
                <p className="single-tab-content-title">{title}</p>
                <div className="single-tab-content-news-agency-date-container">
                    <div className="single-tab-content-news-agency">
                        {newsAgencyIcon && (
                            <img
                                src={newsAgencyIcon}
                                alt=""
                                className="single-tab-content-news-agency-img"
                            />
                        )}
                        <span className="single-tab-content-news-agency-name">
                            {newsAgencyName}
                        </span>
                    </div>
                    <div className="single-tab-content-date">{date}</div>
                </div>
            </div>
        </div>
    )
}

export default SingleTabContentText
