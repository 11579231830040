/* eslint-disable no-unused-vars */
import './authentication.scss'

import { AiOutlineClose } from 'react-icons/ai'
import { authRoutes } from 'global/constants/appConstants'
import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import ForgotPassword from 'components/Layout/ForgotPassword/ForgotPassword'
import Otp from 'components/Layout/Otp/Otp'
import LogOut from 'components/Layout/LogOut/LogOut'
import Modal from 'components/Modal/Modal'
import ResetPassword from 'components/Layout/ResetPassword/ResetPassword'
import SignIn from 'components/Layout/SignIn/SignIn'
import SignUpPassword from 'components/Layout/SignUp/SignUpPassword'
import SignUpUsername from 'components/Layout/SignUp/SignUpUsername'

import token from 'utils/token'
import useStore from 'store/store'

const Authentication = ({ authRef }) => {
    const { t } = useTranslation()

    const userDetails = useStore((store) => store.userDetails)

    const [currentPageHeading, setCurrentPageHeading] = useState(
        token.getUserToken() ? authRoutes.LOGOUT : authRoutes.SIGNIN
    )

    const handleCloseButtonClick = (e) => {
        e.preventDefault()
        authRef.current.close()
    }

    const [currentPage, setCurrentPage] = useState(
        token.getUserToken() ? authRoutes.LOGOUT : authRoutes.SIGNIN
    )

    useEffect(() => {
        if (Object.keys(userDetails).length > 0) {
            setCurrentPage(authRoutes.LOGOUT)
        }
    }, [userDetails])

    useEffect(() => {
        switch (currentPage) {
            case authRoutes.SIGNIN:
                setCurrentPageHeading(t('authentication.signIn'))
                break
            case authRoutes.FORGOT_PASSWORD:
                setCurrentPageHeading(t('authentication.forgotPassword'))
                break
            case authRoutes.PASSWORD_RESET:
                setCurrentPageHeading(t('authentication.passwordReset'))
                break
            case authRoutes.OTP:
                setCurrentPageHeading(t('authentication.otpVerification'))
                break
            case authRoutes.SIGNUP_USERNAME:
                setCurrentPageHeading(t('authentication.welcomeToEknepal'))
                break
            case authRoutes.SIGNUP_PASSWORD:
                setCurrentPageHeading(t('authentication.signUpPassword'))
                break
            case authRoutes.LOGOUT:
                setCurrentPageHeading(t('authentication.logOutHeading'))
                break
            default:
                setCurrentPageHeading(t('authentication.signIn'))
                break
        }

        // eslint-disable-next-line
    }, [currentPage])

    return (
        <Modal fade={true} ref={authRef}>
            <div className="authentication-container">
                <div className="authentication-top-container">
                    <p className="authentication-top-sign-in">
                        {currentPageHeading}
                    </p>
                    <AiOutlineClose
                        onClick={handleCloseButtonClick}
                        className="authentication-close-button"
                    />
                </div>
                <div className="authentication-content-container">
                    <SignIn
                        show={currentPage === authRoutes.SIGNIN}
                        setCurrentPage={setCurrentPage}
                        authRef={authRef}
                    />
                    <SignUpUsername
                        show={currentPage === authRoutes.SIGNUP_USERNAME}
                        setCurrentPage={setCurrentPage}
                    />
                    <SignUpPassword
                        show={currentPage === authRoutes.SIGNUP_PASSWORD}
                        setCurrentPage={setCurrentPage}
                    />
                    <Otp
                        show={currentPage === authRoutes.OTP}
                        setCurrentPage={setCurrentPage}
                    />
                    <ForgotPassword
                        show={currentPage === authRoutes.FORGOT_PASSWORD}
                        setCurrentPage={setCurrentPage}
                    />
                    <ResetPassword
                        show={currentPage === authRoutes.PASSWORD_RESET}
                        setCurrentPage={setCurrentPage}
                    />
                    <LogOut
                        show={currentPage === authRoutes.LOGOUT}
                        setCurrentPage={setCurrentPage}
                        authRef={authRef}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default Authentication

Authentication.propTypes = {
    authRef: PropTypes.object,
}
