import { PropTypes } from 'prop-types'
import { IsItemDuplicate } from 'utils/helpers/isItemDuplicate/IsItemDuplicate'
import { NepaliNumberValidator } from 'utils/helpers/nepaliNumberValidator/NepaliNumberValidator'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'

const NumberConverter = (number) => {
    number = String(number)
    const newValueArr = number.split('')
    

    if (IsItemDuplicate(newValueArr, '.'))
        return NepaliNumberValidator(
            EnglishToNepaliConverter(number.replace(/.([^.]*)$/, '$1'))
        )

    try {
        if ('.' in newValueArr) {
        }
        let firstHalf = number.split('.')[0]
        let secondHalf = number.split('.')[1].replaceAll(',', '')

        number = firstHalf + '.' + secondHalf
    } catch (error) {}

    NepaliNumberValidator(
        EnglishToNepaliConverter(number.replace(/.([^.]*)$/, '$1'))
    )
    let result = EnglishToNepaliConverter(number)
    if (result !== 'undefined') {
        return NepaliNumberValidator(EnglishToNepaliConverter(number))
    }
}

export default NumberConverter

NumberConverter.propTypes = {
    e: PropTypes.object,
}
