import { useRef, useCallback } from 'react'

const useInfiniteScroll = (callback, isFetching) => {
    // NOTE: Here, we use a useRef to store a DOM node and the retunred object will persist regardless of re-rendering
    const observer = useRef()

    // NOTE: useCallback takes a callback argument and an array dependency list and returns a memoized callback which is guaranteed to have the same reference
    const lastElementRef = useCallback(
        (node) => {
            if (isFetching) return

            // NOTE: Stop watching the target. You can think of it as a reset.
            if (observer.current) observer.current.disconnect()

            // NOTE: Create a new intersection observer and execute the callback inscase of an intersecting event
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    callback()
                }
            })

            // NOTE: If there is a node, let the intersection observer watch that node
            if (node) observer.current.observe(node)
        },
        [callback, isFetching]
    )

    return [lastElementRef]
}

export default useInfiniteScroll
