import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchNewsById = (id) => {
    return http().get(endpoints.news.details.replace('{id}', id))
}

export function useFetchNewsById(id) {
    return useQuery(['fetchNewsById', id], () => fetchNewsById(id), {
        retry: 3,
    })
}
