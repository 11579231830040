// import { useFetchNews } from 'hooks/news/useFetchNews'
import Spinner from 'components/Spinner/Spinner'
import DashboardNewsArticle from '../DashboardNewsArticle/DashboardNewsArticle'
import { useFetchNewsPaginate } from 'hooks/news/useFetchNewsPaginate'

import './dashboardNewsArticleBundle.scss'

const DashboardNewsArticleBundle = () => {
    const { status: statusNewsPaginate, data: newsDataPaginate } =
        useFetchNewsPaginate(4)

    return statusNewsPaginate === 'loading' ? (
        <div className="spinner">
            <Spinner />
        </div>
    ) : (
        <>
            <div className="news-article-bundle-container">
                {Array.isArray(newsDataPaginate?.payload) &&
                newsDataPaginate?.payload.length > 0
                    ? newsDataPaginate?.payload?.map((article) => (
                          <DashboardNewsArticle
                              id={article.id}
                              key={article.id}
                              newsAgency={article.channel_name}
                              newsAgencyIcon={article.channel_icon}
                              title={article.title}
                              content={article.description}
                              tags={
                                  article?.news_tags
                                      ? article.news_tags.slice(0, 4)
                                      : null
                              }
                              date={article.created_at}
                              image={article?.image ? article.image : null}
                              newsLink={article.link}
                              desc={article?.description}
                          />
                      ))
                    : null}
            </div>
        </>
    )
}

export default DashboardNewsArticleBundle
