import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchPopularNews = (paginateNumber) => {
    const updatedEndpoints = endpoints.news.popularNews.paginate.replace(
        '{number}',
        paginateNumber
    )
    return http().get(updatedEndpoints)
}

export function useFetchPopularNews(paginateNumber) {
    return useQuery(
        ['fetchPopularNews', paginateNumber],
        () => fetchPopularNews(paginateNumber),
        { retry: 3 }
    )
}
