import Spinner from 'components/Spinner/Spinner'
import { routePaths } from 'global/constants/routePaths'
import Carousel from 'utils/carousel/Carousel'

import { useState, useEffect } from 'react'

import './dashboardFeaturedNews.scss'

import { NavLink } from 'react-router-dom'
import { useFetchDashboardFeaturedNews } from 'hooks/news/useFetchDashboardFeaturedNews'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const DashboardFeaturedNews = () => {
    const { status: statusNews, data: newsData } =
        useFetchDashboardFeaturedNews()

    const [dashboardNewsFeed, setDashboardNewsFeed] = useState([])

    useEffect(() => {
        if (statusNews === 'success') {
            setDashboardNewsFeed(newsData?.payload)
        }
        //eslint-disable-next-line
    }, [statusNews, dashboardNewsFeed])

    const shouldDots = useMediaQuery('(max-width: 768px)')

    return statusNews === 'success' ? (
        <Carousel itemsNumber={1} dots={shouldDots}>
            {Array.isArray(dashboardNewsFeed)
                ? dashboardNewsFeed?.map((data, index) => (
                      <div
                          className="dashboard-featured-news-container"
                          key={index}
                      >
                          <NavLink
                              to={routePaths.news.details.replace(
                                  '{idNumber}',
                                  encodeURIComponent(String(data.id))
                              )}
                          >
                              {data.image && (
                                  <figure className="dashboard-featured-news-figure">
                                      <img
                                          src={replaceHttpWithHttps(data?.image)}
                                          alt=""
                                          className="dashboard-featured-news-image"
                                      />
                                  </figure>
                              )}
                          </NavLink>
                          <div className="dashboard-featured-news-bottom-container">
                              <div className="dashboard-news-agency-date-container">
                                  <a
                                      href={data.postable_type}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="dashboard-news-agency-redirect-link"
                                  >
                                      <p className="dashboard-news-agency-details">
                                          {data?.channel_icon && (
                                              <img
                                                  src={
                                                      replaceHttpWithHttps(data?.channel_icon)
                                                  }
                                                  alt="news channel icon"
                                                  className="dashboard-news-agency-icon"
                                              />
                                          )}
                                          <span className="dashboard-news-agency-name">
                                              {data?.channel_name}
                                          </span>
                                      </p>
                                  </a>
                                  {/* <p
                                      className="dashboard-news-relative-date"
                                      title={new Date(data.created_at)}
                                  >
                                      {ConvertToRelativeTime(
                                          new Date(data.created_at)
                                      )}
                                  </p> */}
                              </div>
                              <NavLink
                                  to={routePaths.news.details.replace(
                                      '{idNumber}',
                                      encodeURIComponent(String(data.id))
                                  )}
                                  className="dashboard-news-title-redirect-link"
                              >
                                  <div className="dashboard-news-title-container">
                                      <h2 className="dashboard-news-title">
                                          {data.title}
                                      </h2>
                                  </div>
                              </NavLink>
                              {/* <div className="dashboard-news-tags-container">
                                  <AiFillTags />
                                  {Array.isArray(data.news_tags) &&
                                  data.news_tags.length > 0
                                      ? data.news_tags.map((tag, index) => (
                                            <NavLink
                                                to={routePaths.news.tag.replace(
                                                    '{tagName}',
                                                    encodeURIComponent(
                                                        String(tag)
                                                    )
                                                )}
                                                className="dashboard-news-tag"
                                                key={index}
                                            >
                                                {tag}
                                            </NavLink>
                                        ))
                                      : null}
                              </div> */}
                          </div>
                      </div>
                  ))
                : 'No news found.'}
        </Carousel>
    ) : (
        <Spinner />
    )
}

export default DashboardFeaturedNews
