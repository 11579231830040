import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchGoldSilverList = () => {
    const updatedEndpoints = endpoints.goldSilver.base
    return http().get(updatedEndpoints)
}

export function useFetchGoldSilverList() {
    return useQuery(['fetchGoldSilverList'], () => fetchGoldSilverList())
}
