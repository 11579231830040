/* eslint-disable no-unused-vars */
import './emiCalculator.scss'
import { useState } from 'react'
import NumberConverter from 'hooks/numberConverter/NumberConverter'
import Button from 'components/Layout/Button/Button'
import { useTranslation } from 'react-i18next'
import { NepaliToEnglishConverter } from 'utils/helpers/nepalToEnglishConverter/NepaliToEnglishConverter'
import ReactPieChart from 'components/ReactPieChart/ReactPieChart'
// import EmiAmortizationTable from './EmiAmortizationTable/EmiAmortizationTable'
import LabelInput from '../../../../components/Layout/LabelInput/LabelInput'
import { useEffect } from 'react'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'

// REMINDERE: Please for the love of yourself, implement formik and yup in the second phase.

const EMICalculator = () => {
    const [emiValues, setEmiValues] = useState({
        principal: 0,
        interest: 0,
        loanTime: {
            year: 0,
            month: 0,
        },
    })

    const [finalLoanEmi, setFinalLoanEmi] = useState(0)
    const [totalInterestPayable, setTotalInterestPayable] = useState(0)
    const [finalPrincipalAmount, setFinalPrincipalAmount] = useState(0)

    const [totalDurationInMonths, setTotalDurationInMonths] = useState(0)
    const [monthtlyInterestRate, setMonthlyInterestRate] = useState(0)

    const [errors, setErrors] = useState({
        principal: '',
        interest: '',
        loanTime: {
            year: '',
            month: '',
        },
    })

    const [errorFlag, setErrorFlag] = useState(false)

    const { t } = useTranslation()

    function roundToTwo(num) {
        return +(Math.round(num + 'e+2') + 'e-2')
    }

    useEffect(() => {
        if (
            !errors.interest &&
            !errors.loanTime.month &&
            !errors.loanTime.year &&
            !errors.principal
        ) {
            setErrorFlag(false)
        } else {
            setErrorFlag(true)
        }
    }, [errors])

    const checkErrors = () => {
        // REMINDER: Add more error conditions if needed.
        if (emiValues.interest > 100) {
            setErrors((prevState) => ({
                ...prevState,
                interest: 'errors.exceed100',
            }))
        } else {
            resetErrors('interest')
        }
        if (emiValues.loanTime.month > 11) {
            setErrors((prevState) => ({
                ...prevState,
                loanTime: {
                    ...prevState.loanTime,
                    month: 'errors.exceed11',
                },
            }))
        }
    }

    const handleCalculateButtonClick = (e) => {
        e.preventDefault()

        checkErrors()

        if (!errorFlag) {
            const r = parseFloat(emiValues?.interest) / 12 / 100

            let totalYearsInMonths = parseFloat(emiValues?.loanTime?.year)
                ? parseFloat(emiValues.loanTime.year) * 12
                : 0

            let totalDurationInMonths =
                parseFloat(emiValues.loanTime.month) + totalYearsInMonths

            let principalTimesRate = parseFloat(emiValues.principal) * r

            let ratePlusOnePow = Math.pow(1 + r, totalDurationInMonths)

            let loanEmi =
                (principalTimesRate * ratePlusOnePow) / (ratePlusOnePow - 1)

            let totalInterest =
                loanEmi * totalDurationInMonths - emiValues.principal

            // Pie Chart values
            setFinalLoanEmi(roundToTwo(loanEmi))
            setTotalInterestPayable(roundToTwo(totalInterest))
            setFinalPrincipalAmount(roundToTwo(parseFloat(emiValues.principal)))

            // Amortization Table values
            setTotalDurationInMonths(totalDurationInMonths)
            setMonthlyInterestRate(r)
        }
    }

    const resetErrors = (id) => {
        switch (id) {
            case 'principal':
                setErrors((prevState) => ({
                    ...prevState,
                    principal: '',
                }))
                break
            case 'interest':
                setErrors((prevState) => ({
                    ...prevState,
                    interest: '',
                }))
                break
            case 'month':
                setErrors((prevState) => ({
                    ...prevState,
                    loanTime: {
                        ...prevState.year,
                        month: '',
                    },
                }))
                break
            case 'year':
                setErrors((prevState) => ({
                    ...prevState,
                    loanTime: {
                        ...prevState.year,
                        year: '',
                    },
                }))
                break
            default:
                setErrors((prevState) => ({
                    ...prevState,
                    principal: '',
                    interest: '',
                    loanTime: {
                        month: '',
                        year: '',
                    },
                }))
        }
    }

    const isHorizontal = useMediaQuery('(max-width: 575px)')

    return (
        <>
            <div className="emi-calculator-container">
                <div className="emi-calculator-left-side-container">
                    <form className="emi-calculator-left-side-form">
                        <div className="emi-calculator-item-container">
                            <LabelInput
                                handleOnBlur={checkErrors}
                                labelName={t(
                                    'calculator.emiCalculator.loanAmount'
                                )}
                                inputType="number"
                                labelClassName="emi-calculator-input-description-text"
                                labelHtmlFor="emi-calculator-princial-input-field"
                                inputName="principal"
                                size="small"
                                inputValue={emiValues?.principal}
                                handleOnFocus={(e) =>
                                    resetErrors(e.target.name)
                                }
                                handleOnChange={(e) =>
                                    setEmiValues((prevState) => ({
                                        ...prevState,
                                        [e.target.name]: e.target.value,
                                    }))
                                }
                                orientation={isHorizontal && 'vertical'}
                            />
                        </div>
                        <div className="emi-calculator-item-container">
                            <LabelInput
                                handleOnBlur={checkErrors}
                                labelName={t(
                                    'calculator.emiCalculator.interestRate'
                                )}
                                inputType="number"
                                labelClassName="emi-calculator-input-description-text"
                                labelHtmlFor="emi-calculator-interest-rate-input-field"
                                inputName="interest"
                                size="small"
                                error={errors.interest}
                                inputValue={emiValues?.interest}
                                handleOnFocus={(e) =>
                                    resetErrors(e.target.name)
                                }
                                handleOnChange={(e) =>
                                    setEmiValues((prevState) => ({
                                        ...prevState,
                                        [e.target.name]: e.target.value,
                                    }))
                                }
                                orientation={isHorizontal && 'vertical'}
                            />
                        </div>
                        <div className="emi-calculator-item-container">
                            <p className="emi-calculator-input-description-text">
                                {t('calculator.emiCalculator.loanTerm')}
                            </p>
                            <div className="emi-calculator-input-fields-container">
                                <div className="emi-calculator-time-input-error-container">
                                    <div className="emi-calculator-time-input-container">
                                        <input
                                            onBlur={checkErrors}
                                            type="number"
                                            className="input-border-shadow-text small"
                                            id="emi-calculator-load-time-year-input-field"
                                            value={emiValues?.loanTime?.year}
                                            name="year"
                                            onFocus={(e) =>
                                                resetErrors(e.target.name)
                                            }
                                            onChange={(e) =>
                                                setEmiValues((prevState) => ({
                                                    ...prevState,
                                                    loanTime: {
                                                        ...prevState.loanTime,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    },
                                                }))
                                            }
                                        />
                                        <label
                                            className="emi-calculator-time-label"
                                            htmlFor="emi-calculator-loan-time-year-input-field"
                                        >
                                            {t('year')}
                                        </label>
                                    </div>
                                </div>
                                <div className="emi-calculator-time-input-error-container">
                                    <div className="emi-calculator-time-input-container">
                                        <input
                                            type="number"
                                            onBlur={checkErrors}
                                            className="input-border-shadow-text small"
                                            id="emi-calculator-load-time-month-input-field"
                                            value={emiValues?.loanTime?.month}
                                            onFocus={(e) =>
                                                resetErrors(e.target.name)
                                            }
                                            name="month"
                                            onChange={(e) => {
                                                setEmiValues((prevState) => ({
                                                    ...prevState,
                                                    loanTime: {
                                                        ...prevState.loanTime,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    },
                                                }))
                                            }}
                                        />
                                        <label
                                            className="emi-calculator-time-label"
                                            htmlFor="emi-calculator-loan-time-month-input-field"
                                        >
                                            {t('month')}
                                        </label>
                                    </div>
                                    <p className="emi-calculator-error">
                                        {t(errors.loanTime.month)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="emi-calculator-button-container">
                            <Button
                                description={t('buttons.calculateButton')}
                                handleButtonClick={handleCalculateButtonClick}
                                bgColor="grey"
                                size={isHorizontal ? 'widthHundred' : 'large'}
                                disabled={
                                    !(
                                        emiValues?.principal &&
                                        emiValues?.principal &&
                                        emiValues?.interest &&
                                        emiValues?.interest &&
                                        ((emiValues?.loanTime?.month &&
                                            emiValues?.loanTime?.month) ||
                                            (emiValues?.loanTime?.year &&
                                                emiValues?.loanTime?.year))
                                    )
                                }
                            />
                        </div>
                    </form>
                </div>
                <div className="emi-calculator-right-side-container">
                    <ReactPieChart
                        principalLoanAmount={finalPrincipalAmount}
                        totalInterestPayable={totalInterestPayable}
                        loanEmi={finalLoanEmi}
                        totalPayment={roundToTwo(
                            finalPrincipalAmount + totalInterestPayable
                        )}
                    />
                </div>
            </div>
        </>
    )
}

export default EMICalculator
