import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchDashboardFeaturedNews = () => {
    const updatedEndpoints = endpoints.news.featuredNews
    return http().get(updatedEndpoints)
}

export function useFetchDashboardFeaturedNews() {
    return useQuery(['fetchFeaturedNews'], () => fetchDashboardFeaturedNews(), {
        retry: 3,
    })
}
