import './largeRectangleAdvertisement.scss'

const LargeRectangleAdvertisement = ({ payload }) => {
    return payload?.map((item, index) => (
        <div className="advertisement-large-rectangle" key={index}>
            <a href={item?.urlLink} target="_blank" rel="noreferrer">
                <figure className="advertisement-large-rectangle-figure">
                    <img
                        src={item?.bgImg}
                        alt=""
                        className="advertisement-large-rectangle-img"
                    />
                </figure>
            </a>
        </div>
    ))
}

export default LargeRectangleAdvertisement
