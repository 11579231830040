import Title from 'components/Layout/Titles/Title'
import { useState } from 'react'
import { calculatorHeadings } from 'global/constants/dummyData'
import classNames from 'classnames'
import CalculatorContent from './CalculatorContent/CalculatorContent'

import './calculator.scss'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import ReactSelect from 'components/ReactSelect'

const Calculator = () => {
    const [activeHeading, setActiveHeading] = useState(0)
    const { t } = useTranslation()

    // NOTE: isTabSelector is a boolean value that determines whether the viewport size is the string query size or not.
    const isTabSelector = useMediaQuery('(max-width: 768px)')

    const calculatorHeadingsSelect = [
        { label: t('calculator.shareCalculator.title'), value: 0 },
        { label: t('calculator.emiCalculator.title'), value: 1 },
        { label: t('calculator.landCalculator.title'), value: 2 },
        { label: t('calculator.weightCalculator'), value: 3 },
        { label: t('calculator.temperatureCalculator'), value: 4 },
    ]

    return (
        <>
            <Title title={t('navbar.calculator')} bgColor="grey" />
            <div className="container">
                <div className="calculator-selector-container">
                    {!isTabSelector &&
                    calculatorHeadings &&
                    Array.isArray(calculatorHeadings) &&
                    calculatorHeadings.length > 0
                        ? calculatorHeadings.map((item, index) => (
                              <div
                                  key={index}
                                  className={classNames('tab-heading', {
                                      activeTab: activeHeading === index,
                                  })}
                                  onClick={() => setActiveHeading(index)}
                              >
                                  <p
                                      className={classNames(
                                          'tab-heading-text',
                                          {
                                              activeTab:
                                                  activeHeading === index,
                                          }
                                      )}
                                  >
                                      {t(item)}
                                  </p>
                              </div>
                          ))
                        : null}

                    {isTabSelector && (
                        <ReactSelect
                            options={calculatorHeadingsSelect}
                            selectDefaultValue={calculatorHeadingsSelect[0]}
                            height="100%"
                            width="100%"
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            color="#1D1D1F"
                            handleOnChange={(value) =>
                                setActiveHeading(value.value)
                            }
                            textColor="#a90000"
                            fontWeight="700"
                            singleValueFontSize="18px"
                        />
                    )}
                </div>
                <div className="heading-content">
                    <CalculatorContent activeCalculator={activeHeading} />
                </div>
            </div>
        </>
    )
}

export default Calculator
