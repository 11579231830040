import { useQuery } from 'react-query'
import { closePriceTypes } from 'global/constants/appConstants'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchTopDifference = (type = closePriceTypes.TOP_LOSS) => {
    const updatedEndpoints = endpoints.shareMarket.closePrice.byType.replace(
        '{type}',
        type
    )

    return http().get(updatedEndpoints)
}

export function useFetchTopDifference(type, options) {
    return useQuery(
        ['fetchTopDifference', type],
        () => fetchTopDifference(type),
        {
            retry: 3,
            ...options,
        }
    )
}
