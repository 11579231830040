/* eslint-disable no-unused-vars */
import './forex.scss'

import CurrencyConverter from 'components/Layout/Forex/CurrencyConverter'
import ExchangeRates from 'components/Layout/Forex/ExchangeRates/ExchangeRates'
import Title from 'components/Layout/Titles/Title'

import { useFetchForex } from 'hooks/forex/useFetchForex'
import { useTranslation } from 'react-i18next'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'
import Spinner from 'components/Spinner/Spinner'

const Forex = () => {
    const { t } = useTranslation()
    const { status: forexDataStatus, data: forexData } = useFetchForex()
    const options = {
        day: 'numeric',
        weekday: 'long',
        month: 'long',
        year: 'numeric',
    }

    const { day, weekday, month, year } = useGetDateBasedOnCurrentLang(
        forexData?.data?.payload?.date,
        options
    )

    return (
        <>
            <Title
                title={t('navbar.forex')}
                bgColor="grey"
                desc={`${t(
                    'lastUpdated'
                )} : ${day} ${month} ${year}, ${weekday}`}
            />
            <div className="container">
                <div className="forex-container">
                    {forexDataStatus === 'loading' ? (
                        <Spinner />
                    ) : (
                        <>
                            <CurrencyConverter
                                payload={
                                    forexData?.data?.payload?.rates
                                        ? forexData?.data?.payload?.rates
                                        : []
                                }
                            />
                            <div className="forex-exchange-rate-advertisement-container">
                                <ExchangeRates
                                    payload={
                                        forexData?.data?.payload?.rates
                                            ? forexData?.data?.payload?.rates
                                            : []
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default Forex
