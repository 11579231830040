import React, { Suspense, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'utils/queryClient'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'

import PublicRoutes from 'components/Routing/PublicRoutes'
import Loading from 'utils/helpers/loading/Loading'
import useStore from 'store/store'

import './App.scss'

import ScrollToTop from 'hooks/scrollToTop/ScrollToTop'
import Spinner from 'components/Spinner/Spinner'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import Pusher from 'pusher-js'

const App = () => {
    const [spinner, setSpinner] = useState(true)
    const [visible, setVisible] = useState(true)

    const firstTime = useStore((store) => store.firstTime)
    const addAdvertisement = useStore((state) => state.addAdvertisement)
    const setBreakingNews = useStore((state) => state.setBreakingNews)

    useEffect(
        () => addAdvertisement(),

        // eslint-disable-next-line
        []
    )

    let pusher = new Pusher('04457a2201324f0e34eb', {
        cluster: 'ap2',
    })

    let channel = pusher.subscribe('Media-App')
    channel.bind('breaking-news-event', function (data) {
        setBreakingNews()
    })

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1500)
        setTimeout(() => setVisible(false), 1500)
    }, [])

    if (spinner) {
        return <Loading spinner={spinner} visible={visible} />
    }

    return (
        <div className="app-container">
            <ErrorBoundary>
                <Suspense fallback={<Spinner />}>
                    <QueryClientProvider client={queryClient}>
                        <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            // REMINDER: Implement a global state for which theme it is, then utilize it to set the theme to either dark or light for the toaster component
                            theme="dark"
                        />
                        <Router>
                            <ScrollToTop />
                            <Switch>
                                <PublicRoutes firstTime={firstTime} />
                            </Switch>
                        </Router>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </Suspense>
            </ErrorBoundary>
        </div>
    )
}

export default App
