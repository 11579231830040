import './newsVideos.scss'

import { useState, useEffect } from 'react'
import { useFetchVideos } from 'hooks/videos/useFetchVideos'
import NewsHighlightVideos from './NewsHighlightVideos/NewsHighlightVideos'
import Carousel from 'utils/carousel/Carousel'
import Spinner from 'components/Spinner/Spinner'
import NewsVideosCarousel from './NewsVideosCarousel/NewsVideosCarousel'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'

const NewsVideos = () => {
    const { status: newsVideosStatus, data: newsVideosData } = useFetchVideos()

    const isMedium = useMediaQuery('(max-width: 768px)')
    const isMediumLarge = useMediaQuery('(max-width: 1024px)')
    const isLarge = useMediaQuery('(max-width: 1440px)')
    const isSmall = useMediaQuery('(max-width: 576px)')

    const [numberOfItems, setNumberOfItems] = useState(4)

    useEffect(() => {
        if (isSmall) {
            setNumberOfItems(1)
        } else if (isMedium) {
            setNumberOfItems(2)
        } else if (isMediumLarge) {
            setNumberOfItems(3)
        } else {
            setNumberOfItems(4)
        }
    }, [isMedium, isMediumLarge, isLarge, isSmall])

    return (
        <>
            {newsVideosStatus === 'success' ? (
                <>
                    <NewsHighlightVideos
                        payload={newsVideosData.payload.slice(0, 8)}
                    />
                    <div className="news-videos-carousel-container">
                        <Carousel
                            itemsNumber={numberOfItems}
                            out={1}
                            size={80}
                            arrowBgColor="dark"
                        >
                            {newsVideosData?.payload
                                ?.slice(8, newsVideosData.length)
                                ?.map((video, index) => (
                                    <NewsVideosCarousel
                                        payload={video}
                                        key={index}
                                    />
                                ))}
                        </Carousel>
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default NewsVideos
