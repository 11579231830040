import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchHoroscopesDetails = (sign, lang) => {
    const updatedEndpoints = endpoints.horoscopes.detail
        .replace('{horoscopeSign}', sign)
        .replace('{currentLanguage}', lang)

    return http().get(updatedEndpoints)
}

export function useFetchHoroscopesDetails(sign, lang) {
    return useQuery(
        ['fetchHoroscopesDetails', sign, lang],
        () => fetchHoroscopesDetails(sign, lang),
        { retry: 3 }
    )
}
