import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import './otp.scss'

import { authRoutes } from 'global/constants/appConstants'

import classNames from 'classnames'
import useStore from 'store/store'

import Button from 'components/Layout/Button/Button'
import OtpInput from 'components/OtpInput/OtpInput'
import Spinner from 'components/Spinner/Spinner'

import usePostResendOtp from 'hooks/userAuth/usePostResendOtp'
import usePostUserVerifyEmail from 'hooks/userAuth/usePostUserVerifyEmail'
import usePostVerifyForgotPasswordOtp from 'hooks/userAuth/usePostVerifyForgotPasswordOtp'

const Otp = ({ show, setCurrentPage }) => {
    const { t } = useTranslation()

    const forgotEmail = useStore((store) => store.forgotEmail)
    const signUpEmail = useStore((store) => store.userCredentials?.email)
    const resetForgotEmail = useStore((store) => store.resetForgotEmail)
    const resetUserCredentials = useStore((store) => store.resetUserCredentials)

    const [currentEmail, setCurrentEmail] = useState({
        email: '',
        type: '',
    })

    const [otpNumber, setOtpNumber] = useState()

    const { mutate: resendOtpFn } = usePostResendOtp()

    const {
        mutate: userVerifyEmailFn,
        isSuccess: userVerifyEmailSuccess,
        isLoading: userVerifyEmailLoading,
    } = usePostUserVerifyEmail()

    const {
        mutate: forgotPasswordEmailVerifyFn,
        isSuccess: forgotPasswordEmailSuccess,
        isLoading: forgotPasswordEmailLoading,
    } = usePostVerifyForgotPasswordOtp()

    useEffect(() => {
        setCurrentEmail((prevState) => ({
            ...prevState,
            email: forgotEmail || signUpEmail,
            type: forgotEmail
                ? 'forgotEmail'
                : signUpEmail
                ? 'signUpEmail'
                : '',
        }))
    }, [forgotEmail, signUpEmail])

    const handleResendOtp = (e) => {
        e.preventDefault()
        if (!currentEmail.email) {
            toast.error('There is no email provided!')
            return
        }

        const postData = {
            email: currentEmail.email,
        }
        resendOtpFn(postData)
    }

    const handleOtpSubmit = (e) => {
        e.preventDefault()
        if (!currentEmail.email) {
            toast.error('There is no email provided!')
            return
        }

        const postData = {
            email: currentEmail.email,
            otp: otpNumber,
        }

        if (currentEmail.type === 'forgotEmail') {
            forgotPasswordEmailVerifyFn(postData)
        } else if (currentEmail.type === 'signUpEmail') {
            userVerifyEmailFn(postData)
        }
    }

    useEffect(() => {
        if (userVerifyEmailSuccess) {
            resetUserCredentials()
            setCurrentPage(authRoutes.SIGNIN)
        }
        if (forgotPasswordEmailSuccess) {
            resetForgotEmail()
            setCurrentPage(authRoutes.PASSWORD_RESET)
        }

        // eslint-disable-next-line
    }, [userVerifyEmailSuccess, forgotPasswordEmailSuccess])

    const handleGoBackToSignIn = (e) => {
        e.preventDefault()
        resetForgotEmail()
        resetUserCredentials()
        setCurrentPage(authRoutes.SIGNIN)
    }

    return (
        <div
            className={classNames('otp-input-container', {
                show: show,
            })}
        >
            <h4 className="otp-heading">
                {t('authentication.otpVerification')}
            </h4>
            <p className="otp-description">
                {t('authentication.otpSentMobile')}
            </p>
            <OtpInput
                autoFocus
                isNumberInput
                length={4}
                className="otp-container"
                inputClassName="otp-input"
                onChangeOtp={(otp) => setOtpNumber(otp)}
            />
            <p className="otp-resend">
                {t('authentication.otpDidntGet')}{' '}
                <span className="otp-resend-bold" onClick={handleResendOtp}>
                    {t('authentication.resendOtp')}
                </span>
            </p>
            <div className="otp-buttons-container">
                {forgotPasswordEmailLoading || userVerifyEmailLoading ? (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={<Spinner isWhite={true} />}
                    />
                ) : (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={t('buttons.continue')}
                        disabled={!(otpNumber?.length === 4)}
                        handleButtonClick={handleOtpSubmit}
                    />
                )}
                <Button
                    size="widthHundred"
                    bgColor="grey"
                    description={t('authentication.goBackSignIn')}
                    handleButtonClick={handleGoBackToSignIn}
                />
            </div>
        </div>
    )
}

export default Otp

Otp.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
}
