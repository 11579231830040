import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchShareIndexGraph = (type, duration) => {
    if (type && duration) {
        const updatedEndpoints = endpoints.shareMarket.index.graph.typeDuration
            .replace('{typeName}', type)
            .replace('{durationTime}', duration)
        return http().get(updatedEndpoints)
    } else {
        const updatedEndpoints = endpoints.shareMarket.index.graph.base
        return http().get(updatedEndpoints)
    }
}

export function useFetchShareIndexGraph(type, duration, others) {
    return useQuery(
        ['fetchShareIndexGraph', type, duration, others],
        () => fetchShareIndexGraph(type, duration),
        {
            retry: 3,
            ...others,
        }
    )
}
