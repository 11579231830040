import './leaderboardAdvertisement.scss'

const LeaderboardAdvertisement = ({ payload }) => {
    return payload?.map((item, index) => (
        <a
            href={item?.urlLink}
            target="_blank"
            rel="noreferrer"
            className="leaderboard-link"
            key={index}
        >
            <figure className="leaderboard-figure">
                <img src={item?.bgImg} alt="leaderboard advertisement" />
            </figure>
        </a>
    ))
}

export default LeaderboardAdvertisement
