import React, { useState } from 'react'
import ReactSelect from 'components/ReactSelect'
import classNames from 'classnames'

import { ShareMarketChart } from 'components/Charts'
import { indexType, shareGraphDuration } from 'global/constants/appConstants'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'

import './shareChart.scss'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'

const ShareChart = ({ size = 'sm' }) => {
    const [duration, setDuration] = useState(shareGraphDuration.ONE_WEEK)
    const [currentType, setCurrentType] = useState(indexType.NEPSE)

    const { t } = useTranslation()

    const typeSelectors = [
        { label: 'NEPSE', value: indexType.NEPSE },
        { label: 'SENSITIVE', value: indexType.SENSITIVE },
        { label: 'FLOAT', value: indexType.FLOAT },
        { label: 'SENSITIVE_FLOAT', value: indexType.SENSITIVE_FLOAT },
    ]

    const durationSelectors = [
        {
            label: t('shareMarket.duration.oneWeek'),
            value: shareGraphDuration.ONE_WEEK,
        },
        {
            label: t('shareMarket.duration.oneMonth'),
            value: shareGraphDuration.ONE_MONTH,
        },
        {
            label: t('shareMarket.duration.threeMonths'),
            value: shareGraphDuration.THREE_MONTHS,
        },
        {
            label: t('shareMarket.duration.sixMonths'),
            value: shareGraphDuration.SIX_MONTHS,
        },
        {
            label: t('shareMarket.duration.oneYear'),
            value: shareGraphDuration.ONE_YEAR,
        },
    ]

    // Currying
    const handleDurationClick = (value) => (e) => setDuration(value)

    const handleTypeClick = (value) => setCurrentType(value?.value)
    const handleDurationMobileClick = (value) => setDuration(value?.value)

    const isMobileSize = useMediaQuery('(max-width: 768px)')

    return (
        <div className="share-chart-container">
            <div className="share-chart-heading-container">
                <ReactSelect
                    selectDefaultValue={{
                        label: 'NEPSE',
                        value: indexType.NEPSE,
                    }}
                    options={typeSelectors}
                    color="grey"
                    className="share-table-select"
                    height="32px"
                    width="165px"
                    handleOnChange={handleTypeClick}
                />
                <div className="share-chart-heading-duration-container">
                    {isMobileSize || size === 'sm' ? (
                        <ReactSelect
                            selectDefaultValue={durationSelectors[0]}
                            options={durationSelectors}
                            color="grey"
                            textColor="#1d1d1f"
                            className="share-table-select"
                            height="32px"
                            width="165px"
                            handleOnChange={handleDurationMobileClick}
                        />
                    ) : (
                        Array.isArray(durationSelectors) &&
                        durationSelectors?.length > 0 &&
                        durationSelectors.map((item, index) => (
                            <div
                                key={index}
                                onClick={handleDurationClick(item.value)}
                                className={classNames(
                                    'share-chart-heading-duration-item',
                                    {
                                        current: duration === item.value,
                                    }
                                )}
                            >
                                {item.label}
                            </div>
                        ))
                    )}
                </div>
            </div>
            <ShareMarketChart
                type={currentType || indexType.NEPSE}
                duration={duration || shareGraphDuration.ONE_MONTH}
                size={size}
            />
        </div>
    )
}

export default ShareChart

ShareChart.propTypes = {
    size: PropTypes.string,
}
