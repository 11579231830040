import { useQuery } from 'react-query'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchNewsPaginate = (paginateNumber, catName) => {
    if (catName && paginateNumber) {
        return http().get(
            endpoints.news.catPaginate
                .replace('{catName}', catName)
                .replace('{pageSize}', paginateNumber)
        )
    }

    return http().get(
        endpoints.news.paginate.replace('{pageSize}', paginateNumber)
    )
}

export function useFetchNewsPaginate(paginateNumber, catName) {
    return useQuery(
        ['fetchNewsPaginate', paginateNumber, catName],
        () => fetchNewsPaginate(paginateNumber, catName),
        { retry: 3 }
    )
}
