import './brokerComissionTable.scss'
import React from 'react'
import { PropTypes } from 'prop-types'
import { share } from 'global/constants/appConstants'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'

const BrokerComissionTable = ({
    type,
    typeOfInvestor,
    totalAmount,
    comissionAmount,
    sebonFee,
    dpCharge,
    totalAmountReceivable,
    totalAmountPayable,
    capitalGainsTax,
    costPricePerShare,
    profitLoss,
}) => {
    const { t, i18n } = useTranslation()

    function roundToThree(num) {
        return +(Math.round(num + 'e+3') + 'e-3')
    }

    const currentLang = i18n.language

    const valuesToRenderBuy = [
        {
            label: t('calculator.shareCalculator.totalAmount'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(totalAmount)
                    : EnglishToNepaliConverter(roundToThree(totalAmount)),
        },
        {
            label: t('calculator.shareCalculator.commission'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(comissionAmount)
                    : EnglishToNepaliConverter(roundToThree(comissionAmount)),
        },
        {
            label: t('calculator.shareCalculator.sebonFee'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(sebonFee)
                    : EnglishToNepaliConverter(roundToThree(sebonFee)),
        },
        {
            label: t('calculator.shareCalculator.dpCharge'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(dpCharge)
                    : EnglishToNepaliConverter(roundToThree(dpCharge)),
        },
        {
            label: t('calculator.shareCalculator.totalAmountPayable'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(totalAmountPayable)
                    : EnglishToNepaliConverter(
                          roundToThree(totalAmountPayable)
                      ),
        },
        {
            label: t('calculator.shareCalculator.costPricePerShare'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(costPricePerShare)
                    : EnglishToNepaliConverter(roundToThree(costPricePerShare)),
        },
    ]

    const valuesToRenderSell = [
        {
            label: t('calculator.shareCalculator.typeOfInvestor'),
            value:
                typeOfInvestor === 'individual-investor'
                    ? t('calculator.shareCalculator.individualInvestors')
                    : t('calculator.shareCalculator.institutionalInvestors'),
        },
        {
            label: t('calculator.shareCalculator.totalAmount'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(totalAmount)
                    : EnglishToNepaliConverter(roundToThree(totalAmount)),
        },
        {
            label: t('calculator.shareCalculator.commission'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(comissionAmount)
                    : EnglishToNepaliConverter(roundToThree(comissionAmount)),
        },
        {
            label: t('calculator.shareCalculator.sebonFee'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(sebonFee)
                    : EnglishToNepaliConverter(roundToThree(sebonFee)),
        },
        {
            label: t('calculator.shareCalculator.dpCharge'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(dpCharge)
                    : EnglishToNepaliConverter(roundToThree(dpCharge)),
        },
        {
            label: t('calculator.shareCalculator.totalReceivables'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(totalAmountReceivable)
                    : EnglishToNepaliConverter(
                          roundToThree(totalAmountReceivable)
                      ),
        },
        {
            label: t('calculator.shareCalculator.capitalGainsTax'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(capitalGainsTax)
                    : EnglishToNepaliConverter(roundToThree(capitalGainsTax)),
        },
        {
            label:
                parseFloat(profitLoss) > 0
                    ? t('calculator.shareCalculator.profit')
                    : t('calculator.shareCalculator.loss'),
            value:
                currentLang === lang.ENGLISH
                    ? roundToThree(profitLoss)
                    : EnglishToNepaliConverter(roundToThree(profitLoss)),
        },
    ]

    return (
        <section className="broker-comission-section-container">
            <div className="broker-comission-table-container">
                <div className="broker-comission-table-heading-container">
                    <h3>{t('calculator.shareCalculator.brokerCommission')}</h3>
                </div>
                <div className="broker-comission-table-content-container">
                    {type === share.SELL
                        ? valuesToRenderSell.map((item, index) => (
                              <div
                                  className="broker-comission-table-item"
                                  key={index}
                              >
                                  <p className="broker-comission-table-item-label">
                                      {item.label}
                                  </p>
                                  <p className="broker-comission-table-item-value">
                                      {item.value}
                                  </p>
                              </div>
                          ))
                        : valuesToRenderBuy.map((item, index) => (
                              <div
                                  className="broker-comission-table-item"
                                  key={index}
                              >
                                  <p className="broker-comission-table-item-label">
                                      {item.label}
                                  </p>
                                  <p className="broker-comission-table-item-value">
                                      {item.value}
                                  </p>
                              </div>
                          ))}
                </div>
            </div>
        </section>
    )
}

export default BrokerComissionTable

BrokerComissionTable.propTypes = {
    type: PropTypes.string,
    typeOfInvestor: PropTypes.string,
    totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    comissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    sebonFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    dpCharge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    totalAmountReceivable: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    totalAmountPayable: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    capitalGainsTax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    costPricePerShare: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    profitLoss: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
