import './dashboardListTemplate.scss'
// import DashboardListTemplateImage from './DashboardListTemplateImage/DashboardListTemplateImage'
import DashboardListTemplateItem from './DashboardListTemplateItem/DashboardListTemplateItem'
import DashboardListTemplateTitle from './DashboardListTemplateTitle/DashboardListTemplateTitle'
import DashboardListTemplateItemCheckbox from './DashboardListTemplateItem/DashboardListTemplateItemCheckbox'
// import { NavLink } from 'react-router-dom'
import { PropTypes } from 'prop-types'

const DashboardListTemplate = ({ payload, hasCheckbox, title, type }) => {
    const payload1 = payload.slice(0, 18)
    return (
        <div className="dashboard-list-template-container">
            {/* <NavLink to={payload.urlLink ? payload.urlLink : '/'}> */}
            <div className="dashboard-list-template-title-img-container">
                <DashboardListTemplateTitle title={title} />
                {/* <div className="dashboard-list-template-image-list-container">
                        <DashboardListTemplateImage image={img} />
                    </div> */}
            </div>
            {/* </NavLink> */}

            <div className="dashboard-list-template-list-container">
                {hasCheckbox ? (
                    <DashboardListTemplateItemCheckbox
                        payload={payload1}
                        type={type}
                    />
                ) : (
                    <DashboardListTemplateItem
                        payload={payload1}
                        type={type || 'magazine'}
                    />
                )}
            </div>
        </div>
    )
}

export default DashboardListTemplate

DashboardListTemplate.propTypes = {
    payload: PropTypes.array,
    title: PropTypes.string,
    hasCheckbox: PropTypes.bool,
    type: PropTypes.string,
}
