import './horoscopeCard.scss'
import { NavLink } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { routePaths } from 'global/constants/routePaths'
import { lang } from 'global/constants/appConstants'

const HoroscopeCard = ({ payload }) => {
    const { i18n } = useTranslation()

    return (
        <NavLink
            exact
            to={routePaths.horoscope.details.replace(
                '{horoscopeSign}',
                i18n.language === lang.ENGLISH ? payload.name : payload.name_np
            )}
            className="horoscope-card-container"
        >
            <figure className="horoscope-image-container">
                <img
                    className="horoscope-image"
                    src={payload.image}
                    alt="horoscope-images"
                />
            </figure>
            <div className="horoscope-main-content">
                <h4 className="horoscope-title">
                    {i18n.language === lang.ENGLISH
                        ? payload.name
                        : payload.name_np}
                </h4>
            </div>
        </NavLink>
    )
}

export default HoroscopeCard

HoroscopeCard.propTypes = {
    payload: PropTypes.object,
}
