import React from 'react'
import './placeholderAdvertisement.scss'
import { PropTypes } from 'prop-types'

const PlaceholderAdvertisement = ({ payload }) => {
    return (
        Array.isArray(payload) &&
        payload.map((item, index) => (
            <div className="placeholder-advertisement-container" key={index}>
                Advertisement block
            </div>
        ))
    )
}

export default PlaceholderAdvertisement

PlaceholderAdvertisement.propTypes = {
    payload: PropTypes.array,
}
