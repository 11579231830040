/* eslint-disable no-unused-vars */
import './shareCalculator.scss'
import ReactSelect from 'components/ReactSelect'
import { useState } from 'react'
import Button from 'components/Layout/Button/Button'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { share, lang } from 'global/constants/appConstants'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import LabelInput from '../../../../components/Layout/LabelInput/LabelInput'
import usePostShareCalculator from 'hooks/shareCalculator/usePostShareCalculator'
import Spinner from 'components/Spinner/Spinner'
import BrokerComissionTable from './BrokerComissionTable/BrokerComissionTable'
import { useEffect } from 'react'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'

const ShareCalculator = () => {
    const { t, i18n } = useTranslation()

    const [capitalGainsTaxOptions, setCapitalGainsTaxOptions] = useState([
        {
            value: 5,
            label:
                i18n.language === lang.ENGLISH
                    ? '5%'
                    : EnglishToNepaliConverter('5%'),
        },
        {
            value: 7.5,
            label:
                i18n.language === lang.ENGLISH
                    ? '7.5%'
                    : EnglishToNepaliConverter('7.5%'),
        },
    ])

    const {
        mutate: calculateFn,
        isLoading: calculateLoading,
        isSuccess: calculateSuccess,
        data: calculateData,
    } = usePostShareCalculator()

    const schema = object().shape({
        transactionType: string().required(t('errors.fieldRequired')),
        shareQuantity: string().required(t('errors.fieldRequired')),
        purchasePrice: string().required(t('errors.fieldRequired')),
        sellingPrice: string().when('transactionType', {
            is: share.SELL,
            then: string().required(t('errors.fieldRequired')),
        }),
        investorType: string().when('transactionType', {
            is: share.SELL,
            then: string().required(t('errors.fieldRequired')),
        }),
        capitalGainsTax: string().when('transactionType', {
            is: share.SELL,
            then: string().required(t('errors.fieldRequired')),
        }),
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (values.transactionType === share.BUY) {
            const postData = {
                type: values.transactionType,
                quantity: values.shareQuantity,
                buy_rate: values.purchasePrice,
            }
            calculateFn(postData)
        } else {
            const postData = {
                type: values.transactionType,
                quantity: values.shareQuantity,
                buy_rate: values.purchasePrice,
                sale_rate: values.sellingPrice,
                capital_gain_tax: values.capitalGainsTax,
            }
            calculateFn(postData)
        }
    }

    const { handleBlur, values, setFieldValue, errors, isValid, touched } =
        useFormik({
            validationSchema: schema,
            onSubmit: handleSubmit,
            initialValues: {
                transactionType: share.BUY,
                shareQuantity: '',
                purchasePrice: '',
                sellingPrice: '',
                investorType: 'individual-investor',
                capitalGainsTax: '5',
            },
            initialErrors: {
                transactionType: true,
                shareQuantity: true,
                purchasePrice: true,
                sellingPrice: true,
                investorType: true,
                capitalGainsTax: true,
            },
            enableReinitialize: true,
        })

    const typeOfInvestor = [
        {
            value: 'individual-investor',
            label: t('calculator.shareCalculator.individualInvestors'),
        },
        {
            value: 'insitutional-investor',
            label: t('calculator.shareCalculator.institutionalInvestors'),
        },
    ]

    const businessType = [
        { value: share.BUY, label: t('calculator.shareCalculator.buy') },
        { value: share.SELL, label: t('calculator.shareCalculator.sell') },
    ]

    useEffect(() => {
        if (values.investorType === 'individual-investor') {
            const newArr = [
                {
                    value: 5,
                    label:
                        i18n.language === lang.ENGLISH
                            ? '5%'
                            : EnglishToNepaliConverter('5%'),
                },
                {
                    value: 7.5,
                    label:
                        i18n.language === lang.ENGLISH
                            ? '7.5%'
                            : EnglishToNepaliConverter('7.5%'),
                },
            ]
            setCapitalGainsTaxOptions(newArr)
        } else {
            const newArr = [
                {
                    value: 10,
                    label:
                        i18n.language === lang.ENGLISH
                            ? '10%'
                            : EnglishToNepaliConverter('10%'),
                },
            ]
            setCapitalGainsTaxOptions(newArr)
        }
    }, [values.investorType, i18n.language])

    const isHorizontal = useMediaQuery('(max-width: 575px)')

    return (
        <section className="share-calculator-container">
            <div className="share-calculator-left-side-container">
                <form
                    onSubmit={handleSubmit}
                    className="share-calculator-left-side-form"
                >
                    <div className="share-calculator-item-container">
                        <p>{t('calculator.shareCalculator.transactionType')}</p>
                        <ReactSelect
                            options={businessType}
                            selectDefaultValue={businessType[0]}
                            height="50.63px"
                            width="100%"
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            color="#1D1D1F"
                            handleOnChange={(value) =>
                                setFieldValue('transactionType', value.value)
                            }
                            textColor="#1D1D1F"
                            fontWeight="400"
                            singleValueFontSize="18px"
                            isSearchable={false}
                        />
                    </div>
                    <LabelInput
                        inputType="number"
                        labelClassName="share-calculator-item-label"
                        labelHtmlFor="share-calculator-shareQuantity"
                        labelName={t('calculator.shareCalculator.shareNumber')}
                        size="small"
                        inputValue={values.shareQuantity}
                        touched={touched['share-calculator-shareQuantity']}
                        touchedError={errors.shareQuantity}
                        handleOnChange={(e) =>
                            setFieldValue('shareQuantity', e.target.value)
                        }
                        handleOnBlur={handleBlur}
                        orientation={isHorizontal && 'vertical'}
                    />
                    <LabelInput
                        inputType="number"
                        labelClassName="share-calculator-item-label"
                        labelHtmlFor="share-calculator-purchasePrice"
                        labelName={t(
                            'calculator.shareCalculator.purchasePrice'
                        )}
                        size="small"
                        inputValue={values.purchasePrice}
                        touched={touched['share-calculator-purchasePrice']}
                        touchedError={errors.purchasePrice}
                        handleOnChange={(e) =>
                            setFieldValue('purchasePrice', e.target.value)
                        }
                        handleOnBlur={handleBlur}
                        orientation={isHorizontal && 'vertical'}
                    />
                    {values.transactionType === share.SELL && (
                        <>
                            <LabelInput
                                inputType="number"
                                labelClassName="share-calculator-item-label"
                                labelHtmlFor="share-calculator-sellingPrice"
                                labelName={t(
                                    'calculator.shareCalculator.salePrice'
                                )}
                                size="small"
                                inputValue={values.sellingPrice}
                                touched={
                                    touched['share-calculator-sellingPrice']
                                }
                                touchedError={errors.sellingPrice}
                                handleOnChange={(e) =>
                                    setFieldValue(
                                        'sellingPrice',
                                        e.target.value
                                    )
                                }
                                handleOnBlur={handleBlur}
                                orientation={isHorizontal && 'vertical'}
                                isSearchable={false}
                            />
                            <div className="share-calculator-item-container">
                                <p>
                                    {t(
                                        'calculator.shareCalculator.typeOfInvestor'
                                    )}
                                </p>
                                <ReactSelect
                                    options={typeOfInvestor}
                                    selectDefaultValue={typeOfInvestor[0]}
                                    height="50.63px"
                                    width="100%"
                                    boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                                    color="#1D1D1F"
                                    handleOnChange={(value) =>
                                        setFieldValue(
                                            'investorType',
                                            value.value
                                        )
                                    }
                                    textColor="#1D1D1F"
                                    fontWeight="400"
                                    singleValueFontSize="18px"
                                    isSearchable={false}
                                />
                            </div>
                            <div className="share-calculator-item-container">
                                <p>
                                    {t(
                                        'calculator.shareCalculator.capitalGainsTax'
                                    )}
                                </p>
                                {values.investorType ===
                                'individual-investor' ? (
                                    <ReactSelect
                                        options={capitalGainsTaxOptions}
                                        selectDefaultValue={{
                                            value: 5,
                                            label:
                                                i18n.language === lang.ENGLISH
                                                    ? '5%'
                                                    : EnglishToNepaliConverter(
                                                          '5%'
                                                      ),
                                        }}
                                        height="50.63px"
                                        width="100%"
                                        boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                                        color="#1D1D1F"
                                        handleOnChange={(value) =>
                                            setFieldValue(
                                                'capitalGainsTax',
                                                value.value
                                            )
                                        }
                                        textColor="#1D1D1F"
                                        fontWeight="400"
                                        singleValueFontSize="18px"
                                        isSearchable={false}
                                    />
                                ) : (
                                    <>
                                        <ReactSelect
                                            options={capitalGainsTaxOptions}
                                            selectDefaultValue={{
                                                value: 10,
                                                label:
                                                    i18n.language ===
                                                    lang.ENGLISH
                                                        ? '10%'
                                                        : EnglishToNepaliConverter(
                                                              '10%'
                                                          ),
                                            }}
                                            height="50.63px"
                                            width="100%"
                                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                                            color="#1D1D1F"
                                            handleOnChange={(value) =>
                                                setFieldValue(
                                                    'capitalGainsTax',
                                                    value.value
                                                )
                                            }
                                            textColor="#1D1D1F"
                                            fontWeight="400"
                                            singleValueFontSize="18px"
                                            isSearchable={false}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    <div className="share-calculator-item-container">
                        <p></p>
                        <Button
                            disabled={!isValid}
                            size="widthHundred"
                            bgColor="grey"
                            description={t('buttons.calculateButton')}
                        />
                    </div>
                </form>
            </div>
            {calculateLoading ? (
                <Spinner />
            ) : (
                <div className="share-calculator-right-side-container">
                    {calculateSuccess ? (
                        <BrokerComissionTable
                            type={values.transactionType}
                            typeOfInvestor={values?.investorType}
                            totalAmount={
                                values.transactionType === share.BUY
                                    ? calculateData?.payload?.buy_amount
                                    : calculateData?.payload?.sale_amount
                            }
                            comissionAmount={
                                calculateData?.payload?.commission_amount
                            }
                            sebonFee={calculateData?.payload?.sebo_commission}
                            dpCharge={calculateData?.payload?.dp_amount}
                            totalAmountReceivable={
                                calculateData?.payload?.total_receivable_amount
                            }
                            totalAmountPayable={
                                calculateData?.payload?.total_buy_amount
                            }
                            capitalGainsTax={
                                calculateData?.payload?.capital_gain_tax_amount
                            }
                            costPricePerShare={
                                calculateData?.payload?.actual_cost_per_share
                            }
                            profitLoss={calculateData?.payload?.profit_or_loss}
                        />
                    ) : (
                        <BrokerComissionTable
                            type="..."
                            typeOfInvestor="..."
                            totalAmount="0"
                            comissionAmount="0"
                            sebonFee="0"
                            dpCharge="0"
                            totalAmountReceivable="0"
                            totalAmountPayable="0"
                            capitalGainsTax="0"
                            costPricePerShare="0"
                            profitLoss="0"
                        />
                    )}

                    {/* <p className="share-calculator-caveat-text">
                        {t('calculator.shareCalculator.shareCaveatText')}
                    </p> */}
                </div>
            )}
        </section>
    )
}

export default ShareCalculator
