import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

const postResetPassowrd = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.auth.resetPassword
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostResetPassword() {
    return useMutation(postResetPassowrd, {
        onSuccess: () => {
            toast.success(
                'You have successfully reset your password! Please login again!'
            )
        },
        onError: (err) => {
            Object.keys(err.errors).map((item, index) =>
                toast.error(err.errors[item][0])
            )
        },
    })
}
