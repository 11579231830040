import './dashboardListTemplateTitle.scss'

import { HiArrowSmRight } from 'react-icons/hi'

const DashboardListTemplateTitle = ({ title, urlLink }) => {
    return (
        <div className="dashboard-list-template-title-container">
            <p className="dashboard-list-template-title">
                {title}
                <span className="dashboard-list-template-title-arrow-container">
                    <HiArrowSmRight className="dashboard-list-template-title-arrow" />
                </span>
            </p>
        </div>
    )
}

export default DashboardListTemplateTitle
