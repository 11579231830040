import { PropTypes } from 'prop-types'

const UnicodeIframe = ({ src }) => {
    return (
        <div className="iframe-container">
            <iframe
                width="100%"
                height="400"
                frameborder="0"
                border="no"
                scrolling="no"
                marginwidth="0"
                marginheight="0"
                allowtransparency="true"
                title="unicode-section"
                src={src}
            ></iframe>
            <span style={{ color: 'gray', fontSize: '8px', textAlign: 'left' }}>
                ©{' '}
                <a
                    href="https://www.ashesh.com.np/nepali-unicode.php"
                    title="Nepali unicode"
                    target="_top"
                    style={{ textDecoration: 'none', color: 'gray' }}
                >
                    Nepali Unicode
                </a>
            </span>
        </div>
    )
}
export default UnicodeIframe

UnicodeIframe.propTypes = {
    src: PropTypes.string.isRequired,
}
