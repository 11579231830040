import React from 'react'
import { components } from 'react-select'

import './components.scss'

const Components = {
    Option: ({ data, ...props }) => {
        return (
            data && (
                <components.Option className="input-select" {...props}>
                    <div className="input-selector">
                        {data.icon && (
                            <img
                                src={data.icon}
                                alt="Icon"
                                className="selector-flag"
                            />
                        )}
                        <span>{data.label}</span>
                    </div>
                </components.Option>
            )
        )
    },
    SingleValue: ({ data, ...props }) => {
        return (
            data && (
                <components.SingleValue {...props}>
                    <div className="input-selector">
                        {data.icon && (
                            <img
                                src={data.icon}
                                alt="Icon"
                                className="selector-flag"
                            />
                        )}
                        <span>{data.label}</span>
                    </div>
                </components.SingleValue>
            )
        )
    },
}

export default Components
