import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchHolidays = (currentLang) => {
    const updatedEndpoints =
        endpoints.calendar.holidays.basedOnLanguage.replace(
            '{currentLang}',
            currentLang
        )
    return http().get(updatedEndpoints)
}

export function useFetchHolidays(currentLang) {
    return useQuery(
        ['fetchHolidays', currentLang],
        () => fetchHolidays(currentLang),
        {
            retry: 3,
        }
    )
}
