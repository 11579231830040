import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchEvents = (currentLang, nepaliMonth) => {
    const updatedEndpoints = endpoints.calendar.events.basedOnNepaliMonthAndLang
        .replace('{currentLang}', currentLang)
        .replace('{monthNumber}', nepaliMonth)
    return http().get(updatedEndpoints)
}

export function useFetchEvents(currentLang, nepaliMonth) {
    return useQuery(
        ['fetchEvents', currentLang, nepaliMonth],
        () => fetchEvents(currentLang, nepaliMonth),
        { retry: 3 }
    )
}
