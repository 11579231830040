import './mostRead.scss'

import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { NavLink } from 'react-router-dom'
import { routePaths } from 'global/constants/routePaths'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const MostRead = ({
    title,
    newsAgency,
    newsAgencyIcon,
    rank,
    id,
    newsAgencyLink,
}) => {
    const { i18n } = useTranslation()

    return (
        <div className="item-container">
            <NavLink
                exact
                to={routePaths.news.details.replace('{idNumber}', id)}
                className="item-link"
            >
                <h3 className="rank-number">
                    {i18n.language === lang.NEPALI
                        ? EnglishToNepaliConverter(rank)
                        : rank}
                </h3>
            </NavLink>
            <div className="item-details">
                <NavLink
                    exact
                    to={routePaths.news.details.replace('{idNumber}', id)}
                    className="item-link"
                >
                    <h4 className="item-title">{title}</h4>
                </NavLink>
                <div className="item-bottom-section">
                    <a
                        href={newsAgencyLink}
                        target="_blank"
                        rel="noreferrer"
                        className="item-link"
                    >
                        <p className="item-bottom-section-desc">
                            <span>
                                {newsAgencyIcon && (
                                    <img
                                        src={replaceHttpWithHttps(newsAgencyIcon)}
                                        className="item-bottom-section-icon"
                                        alt="Icon of concerned news agency"
                                    />
                                )}
                            </span>
                            {newsAgency}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MostRead
