import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

const putLikeSingleNews = (newsId, likeData) => {
    if (newsId && likeData) {
        const updatedEndpoints = endpoints.news.like.replace('{id}', newsId)
        return http().put(updatedEndpoints, likeData)
    }
}

export default function usePutLikeSingleNews(others) {
    return useMutation(putLikeSingleNews, {
        onSuccess: () => {
            toast.success('You have Liked successfully!')
        },
        onError: (err) => {
            Object.keys(err.errors).map((item, index) =>
                toast.error(err.errors[item][0])
            )
        },
        ...others,
    })
}
