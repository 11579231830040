import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchClosePrice = (symbol = '', date = '', paginationNumber) => {
    const updatedEndpoints =
        symbol && date
            ? endpoints.shareMarket.closePrice.byDateSymbolPaginate
                  .replace('{date}', date)
                  .replace('{symbol}', symbol)
            : symbol
            ? endpoints.shareMarket.closePrice.bySymbol.replace(
                  '{symbol}',
                  symbol
              )
            : date
            ? endpoints.shareMarket.closePrice.byDate.replace('{date}', date)
            : endpoints.shareMarket.closePrice.base

    return http().get(
        updatedEndpoints.replace(
            '{paginateNumber}',
            paginationNumber ? paginationNumber : '30'
        )
    )
}

export function useFetchClosePrice(
    symbol = '',
    date = '',
    paginationNumber = 30,
    options
) {
    return useQuery(
        ['fetchClosePrice'],
        () => fetchClosePrice(symbol, date, paginationNumber),
        {
            retry: 3,
            ...options,
        }
    )
}
