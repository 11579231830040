import create from 'zustand'

import { firstTimeSlice } from './firstTimeSlice'
import { darkModeSlice } from './darkModeSlice'
import { advertisementSlice } from './advertisementSlice'
import { breakingNewsSlice } from './breakingNewsSlice'
import { signUpUserNameSlice } from './signUpUserNameSlice'
import { forgotPasswordEmailSlice } from './forgotPasswordEmailSlice'
import { userDetailsSlice } from './userDetailsSlice'
import { currentLanguageSlice } from './currentLanguageSlice'

const useStore = create((set, get) => ({
    ...firstTimeSlice(set, get),
    ...darkModeSlice(set, get),
    ...advertisementSlice(set, get),
    ...breakingNewsSlice(set, get),
    ...signUpUserNameSlice(set, get),
    ...forgotPasswordEmailSlice(set, get),
    ...userDetailsSlice(set, get),
    ...currentLanguageSlice(set, get),
}))

export default useStore
