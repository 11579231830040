import './thisMonthHoliday.scss'

import React from 'react'
import { PropTypes } from 'prop-types'
import { nepaliMonths } from 'global/constants/dummyData'

const ThisMonthHoliday = ({ payload }) => {
    return (
        <div className="this-month-holiday-container">
            {payload.map((item, index) => (
                <div key={index}>
                    <h3 className="this-month-holiday-month-heading">
                        {nepaliMonths[index]}
                    </h3>
                    <>
                        {item.map((holiday, index) => (
                            <div
                                className="this-month-holiday-content-container"
                                key={index}
                            >
                                <p className="this-month-holiday-date">{`${
                                    holiday.date ? holiday.date : ''
                                }`}</p>
                                <p className="this-month-holiday-description">
                                    {holiday.holiday_text
                                        ? holiday.holiday_text
                                        : ''}
                                </p>
                            </div>
                        ))}
                    </>
                </div>
            ))}
        </div>
    )
}

export default ThisMonthHoliday

ThisMonthHoliday.propTypes = {
    payload: PropTypes.array,
}
