import AdToBsConveter from './AdToBsConveter'
import BsToAdConveter from './BsToAdConverter'
const DateContent = ({ active }) => {
    switch (active) {
        case 0:
            return <AdToBsConveter />
        case 1:
            return <BsToAdConveter />
        default:
            return <AdToBsConveter />
    }
}
export default DateContent
