import React from 'react'
import './leftNewsSinglePage.scss'

import Button from 'components/Layout/Button/Button'
import CommentSection from 'components/Layout/CommentSection/CommentSection'

import { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import ConvertToRelativeTime from 'utils/ConvertToRelativeTime'

import usePutCommentSingleNews from 'hooks/news/usePutCommentSingleNews'

import token from 'utils/token'
import Spinner from 'components/Spinner/Spinner'
import ShareIcons from './ShareIcons'

import Authentication from 'pages/Authentication/index'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const LeftNewsSinglePage = ({
    newsArticleData,
    newsArticleStatus,
    newsArticleRefetchData,
    newsArticleId,
}) => {
    const [commentText, setCommentText] = useState('')

    const refAuth = useRef()

    const {
        mutate: putCommentSingleNews,
        isSuccess: putCommentSingleNewsSuccess,
    } = usePutCommentSingleNews()

    const { t } = useTranslation()

    useEffect(() => {
        if (putCommentSingleNewsSuccess) {
            setCommentText('')
            newsArticleRefetchData()
        }

        // eslint-disable-next-line
    }, [putCommentSingleNewsSuccess])

    const handleInputTextChange = (e) => {
        setCommentText(e.target.value)
    }

    const handlePutCommentButtonClick = (e) => {
        e.preventDefault()
        if (token.getUserToken()) {
            const putData = {
                newsId: newsArticleId,
                comment: { comment: commentText },
            }
            putCommentSingleNews(putData)
        } else {
            refAuth.current.open()
        }
    }

    const handleOnBlur = (e) => {
        setCommentText(e.target.value.trim())
    }

    return newsArticleStatus === 'loading' ? (
        <Spinner />
    ) : (
        <>
            <Authentication authRef={refAuth} />
            <div className="news-single-page-left-side-container">
                <div className="news-single-page-news-details-container">
                    <div className="news-single-page-news-title-container">
                        <h2 className="news-single-page-left-side-title">
                            {newsArticleData?.payload?.title}
                        </h2>
                    </div>
                    <div className="news-single-page-left-side-description-container">
                        {newsArticleData?.payload?.image && (
                            <figure className="news-single-page-left-side-description-figure">
                                <img
                                    src={replaceHttpWithHttps(newsArticleData?.payload?.image)}
                                    alt=""
                                />
                            </figure>
                        )}
                        <div
                            className="news-single-page-left-side-description-news-agency-date-share-container"
                            id="share-section"
                        >
                            <div className="news-single-page-left-side-description-news-agency-date-contianer">
                                <div className="news-single-page-left-side-description-news-agency-container">
                                    <figure className="news-single-page-left-side-description-news-agency-figure">
                                        <img
                                            src={replaceHttpWithHttps(newsArticleData?.payload?.channel_icon)}
                                            alt=""
                                            className="news-single-page-left-side-description-news-agency-img"
                                        />
                                    </figure>
                                    <a
                                        href={
                                            newsArticleData?.payload
                                                ?.Putable_type
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <p
                                            className="news-single-page-left-side-description-news-agency-name"
                                            title={`Go to ${newsArticleData?.payload?.channel_name}'s website`}
                                        >
                                            {
                                                newsArticleData?.payload
                                                    ?.channel_name
                                            }
                                        </p>
                                    </a>
                                </div>
                                .
                                <div
                                    className="news-single-page-left-side-description-date-container"
                                    title={
                                        new Date(
                                            newsArticleData?.payload?.created_at
                                        )
                                    }
                                >
                                    {ConvertToRelativeTime(
                                        new Date(
                                            newsArticleData?.payload?.created_at
                                        )
                                    )}
                                </div>
                            </div>
                            <span className="news-single-page-share-icons-section">
                                <p className="news-single-page-share-icons-text">
                                    Share it:
                                </p>
                                <ShareIcons />
                            </span>
                        </div>
                        <p className="news-single-page-left-side-description">
                            {newsArticleData?.payload?.description}
                        </p>
                    </div>
                    <div className="news-single-page-left-side-news-details-redirect-button">
                        <Button
                            size="large"
                            center={true}
                            description={t('buttons.newsSingleArticle')}
                            bgColor="grey"
                            handleButtonClick={(e) =>
                                window.open(
                                    newsArticleData?.payload?.link,
                                    '_blank'
                                )
                            }
                        />
                    </div>
                </div>
                <div className="news-single-page-left-side-comment-box-container">
                    <div className="news-single-page-left-side-comment-box-title-container">
                        <p className="news-single-page-left-side-comment-box-title">
                            {t('feedback')}
                        </p>
                    </div>
                    <form
                        action=""
                        className="news-single-page-left-side-comment-box-form"
                        id="comment-section"
                    >
                        <textarea
                            className="news-single-page-left-side-comment-box-textarea"
                            value={commentText}
                            onChange={handleInputTextChange}
                            onBlur={handleOnBlur}
                        ></textarea>
                        <div className="news-single-page-left-side-comment-box-button-container">
                            <Button
                                size="large"
                                bgColor="grey"
                                description={t(
                                    'buttons.newsSingleArticleComment'
                                )}
                                handleButtonClick={handlePutCommentButtonClick}
                                disabled={
                                    commentText.trim() !== '' ? false : true
                                }
                            />
                        </div>
                    </form>
                </div>
                <div className="news-single-page-left-side-comment-section-container">
                    <CommentSection
                        payload={newsArticleData?.payload?.comments}
                    />
                </div>
            </div>
        </>
    )
}

export default LeftNewsSinglePage
