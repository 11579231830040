import './newsHighlightVideos.scss'

import VideoPlayButton from 'components/VideoPlayButton/VideoPlayButton'
import { PropTypes } from 'prop-types'
import { useRef, useState } from 'react'
import TVLists from 'pages/TVLists'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const NewsHighlightVideos = ({ payload }) => {
    const [videoUrl, setVideoUrl] = useState()
    const videoRef = useRef()

    const handleClick = (e, urlLink) => {
        setVideoUrl(urlLink)
        videoRef.current.open()
    }

    return (
        <>
            <TVLists innerRef={videoRef} streamUrl={videoUrl} />
            <div className="news-highlight-videos-container">
                {payload?.map((video, index) => (
                    <div
                        className="news-highlight-video"
                        key={index}
                        onClick={(e) => handleClick(e, video?.url)}
                        title={video?.title}
                    >
                        <figure className="news-highlight-video-image-figure">
                            <img
                                src={replaceHttpWithHttps(video?.imageUrl)}
                                alt=""
                                className="news-highlight-video-image"
                            />
                            <VideoPlayButton size="large" />
                        </figure>
                        <div className="news-highlight-video-bottom-container">
                            <h3 className="news-highlight-video-title">
                                {video?.title}
                            </h3>
                            <div className="news-highlight-video-news-agency-container">
                                <img
                                    src={video?.channel_icon}
                                    alt=""
                                    className="news-highlight-video-news-agency-icon"
                                />
                                <p className="news-highlight-video-news-agency-name">
                                    {video?.channel}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default NewsHighlightVideos

NewsHighlightVideos.propTypes = {
    payload: PropTypes.array,
}
