import './shareCard.scss'
import classNames from 'classnames'
import { BsFillTriangleFill } from 'react-icons/bs'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'

const ShareCard = ({
    indexName,
    amount,
    changedAmountNum,
    changedAmountPercent,
    result,
}) => {
    const [sign, setSign] = useState('')

    useEffect(() => {
        if (result === 'profit') {
            setSign('+')
        } else if (result === 'loss') {
            setSign('')
        } else setSign('')

        // eslint-disable-next-line
    }, [])

    const { i18n } = useTranslation()

    return (
        <div
            className={classNames('share-card-container', {
                green: result === 'profit',
                red: result === 'loss',
                blue: result === 'constant',
            })}
        >
            <div className="share-card-index-name-triangle-container">
                <p className="share-card-index-name">{indexName}</p>
                <div className="share-card-triangle-container">
                    <BsFillTriangleFill className="share-card-triangle-icon" />
                </div>
            </div>
            <p className="share-card-main-amount">
                {i18n.language === lang.ENGLISH
                    ? new Intl.NumberFormat('en-IN').format(amount)
                    : EnglishToNepaliConverter(
                          new Intl.NumberFormat('en-IN').format(amount)
                      )}
            </p>
            <div className="share-card-changed-amount-container">
                <p className="share-card-changed-amount-numeric">
                    {sign +
                        (i18n.language === lang.ENGLISH
                            ? parseFloat(
                                  new Intl.NumberFormat('en-IN').format(
                                      changedAmountNum
                                  )
                              ).toFixed(2)
                            : String(
                                  EnglishToNepaliConverter(
                                      parseFloat(
                                          new Intl.NumberFormat('en-IN').format(
                                              changedAmountNum
                                          )
                                      ).toFixed(2)
                                  )
                              ))}
                </p>
                <p className="share-card-changed-amount-percent">
                    {sign +
                        (i18n.language === lang.ENGLISH
                            ? parseFloat(
                                  new Intl.NumberFormat('en-IN').format(
                                      changedAmountPercent
                                  )
                              ).toFixed(2) + '%'
                            : String(
                                  EnglishToNepaliConverter(
                                      parseFloat(
                                          new Intl.NumberFormat('en-IN').format(
                                              changedAmountPercent
                                          )
                                      ).toFixed(2)
                                  )
                              ) + '%')}
                </p>
            </div>
        </div>
    )
}

export default ShareCard
