import './tvlists.scss'
import Modal from 'components/Modal/Modal'
import ReactPlayer from 'react-player'
import { PropTypes } from 'prop-types'
import { AiOutlineClose } from 'react-icons/ai'

const TVLists = ({ innerRef, streamUrl }) => {
    const handleCloseButtonClick = () => {
        innerRef.current.close()
    }
    return (
        <Modal fade={true} ref={innerRef}>
            <div className="tv-lists-container">
                <AiOutlineClose
                    onClick={handleCloseButtonClick}
                    className="close-button"
                />
                <section className="tv-container">
                    <ReactPlayer
                        url={streamUrl}
                        controls={true}
                        width="100%"
                        height="100%"
                        playing
                        muted
                        className="tv-lists-react-player"
                    />
                </section>
            </div>
        </Modal>
    )
}

export default TVLists

TVLists.propType = {
    innerRef: PropTypes.object,
    streamUrl: PropTypes.string,
}
