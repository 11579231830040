import './gridImage.scss'
import { PropTypes } from 'prop-types'

const GridImage = ({ image, title, handleChange }) => {
    return (
        <label className="grid-image">
            <input type="checkbox" onChange={handleChange} />
            <figure>
                <img src={image} alt={title} />
                <figcaption>{title}</figcaption>
            </figure>
            <span className="checked-icon">
                <i className="las la-check"></i>
            </span>
        </label>
    )
}

export default GridImage

GridImage.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    handleChange: PropTypes.func,
}
