import { useState, useEffect } from 'react'
import 'nepali-datepicker-reactjs/dist/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'components/ReactSelect'
import './AdToBsConveter.scss'

import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import NepaliDate from 'nepali-date-converter'
import {
    dateConverterDataMonthDay,
    dateConverterDataYear,
    dateConverterMonthEn,
    dateConverterMonthEnShort,
} from 'global/constants/dummyData'

const AdToBsConveter = () => {
    const { i18n, t } = useTranslation()

    const shouldMonthShort = useMediaQuery('(max-width: 768px)')

    const [userSelectedDate, setUserSelectedDate] = useState({
        day: new Date().toLocaleDateString('en-US', { day: 'numeric' }),
        month: new Date().toLocaleDateString('en-US', { month: 'numeric' }) - 1,
        year: new Date().toLocaleDateString('en-US', { year: 'numeric' }),
    })

    const [resultDate, setResultDate] = useState(
        `${new NepaliDate(new Date()).format('DD, MMMM YYYY', i18n.language)}`
    )

    useEffect(() => {
        try {
            setResultDate(
                `${new NepaliDate(
                    new Date(
                        userSelectedDate.year,
                        userSelectedDate.month,
                        userSelectedDate.day
                    )
                ).format('DD MMMM YYYY', i18n.language)}`
            )
        } catch (err) {
            // do nothing
        }

        // eslint-disable-next-line
    }, [userSelectedDate, i18n.language])

    return (
        <div className="date-converter-container">
            <div className="date-container">
                <div className="date-left-container">
                    <div className="date-left-select-container">
                        <ReactSelect
                            color="#1D1D1F"
                            className="date-converter-select"
                            classNamePrefix="date-converter-select-prefix"
                            handleOnChange={(value) =>
                                setUserSelectedDate((prevState) => ({
                                    ...prevState,
                                    day: value.value,
                                }))
                            }
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            selectDefaultValue={{
                                label: userSelectedDate.day,
                                value: userSelectedDate.day,
                            }}
                            options={
                                dateConverterDataMonthDay[
                                    userSelectedDate.month
                                ].day_en
                            }
                            isSearchable={false}
                        />
                        <ReactSelect
                            color="#1D1D1F"
                            className="date-converter-select"
                            handleOnChange={(value) =>
                                setUserSelectedDate((prevState) => ({
                                    ...prevState,
                                    month: value.value,
                                }))
                            }
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            classNamePrefix="date-converter-select-prefix"
                            selectDefaultValue={{
                                label: shouldMonthShort
                                    ? dateConverterMonthEnShort[
                                          userSelectedDate.month
                                      ].label
                                    : dateConverterMonthEn[
                                          userSelectedDate.month
                                      ].label,
                                value: userSelectedDate.month,
                            }}
                            options={
                                shouldMonthShort
                                    ? dateConverterMonthEnShort
                                    : dateConverterMonthEn
                            }
                            isSearchable={false}
                        />
                        <ReactSelect
                            color="#1D1D1F"
                            className="date-converter-select"
                            handleOnChange={(value) =>
                                setUserSelectedDate((prevState) => ({
                                    ...prevState,
                                    year: value.value,
                                }))
                            }
                            boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                            classNamePrefix="date-converter-select-prefix"
                            selectDefaultValue={{
                                label: userSelectedDate.year,
                                value: userSelectedDate.year,
                            }}
                            options={dateConverterDataYear.en}
                            isSearchable={false}
                        />
                    </div>
                    <p className="date-left-from-text">
                        {t('dateConverter.from')}
                    </p>
                </div>
                <div className="date-right-container">
                    <div className="date-col-upper">
                        <div htmlFor="date-bs" className="date-label">
                            <p className="bs-paragraph">
                                {t('dateConverter.bs')}
                            </p>
                        </div>
                        <div className="result-container">
                            <p className="converted-date-paragraph">
                                {resultDate}
                            </p>
                        </div>
                    </div>
                    <div className="date-col-lower">
                        <p className="to-paragraph">{t('dateConverter.to')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdToBsConveter
