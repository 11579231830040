import http from 'utils/http'
import { endpoints } from 'global/endpoints'

export const breakingNewsSlice = (set, get) => ({
    checkBreakingNews: false,
    setBreakingNews: () => set({ checkBreakingNews: true }),
    resetBreakingNews: () => set({ checkBreakingNews: false }),
    breakingNewsData: '',
    setBreakingNewsData: () => {
        const updatedEndpoints = endpoints.news.breakingNews.base
        const response = http().get(updatedEndpoints)

        response
            .then((data) =>
                set({
                    breakingNewsData: data?.payload,
                })
            )
            .catch((err) => console.log(err))
    },
})
