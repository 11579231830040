import './title.scss'

import { NavLink } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const Title = ({ title, urlLink, bgColor, desc }) => {
    const { t } = useTranslation()
    return (
        <section
            className={classNames('title-section', {
                'width-125': bgColor === 'grey',
            })}
        >
            <header
                className={classNames('title-header', {
                    'bg-grey': bgColor === 'grey',
                    desc: desc,
                })}
            >
                <h2 className="title-title">{title ? title : 'No title'}</h2>
                <p className="title-desc">{desc ? desc : ''}</p>
                {urlLink && (
                    <NavLink to={urlLink} className="title-see-all-tag">
                        {t('seeAll')}
                    </NavLink>
                )}
            </header>
        </section>
    )
}

export default Title

Title.propTypes = {
    title: PropTypes.string.isRequired,
    urlLink: PropTypes.string,
    bgColor: PropTypes.string,
    desc: PropTypes.string,
}
