import { useState } from 'react'
import DateContent from 'pages/DateConverter/DateConverterContent/DateContent'
import { useTranslation } from 'react-i18next'
import './tabsconveter.scss'
import classNames from 'classnames'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import ReactSelect from 'components/ReactSelect/ReactSelect'

const TabsConveter = () => {
    const [activeHeading, setActiveHeading] = useState(0)
    const { t } = useTranslation()

    const dateHeading = ['dateConverter.adToBs', 'dateConverter.bsToAd']

    const isTabSelector = useMediaQuery('(max-width:768px)')

    const dateConverterHeadings = [
        { label: t('dateConverter.adToBs'), value: 0 },
        { label: t('dateConverter.bsToAd'), value: 1 },
    ]

    return (
        <>
            <div className="tab-conveter-container">
                {!isTabSelector &&
                    dateHeading?.map((item, index) => (
                        <div
                            className={classNames('tab-date-item', {
                                active: activeHeading === index,
                            })}
                            onClick={() => setActiveHeading(index)}
                        >
                            <p
                                className={classNames('tab-date-item-text', {
                                    active: activeHeading === index,
                                })}
                            >
                                {t(item)}
                            </p>
                        </div>
                    ))}
                {isTabSelector && (
                    <ReactSelect
                        options={dateConverterHeadings}
                        selectDefaultValue={dateConverterHeadings[0]}
                        height="100%"
                        width="100%"
                        boxShadow="0px 2px 12px rgba(0, 0, 0, 0.2)"
                        color="#1D1D1F"
                        handleOnChange={(value) =>
                            setActiveHeading(value.value)
                        }
                        textColor="#a90000"
                        fontWeight="700"
                        singleValueFontSize="18px"
                    />
                )}
            </div>
            <DateContent active={activeHeading} />
        </>
    )
}
export default TabsConveter
